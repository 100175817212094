import React, { Component } from "react";

import { Button, Modal } from 'react-bootstrap';

export class ConformationBox extends Component {

    constructor(props) {
        super(props)
        this.MesageReceived(this.props);
       
    }
    componentWillReceiveProps(nextProps) { this.MesageReceived(nextProps); }
    MesageReceived(props) {
        if (props.message == undefined ||props.message == '' ||props.message == null) {
            this.Message = "Are you sure to delete this item? ";
        }
        else { this.Message = props.message }
    }
    render() {


        return (
            <div>
                <Modal {...this.props} size="sm"aria-labelledby="contained-modal-title-vcenter" centered className="conformationBox">
                    <Modal.Header closeButton >
                        <Modal.Title id="contained-modal-title-vcenter">Confirm </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div dangerouslySetInnerHTML={{ __html: this.Message  }} /> 
                    </Modal.Body>
                    <Modal.Footer className="spEqual">
                        <button className="btn btn-secondary" onClick={this.props.onHide}>No</button>
                        <button className="btn btn-primary" onClick={this.props.onConfirm}>Yes</button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }


}

import React, { Component } from 'react';
import { HttpRequest } from '../common/CommonOperations';
export class Results extends Component {
    static displayName = Results.name;
    constructor(props) {
        super(props);
        this.state = {selectedItemNumber: 0, categoryList: [], categoryDetail:[], categoryNumber: '', categoryIndexNumber: 0, selectedcategoryId: '', tieBreakerResponse: [],
            urlResult: '', indexNumber: null, showComponent: false, userScoreCard:[] };
    }
    componentDidMount() {
        let menuTittleName = this.props.history.location.pathname;
        let splitId = menuTittleName.substring(menuTittleName.lastIndexOf('/') + 1);
        this.GetAcademyResultsForUserByPersonID(splitId);
        this.GetAcademyNominationUserResponseCalcByPersonID(splitId);
        document.title = 'Academy Awards Results';
    }
    valueToSelectPick = (index) => {
        var path = "/AcademyAwards/SelectPicks/" + index;
        this.props.history.push(path)
    }
    viewLeaderboardList = async () => {
        var path = "/AcademyAwards/leaderboard/";
        this.props.history.push(path)

    }

    GetAcademyResultsForUserByPersonID = async (PersonID) => {
        var response = null;
        if (PersonID != null)
            response = await HttpRequest('AcademyAwards/GetAcademyResultsForUserByPersonID/' + PersonID, 'get');
        else
            response = await HttpRequest('AcademyAwards/GetAcademyResults/', 'get');
        const res = await response.json();
        if (res.AcademyNominationUserResponses != null) {
            let tieBreakerResponse = res.AcademyNominationUserResponses.filter((item, index) => item.TieBreakerResponse != null)
            this.setState({
                tieBreakerResponse: tieBreakerResponse
            })}
        this.setState({
            categoryList: res, 
        })
    }
    GetAcademyNominationUserResponseCalcByPersonID = async (PersonID) => {
        var response = null;
        if (PersonID != null)
            response = await HttpRequest('AcademyAwards/GetAcademyNominationUserResponseCalcByPersonID/' + PersonID, 'get');
        else
            response = await HttpRequest('AcademyAwards/GetAcademyResults/', 'get');
        const res = await response.json();
        this.setState({
            userScoreCard: res.Data
        })
    }
    renderCategory = () => {
        if (this.state.categoryList == null ||
            this.state.categoryList.length == 0) {
            return;
        }
        else {
            var categorylisting = this.state.categoryList.AcademyNominationCategories;
            if (categorylisting == undefined) { return ''; }
            else {
                return (
                    categorylisting.map((item, index) => <> {
                        this.renderSelectedNominee(item, index)
                    }
                    </>
                    )
                )
            }
        }

    }
    renderSelectedNominee = (item, index) => {
        let result = item.AcademyNominationCategoryDetails.filter(itemRes =>
            this.state.categoryList.AcademyNominationUserResponses.some((field) =>
                field.AcademyNominationCategoryID ==
                itemRes.AcademyNominationCategoryID &&
                field.AcademyNomineeID == itemRes.AcademyNominee.ID
            ));
        if (result == null || result.length == 0) {
            return;
        }
        else {
            if (!item.IsTieBreakerQuestion) {
                return (
                    <>

                        <li className="col s12 m4  reviewBlock desktopBlock">
                            <div className="reviewBlockDetails blockDetailstyle card">

                                <span>
                                    <p className="resultBlockTextHeader">
                                        <span className="descres ng-binding" id={item.ID}>{item.Description}</span>
                                        <span className="sub-title ng-binding">{item.PointValue} <span className="pts">pts</span></span>
                                    </p>
                                    <div className="line"></div>

                                    <div className="reviewBlockText revBlockText ng-binding ng-scope"><span>{result[0].AcademyNominee.NomineeName}</span> </div>
                                    
                                </span>
                            </div>
                        </li>
                    </>
                )
            }
            else {

                return (

                    <>
                        <li className="col s12 m4  reviewBlock desktopBlock">
                            <div className="reviewBlockDetails blockDetailstyle card">

                                <span>
                                    <p className="resultBlockTextHeader">
                                        <span className="descres ng-binding" id={item.ID}>{item.Description}</span>
                                        <span className="sub-title ng-binding">{item.PointValue} <span className="pts">pts</span></span>
                                    </p>
                                    <div className="line"></div>

                                    <div className="reviewBlockText revBlockText ng-binding ng-scope">
                                        <span>{result[0].AcademyNominee.TieBreakerQuestion}</span>
                                        <br />
                                        <span>You answered: {this.state.tieBreakerResponse[0].TieBreakerResponse}</span>
                                        <br />
                                        <span>Correct answer: {result[0].AcademyNominee.CorrectTieBreakerResponse}</span>
                                        <br />
                                    </div>
                                    
                                </span>
                            </div>
                        </li>

                        <li className="col s12 m4  reviewBlock desktopBlock">
                            <div className="reviewBlockDetails blockDetailstyle card">

                                <span>
                                    <p className="resultBlockTextHeader">
                                        <span className="descres ng-binding" id={item.ID}>{item.Description}</span>
                                        <span className="sub-title ng-binding">{item.PointValue} <span className="pts">pts</span></span>
                                    </p>
                                    <div className="line"></div>

                                    <div className="reviewBlockText revBlockText ng-binding ng-scope">
                                        <span>{result[1].AcademyNominee.TieBreakerQuestion}</span>
                                        <br />
                                        <span>You answered: {this.state.tieBreakerResponse[1].TieBreakerResponse}</span>
                                        <br />
                                        <span>Correct answer: {result[1].AcademyNominee.CorrectTieBreakerResponse}</span>
                                        <br />
                                    </div>
                                  </span>
                            </div>
                        </li>

                    </>
                )


            }
        }
    }

    render() {
        return (
            <>
                <div className="container">
                    <div className="selectPicksPage">
                        <div className="scoreBar row">
                            <div className="progressBarContianer" style={{ paddingTop: '15px' }}>
                                <div className="col s12 m12 progress progressbrand" style={{ padding: '0px' }}>
                                    <div className="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="25" style={{ width: (24 / 24) * 100 + '%' }}></div>
                                </div>
                                <div className="itemDisplay" >
                                    <img id="image" src="/images/AcademyAwards/leaf.png" alt="" />
                                    <div className="scoreboards">
                                        <center>
                                            <div id="pointsText">You<br /> scored<br />
                                                {this.state.userScoreCard.length == 0 ? <span id="upText1" ></span> : <span id="upText1" >{this.state.userScoreCard[0].TotalScore}</span>}
                                                <br />points </div>
                                           
                                        </center>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col s12 m12 categoryHeader">
                            <span className="categorytitle">Result</span>
                        </div>
                        <div className="userGridDisplay">
                            <ul className="row">{this.renderCategory()} </ul>
                        </div>
                        <div className="col-lg-12 nomineesReviewText text-center">
                            <button onClick={() => this.viewLeaderboardList()} className="btn btn-primary">VIEW LEADERBOARD</button>
                        </div>
                    </div>                        
                </div>
            </>
        );
    }
}
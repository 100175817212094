import React, { Component } from 'react';
import { HttpRequest, CheckSupportIDException, } from '../common/CommonOperations';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

export class Leaderboard extends Component {
    static displayName = Leaderboard.name;
    constructor(props) {
        super(props);
        this.state = {allTeamData: [], executiveTableData: [] };
    }
    componentDidMount() {
        this.GetAcademyNominationUserResponseCalcs();
        document.title = 'Leaderboard';
    }

    GetAcademyResultsForUserByPersonID = async (PersonID) => {
        var path = "/AcademyAwards/Results/" + PersonID ;
        this.props.history.push(path)
    }  
    GetAcademyNominationUserResponseCalcs = async () => {
        var response = await HttpRequest('AcademyAwards/GetAcademyNominationUserResponseCalcs', 'get');
        const res = await CheckSupportIDException(response)        
        var execPersonIDs = [135536, 135623, 2949939, 135758, 2971827, 185887, 3017768, 512847, 173897, 135584, 135613, 243628, 1626875, 503118];
        let result = res.Data.filter(itemRes => execPersonIDs.includes(itemRes.PersonID))
        this.setState({allTeamData: res.Data, executiveTableData: result})
    }

    renderExecutiveTable() {
        const columns = [  
            {
                name: ' Name', selector: 'FullName', sortable: true,
                cell: d => <span className="rowTextOverflow" title={d.FullName}>
                    <a onClick={() => this.GetAcademyResultsForUserByPersonID(d.PersonID)}>{d.FullName}</a></span>
            },
            { name: 'Department', selector: 'DepartmentName', sortable: true, },
            { name: 'Location', selector: 'LocationName', sortable: true, },
            { name: 'Score', selector: 'TotalScore', sortable: true, },
            { name: 'Tiebreakers', selector: 'TiebreakerScore', sortable: true, },
            { name: 'Rank', selector: 'Rank', sortable: true, },

        ];
        return <DataTableExtensions columns={columns} data={this.state.executiveTableData} filterPlaceholder="Search" print={false} export={false}  >
            <DataTable highlightOnHover pagination={true} paginationRowsPerPageOptions={[5, 10, 20, 50]}
                className="table table-bordered table-hover table-striped" tittle='Executive Leaderboard'
            />
        </DataTableExtensions>
    }
    

    renderAllTeamTable() {
        const columns = [
            {
                name: ' Name', selector: 'FullName', sortable: true,
                cell: d => <span className="rowTextOverflow" title={d.FullName}>
                    <a onClick={() => this.GetAcademyResultsForUserByPersonID(d.PersonID)}>{d.FullName}</a></span>
            },
            { name: 'Score', selector: 'TotalScore', sortable: true, },
            { name: 'Tiebreakers', selector: 'TiebreakerScore', sortable: true, },
            { name: 'Rank', selector: 'Rank', sortable: true, },

        ];
        return <DataTableExtensions columns={columns} data={this.state.allTeamData} filterPlaceholder="Search" print={false} export={false}  >
            <DataTable highlightOnHover pagination={true} paginationRowsPerPageOptions={[5,10, 20, 50]}
                className="table table-bordered table-hover table-striped" tittle='All-Team Leaderboard'
            />
        </DataTableExtensions>
    }

  render () {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col s12 md12">
                        <h4>Leaderboard </h4>
                        <div className="panel-container mt-4 card">
                            {this.renderExecutiveTable()}
                        </div>
                        <div className="panel-container mt-4 card">
                            {this.renderAllTeamTable()}
                        </div>
                    </div>
                </div>
            </div>

       </>
    );
  }
}

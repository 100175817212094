import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ExcelIcon } from './../../assets/images/excelIcon.svg';
import{ReactComponent as ViewEvent} from './../../assets/images/view-event.svg';
import{ReactComponent as EditEvent} from './../../assets/images/edit-event.svg';
import { Fragment } from "react";
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { MessageBox } from '../../components/common/modalBox/MessageBox';   
const EventPage = (props) =>{
    const [show, setShow] = useState(false);
    const [showMessageBox, setShowMessageBox] = useState(false);
    const [messageType, setMessageType] = useState('');
    const [message, setMessage] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    console.log(props.UserDetails);

    const ShowMessageBox = (message, messageType) => {
        setShowMessageBox(true)
        setMessageType(messageType)
        setMessage(message)
    }

    const HideMessageBox = () => setShowMessageBox(false);
    const showModalMessage = () => ShowMessageBox('Hello World', 3);




    return(
        <Fragment>
<div className="table-container mNoneTop">
        <div className="titleBox">
            <h2>Business Events</h2>
            <div className="table-top-link">
            <Link to='/'><i><span></span></i>Add An Event</Link>
            <Link to='/'><i><ExcelIcon /></i>Export to Excel</Link>
         
            </div>
            
        </div>
        <div className="tableBox">
        <table>
           <thead>
            <tr>
                <th>Event Name</th>
                <th>Event Description</th>
                <th>Event Image Path</th>
                <th>Location Group</th>
                <th>Event Email</th>
                <th></th>
            </tr>
           </thead>
            <tbody>
                <tr>
                    <td>Oktoberfest</td>
                    <td>Oktoberfest Event 1</td>
                    <td>Department/image/image1</td>
                    <td>Riverfront</td>
                    <td><button>oktoberfest@dish.com</button></td>
                    <td>
                                    <button className="btn btn-table-link" ><ViewEvent /></button>
                       <button className="btn btn-table-link"><EditEvent /></button>
                    </td>
                </tr>
                <tr>
                    <td>Oktoberfest</td>
                    <td>Oktoberfest Event 1</td>
                    <td>Department/image/image1</td>
                    <td>Riverfront</td>
                    <td><button>oktoberfest@dish.com</button></td>
                    <td>
                        <button className="btn btn-table-link"><ViewEvent /></button>
                       <button className="btn btn-table-link"><EditEvent /></button>
                    </td>
                </tr>
                <tr>
                    <td>Oktoberfest</td>
                    <td>Oktoberfest Event 1</td>
                    <td>Department/image/image1</td>
                    <td>Riverfront</td>
                    <td><button>oktoberfest@dish.com</button></td>
                    <td>
                        <button className="btn btn-table-link"><ViewEvent /></button>
                       <button className="btn btn-table-link"><EditEvent /></button>
                    </td>
                </tr>
                <tr>
                    <td>Oktoberfest</td>
                    <td>Oktoberfest Event 1</td>
                    <td>Department/image/image1</td>
                    <td>Riverfront</td>
                    <td><button>oktoberfest@dish.com</button></td>
                    <td>
                        <button className="btn btn-table-link"><ViewEvent /></button>
                       <button className="btn btn-table-link"><EditEvent /></button>
                    </td>
                </tr>
                <tr>
                    <td>Oktoberfest</td>
                    <td>Oktoberfest Event 1</td>
                    <td>Department/image/image1</td>
                    <td>Riverfront</td>
                    <td><button>oktoberfest@dish.com</button></td>
                    <td>
                        <button className="btn btn-table-link"><ViewEvent /></button>
                       <button className="btn btn-table-link"><EditEvent /></button>
                    </td>
                </tr>
                <tr>
                    <td>Oktoberfest</td>
                    <td>Oktoberfest Event 1</td>
                    <td>Department/image/image1</td>
                    <td>Riverfront</td>
                    <td><button>oktoberfest@dish.com</button></td>
                    <td>
                        <button className="btn btn-table-link"><ViewEvent /></button>
                       <button className="btn btn-table-link"><EditEvent /></button>
                    </td>
                </tr>
                <tr>
                    <td>Oktoberfest</td>
                    <td>Oktoberfest Event 1</td>
                    <td>Department/image/image1</td>
                    <td>Riverfront</td>
                    <td><button>oktoberfest@dish.com</button></td>
                    <td>
                        <button className="btn btn-table-link"><ViewEvent /></button>
                       <button className="btn btn-table-link"><EditEvent /></button>
                    </td>
                </tr>
                <tr>
                    <td>Oktoberfest</td>
                    <td>Oktoberfest Event 1</td>
                    <td>Department/image/image1</td>
                    <td>Riverfront</td>
                    <td><button>oktoberfest@dish.com</button></td>
                    <td>
                        <button className="btn btn-table-link"><ViewEvent /></button>
                       <button className="btn btn-table-link"><EditEvent /></button>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
        
            </div>

            <div style={{ 'display': 'flex','flexDirection':'row'}}>
    <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button>


            <Button variant="primary" onClick={showModalMessage}>
                Modal For Message Display
      </Button>
        </div>

    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
            </Modal>


            <MessageBox show={showMessageBox} onHide={HideMessageBox} type={messageType} message={message} />

              


        </Fragment>
        
    )
}

export default EventPage;
import React from "react";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { MessageBox } from '../../components/common/modalBox/MessageBox';   
import { Link } from "react-router-dom";
import { Fragment } from "react";
import { Editor } from '@tinymce/tinymce-react';



import { ReactComponent as EventSession } from './../../assets/images/eventSection.svg';
import { ReactComponent as AddUser } from './../../assets/images/addUser.svg';

import { useEffect } from 'react';
import { useState } from 'react';

import { HttpRequest, CheckSupportIDException, ShowLoader } from '../../components/common/CommonOperations';
import { ReactComponent as EditEvent } from './../../assets/images/edit-event.svg';
import { ReactComponent as ExcelIcon } from './../../assets/images/excelIcon.svg';
import { ReactComponent as AddSession } from './../../assets/images/add-plus-svgrepo-com.svg';
import { ReactComponent as PreviewIcon } from './../../assets/images/view-event.svg';
import { ReactComponent as RegisterUser } from './../../assets/images/userIcon.svg';
import DatePicker from "react-datepicker";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

import "react-datepicker/dist/react-datepicker.css";
import PreviewModal from './PreviewModal';

const DataFieldAddEvent = {
    ModalTile: 'Add Event',
    Header: '',
    Description: '',
    Details: '',
    Image: '',
    RouteText: '',
    EventEmail: '',
    LocationGroup: 0,
    StartDate: '',
    StartTime: '',
    EndDate: '',
    EndTime: '',
    ModalType: 'AddEvent',
    item: '',
};

const AddSessionFormFieldData = {
    ModalTile: '',
    Topic: '',
    Presenter: '',
    Location: '',
    Description: '',
    ConfirmMessage: '',
    ConfirmCount:0,
    BackupMessage: '',
    BackupCount: 0,
    SessionDate: '',
    SessionStartTime: '',
    SessionEndTime: '',
    StartDate: '',
    StartTime: '',
    EndDate: '',
    EndTime: '',
    MainEventData: '',
};


const EditSessionFormFieldData = {
    ModalTile: '',
    Topic: '',
    Presenter: '',
    Location: '',
    Description: '',
    ConfirmMessage: '',
    ConfirmCount: '',
    BackupMessage: '',
    BackupCount: '',
    SessionDate: '',
    SessionStartTime: '',
    SessionEndTime: '',
    StartDate: '',
    StartTime: '',
    EndDate: '',
    EndTime: '',
    MainEventData: '',
    itemData: '',
};


const AddEventDate = {
    StartDate: new Date(),
    EndDate: new Date('12/31/9999'),
};




const TimeData = [
{ 'label': '12:00 AM', 'value': '12:00:00 AM', },
{ 'label': '12:30 AM', 'value': '12:30:00 AM', },
{ 'label': '1:00 AM', 'value': '1:00:00 AM', },
{ 'label': '1:30 AM', 'value': '1:30:00 AM', },
{ 'label': '2:00 AM', 'value': '2:00:00 AM', },
{ 'label': '2:30 AM', 'value': '2:30:00 AM', },
{ 'label': '3:00 AM', 'value': '3:00:00 AM', },
{ 'label': '3:30 AM', 'value': '3:30:00 AM', },
{ 'label': '4:00 AM', 'value': '4:00:00 AM', },
{ 'label': '4:30 AM', 'value': '4:30:00 AM', },
{ 'label': '5:00 AM', 'value': '5:00:00 AM', },
{ 'label': '5:30 AM', 'value': '5:30:00 AM', },
{ 'label': '6:00 AM', 'value': '6:00:00 AM', },
{ 'label': '6:30 AM', 'value': '6:30:00 AM', },
{ 'label': '7:00 AM', 'value': '7:00:00 AM', },
{ 'label': '7:30 AM', 'value': '7:30:00 AM', },
{ 'label': '8:00 AM', 'value': '8:00:00 AM', },
{ 'label': '8:30 AM', 'value': '8:30:00 AM', },
{ 'label': '9:00 AM', 'value': '9:00:00 AM', },
{ 'label': '9:30 AM', 'value': '9:30:00 AM', },
{ 'label': '10:00 AM', 'value': '10:00:00 AM', },
{ 'label': '10:30 AM', 'value': '10:30:00 AM', },
{ 'label': '11:00 AM', 'value': '11:00:00 AM', },
{ 'label': '11:30 AM', 'value': '11:30:00 AM', },
{ 'label': '12:00 PM', 'value': '12:00:00 PM', },
{ 'label': '12:30 PM', 'value': '12:30:00 PM', },
{ 'label': '1:00 PM', 'value': '1:00:00 PM', },
{ 'label': '1:30 PM', 'value': '1:30:00 PM', },
{ 'label': '2:00 PM', 'value': '2:00:00 PM', },
{ 'label': '2:30 PM', 'value': '2:30:00 PM', },
{ 'label': '3:00 PM', 'value': '3:00:00 PM', },
{ 'label': '3:30 PM', 'value': '3:30:00 PM', },
{ 'label': '4:00 PM', 'value': '4:00:00 PM', },
{ 'label': '4:30 PM', 'value': '4:30:00 PM', },
{ 'label': '5:00 PM', 'value': '5:00:00 PM', },
{ 'label': '5:30 PM', 'value': '5:30:00 PM', },
{ 'label': '6:00 PM', 'value': '6:00:00 PM', },
{ 'label': '6:30 PM', 'value': '6:30:00 PM', },
{ 'label': '7:00 PM', 'value': '7:00:00 PM', },
{ 'label': '7:30 PM', 'value': '7:30:00 PM', },
{ 'label': '8:00 PM', 'value': '8:00:00 PM', },
{ 'label': '8:30 PM', 'value': '8:30:00 PM', },
{ 'label': '9:00 PM', 'value': '9:00:00 PM', },
{ 'label': '9:30 PM', 'value': '9:30:00 PM', },
{ 'label': '10:00 PM', 'value': '10:00:00 PM', },
{ 'label': '10:30 PM', 'value': '10:30:00 PM', },
{ 'label': '11:00 PM', 'value': '11:00:00 PM', },
{ 'label': '11:30 PM', 'value': '11:30:00 PM', }
]
const Dashbaord = (props) => {
    
    const [EventData, setEventData] = useState('');
    const [imgageBlobData, setimgageBlobData] = useState('');

  
    const [messageType, setMessageType] = useState('');
    const [message, setMessage] = useState('');
    const [addEventFormData, setaddEventFormData] = useState(DataFieldAddEvent);

    const [timeDataList, setTimeDataList] = useState(TimeData);
    const [addSesstionData, setAddSesstionData] = useState(AddSessionFormFieldData);


    const [editSesstionData, setEditSesstionData] = useState(AddSessionFormFieldData);

    const [addEventDatePick, setAddEventDatePick] = useState(AddEventDate);
    const [locationData, setLocationData] = useState('');

    const [showAddEventModal, setshowAddEventModal] = useState(false);
    const [showMessageBox, setShowMessageBox] = useState(false);
    const [showSessionModal, setShowSessionModal] = useState(false);
    const [showEditSessionModal, setShowEditSessionModal] = useState(false);
    const [registedUserDetail, setRegistedUserDetail] = useState(false);

    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [previewData, setPreviewData] = useState('');


    const sessionModalHandleClose = () => {
        setAddSesstionData(AddSessionFormFieldData);
        setShowSessionModal(false);

    };

    const sessionModalHandleShow = () => { setShowSessionModal(true) };


    const sessionEditModalHandleClose = () => { setShowEditSessionModal(false) };
    const sessionEditModalHandleShow = () => { setShowEditSessionModal(true) };

    const hideShowPreviewModal = () => {
        setPreviewData('');
        setShowPreviewModal(!showPreviewModal);
       
    };


    const handleClose = () => { setshowAddEventModal(false);
        setaddEventFormData(DataFieldAddEvent)};
    const handleShow = () => { setshowAddEventModal(true) };

    const [htmlData, setHtmlData] = useState('');


    const [previewModal, setPreviewModal] = useState(false);
    const [previewModalData, setPreviewModalData] = useState('');

    const [showRegisterUserModal, setShowRegisterUserModal] = useState(false);
    const [dataRegisterUserModal, setDataRegisterUserModal] = useState();
    const closeRegisterUserModal = () => setShowRegisterUserModal(!showRegisterUserModal);
   
    const closePreviewModal = () => {
        
        setPreviewModal(!previewModal);
    }

    const ShowMessageBox = (message, messageType) => {
        setShowMessageBox(true)
        setMessageType(messageType)
        setMessage(message)
    }

    const HideMessageBox = () => { setShowMessageBox(false) };

    //email validate function 

  const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };


    const routeTextValidation = (string) => {
        //debugger
        //Regex for Valid Characters i.e. Alphabets, Numbers and Space.
        var regex = /^[A-Za-z0-9]+$/

        //Validate TextBox value against the Regex.
        var isValid = regex.test(string);
       
        return isValid;
    };



    const localDateConvert = (date) => {
        
        var startDateString = new Date(date).toString();
        var startDateStringUtc = startDateString.substring(0, startDateString.indexOf('GMT')) + 'UTC';

        var startDateTimeLocal = new Date(startDateStringUtc).toString();
        var startDateLocal = new Date(startDateStringUtc).toLocaleDateString();
        var startTimeLocal = new Date(startDateStringUtc).toLocaleTimeString();
        return startDateLocal + ' ' + startTimeLocal;
    }

  

    const fetchData = async () => {
        let response = await HttpRequest('Content/GetEvent', 'Get');
        const res = await response.json();
       console.log(res)
        return res;
    }



    const getLocationData = async () => {
        let response = await HttpRequest('Content/GetLocationGroupSelectInfos', 'Get');
        const res = await response.json();
       
        return res.Data;
    }


    const getRegisteredUsers = async (id) => {
        let response = await HttpRequest('Content/GetBusinessEventEmployeeRegistrations/'+id, 'Get');
        const res = await response.json();
      
        //return res.Data;
       
        await setDataRegisterUserModal(res);
        await closeRegisterUserModal();
    }

    const renderRegisterUserModal = () => {
       
        let data = dataRegisterUserModal;
       
        if (data != undefined && data != null && data != '') {
            const registerUserList = (dataRegisterUser) => {
                return dataRegisterUser.sort((a, b) => a.ReservationStatus > b.ReservationStatus ? 1 : (a.ReservationStatus < b.ReservationStatus ? -1 : 0)).reverse().map((item, index) => {
                    return (
                        <tr key={index * 1.5}>
                            <td>{item.FirstName + ' ' + item.LastName}</td>
                            <td>{item.EmployeeNumber}</td>
                            <td>{item.EmailAddress}</td>
                            <td>{item.WorkPhone}</td>
                            <td>{item.LocationName}</td>
                            <td>{item.DepartmentName}</td>
                            <td>{item.AdditionInfo}</td>
                            <td>{localDateConvert(item.ReservationDate)}</td>
                            <td>{item.ReservationStatus == 'Primary' ? 'Confirmed' : 'Waiting'}</td>
                            
                        </tr>    
                        )
                    
                }) 
            }

            return (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>{data.Session.EventTopic}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {data.Registrations.length == 0 ? <div className="message">No Record found</div> :
                            <div className="table-responsive">
                                <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Employee Number</th>
                                        <th>Email Address</th>
                                        <th>Phone</th>
                                        <th>Location</th>
                                        <th>Department</th>
                                        <th>Additional Info	</th>
                                            <th>Reserve Date</th>
                                            <th>IsConfirmed</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        registerUserList(data.Registrations)
                                    }
                                </tbody>
                                </table>
                            </div>
                            }
                    </Modal.Body>
                </>
            )
        } else {

        }
    }

    const timeListDropDown = (propsTime) => {
        
        let data = timeDataList;

       // data.filter(item => item.value != propsTime);
        
        if (data != null && data != undefined && data != null) {
           return data.map((item ,index) => {
                return (
                    item.value != propsTime ? <option value={item.value} label={item.label} key={index*1.25}>{item.label}</option> :
                        <option value={item.value} label={item.label} selected key={index * 1.25}>{item.label}</option>
                    )
            }
            )

        }
    }

    const timeListDropDownNew = (propsTime) => {
       // debugger
        let newPropsTime = new Date(propsTime).toLocaleTimeString();


        let data = timeDataList;

        // data.filter(item => item.value != propsTime);

        if (data != null && data != undefined && data != null) {
            return data.map((item, index) => {
                return (
                    item.value != newPropsTime ? <option key={item.value} value={item.value} label={item.label}>{item.label}</option> :
                        <option key={item.value} value={item.value} label={item.label} selected>{item.label}</option>
                )
            }
            )

        }
    }



  const  removeFn = (str) => {
        let strArr = str.split(' ');
        strArr[strArr.length - 1] = 'PP';
        let fnStr = strArr.filter((item, pos) => {
            return strArr.indexOf(item) == pos;
        })

        let finalStr = '';
        fnStr.forEach((item) => {

            finalStr += item + ' ';
        })
        return finalStr.replaceAll(':00', '').replaceAll('PP', 'PM');
    }

    useEffect(() => {

        let mounted = true;
        fetchData().then(items => {

            ShowLoader(true)
            if (mounted) {

                setEventData(items.Data)
                ShowLoader(false)

            }
        }).catch(() => { console.log('error') });
        return () => { mounted = false; };


    }, []);


    useEffect(() => {
        async function fetchBookList() {
            let aa = await getLocationData();
          
            setLocationData(aa);
        }

        if (locationData == "") {
            fetchBookList();
        }

    }, [locationData]);

 
    
    const expendContent = (e) => {
     
        let plusIcon = e.target;

        if (plusIcon.classList.contains('active')) {
            document.querySelectorAll('.plus-btn').forEach(item => item.classList.remove('active'));
            plusIcon.classList.remove('active');
            document.querySelectorAll('.tableBox tr').forEach(item => item.id == e.target.dataset.id ? item.style.display = 'none' : '');
        } else {
            document.querySelectorAll('.plus-btn').forEach(item => item.classList.remove('active'));
            plusIcon.classList.add('active');
            document.querySelectorAll('.tableBox tr').forEach(item => item.id == plusIcon.dataset.id ? item.style.display = 'table-row' : item.id != '' ? item.style.display = 'none' : '');
        }     
    }



    const ChnageSessionObj = (e, item) => {
        
        let Datavalue = item;
        
        setEditSesstionData({
            ...editSesstionData,
            ['Topic']: Datavalue.EventSessions.EventTopic,
            ['Presenter']: Datavalue.EventSessions.EventPresenter,
            ['Location']: Datavalue.EventSessions.EventLocation,
            ['Description']: Datavalue.EventSessions.EventDescription,
            ['ConfirmMessage']: Datavalue.EventSessions.EventConfirmationMessage,
            ['ConfirmCount']: Datavalue.EventSessions.EventConfirmCount,
            ['BackupMessage']: Datavalue.EventSessions.EventBackupMessage,
            ['BackupCount']: Datavalue.EventSessions.EventBackupCount,
            ['SessionDate']: new Date(Datavalue.EventSessions.EventSessionStartDate).toLocaleDateString(),
            ['SessionStartTime']: new Date(Datavalue.EventSessions.EventSessionStartDate).toLocaleTimeString(),
            ['SessionEndTime']: new Date(Datavalue.EventSessions.EventSessionEndDate).toLocaleTimeString(),
            ['StartDate']: new Date(Datavalue.StartDate).toLocaleDateString(),
            ['StartTime']: new Date(Datavalue.StartDate).toLocaleTimeString(),
            ['EndDate']: new Date(Datavalue.EndDate).toLocaleDateString(),
            ['EndTime']: new Date(Datavalue.EndDate).toLocaleTimeString(),
            ['MainEventData']: Datavalue,
        });
       
    }

    const dataEditSession = (parm) => {
        
    }



    const subEventData = (EventSessionsGroupDetails) => {
                return EventSessionsGroupDetails.map((item1) => {
                    let { EventSessions } = item1;
                    console.log(item1);
                return (
                    EventSessions != null && EventSessions != '' && EventSessions != undefined ?
                        <tr key={EventSessions.ID}>
                            {EventSessions.EventTopic != '' && EventSessions.EventTopic != null ? <td><span className="text-small">{EventSessions.EventTopic}</span></td> : <td></td>}
                            {EventSessions.EventPresenter != '' && EventSessions.EventPresenter != null ? <td><span className="text-small">{EventSessions.EventPresenter}</span></td> :<td></td>}
                            <td><span className="text-small">{new Date(EventSessions.EventSessionStartDate).toLocaleDateString() + ', ' + new Date(EventSessions.EventSessionStartDate).toLocaleTimeString([], { timeStyle: 'short' }) + ' - ' + new Date(EventSessions.EventSessionEndDate).toLocaleTimeString([], { timeStyle: 'short' })}</span></td>
                            {EventSessions.EventLocation != '' && EventSessions.EventLocation != null ? <td><span className="text-small">{EventSessions.EventLocation}</span></td> : <td></td>}
                            <td>{EventSessions.EventConfirmCount}</td>
                            <td>{EventSessions.EventBackupCount}</td>
                            
                            <td>
                                <button className="btn btn-table-link" title="Registered Users" onClick={() => getRegisteredUsers(EventSessions.ID)}><RegisterUser /></button>
                                <button className="btn btn-table-link"
                                    onClick={(e) => { ChnageSessionObj(e, item1); sessionEditModalHandleShow();}} title="Edit Session"><EditEvent /></button></td>
                        </tr> : ''
                    )
                })
    }


    const previewSessionRender = (EventSessionsGroupDetails) => {
        return EventSessionsGroupDetails.map((item1) => {
            let { EventSessions } = item1;
            return (
                EventSessions != null && EventSessions != '' && EventSessions != undefined ?
                    <tr key={EventSessions.ID}>
                        {EventSessions.EventTopic != '' && EventSessions.EventTopic != null ? <td><span className="text-small">{EventSessions.EventTopic}</span></td> : <td></td>}
                        {EventSessions.EventPresenter != '' && EventSessions.EventPresenter != null ? <td><span className="text-small">{EventSessions.EventPresenter}</span></td> : <td></td>}
                        <td><span className="text-small">{new Date(localDateConvert(EventSessions.EventSessionStartDate)).toLocaleDateString() + ', ' + new Date(localDateConvert(EventSessions.EventSessionStartDate)).toLocaleTimeString([], { timeStyle: 'short' }) + ' - ' + new Date(localDateConvert(EventSessions.EventSessionEndDate)).toLocaleTimeString([], { timeStyle: 'short' })}</span></td>
                        {EventSessions.EventLocation != '' && EventSessions.EventLocation != null ? <td><span className="text-small">{EventSessions.EventLocation}</span></td> : <td></td>}
                        <td>{EventSessions.EventConfirmCount}</td>
                        <td>{EventSessions.EventBackupCount}</td>
                        
                    </tr> : ''
            )
        })
    }




    const locationSelect = (propsData) => {
        
        let locationDData = locationData;

        if (locationDData != '' && locationDData != null && locationDData != undefined) {
            return locationDData.map((item,index) =>{
                return (
                    item.Value != propsData ? <option value={item.Value} key={index}>{item.Name}</option> : <option value={item.Value} selected key={index}>{item.Name}</option>
                )
            })
        }
    }

    


    const DisplayTableData = () => {
        let DataEvent = EventData;
        if (DataEvent != '' && DataEvent != null && DataEvent != undefined) {
            const columns = [

                {
                    name: 'Event Header',
                    selector: row => `${row.EventHeader}`,
                    sortable: true,
                    cell: d => <span className="hideText" title={d.EventHeader}>{d.EventHeader}</span>,
                },
                
                {
                    name: 'Event Route',
                    selector: row => `${row.RouteText}`,
                    sortable: true,
                    cell: d => <span className="hideText" title={d.RouteText}>{d.RouteText}</span>,
                },
                {
                    name: 'Location Group',
                    selector: row => `${row.LocationGroup != null && row.LocationGroup != '' ? row.LocationGroup.Text : ''}`,
                    sortable: true,
                    cell: d => <span className="hideText" title={d.LocationGroup != null && d.LocationGroup != '' ? d.LocationGroup.Text : ''}>{d.LocationGroup != null && d.LocationGroup != '' ? d.LocationGroup.Text : ''}</span>,
                },
                {
                    name: 'Event Email',
                    selector: row => `${row.EventEmail}`,
                    sortable: true,
                    cell: d => <span className="hideText" title={d.EventEmail}>{d.EventEmail}</span>,
                },
                {
                    name: 'Event Start Date',
                    selector: row => `${row.StartDate}`,
                    sortable: true,
                    cell: d => <span className="hideText" title={d.StartDate}>{new Date(d.StartDate).toLocaleDateString() + ' ' + new Date(d.StartDate).toLocaleTimeString([], { timeStyle: 'short' }) }</span>,
                },
                {
                    name: '',
                    selector: row => `${row}`,
                    
                    cell: d => <><button title="Edit Event" className="btn btn-table-link" onClick={() => {
                        handleShow();

                        EditAddEventObj(d);


                    }}><EditEvent /></button>
                        <button title="Add Session" className="btn btn-table-link" onClick={() => {
                            sessionModalHandleShow();
                            setAddSesstionData({
                                ...addSesstionData,
                                ['MainEventData']: d,
                            })

                        }}><AddSession /></button>
                        <button title="Preview Event" className="btn btn-table-link" onClick={() => {

                            //closePreviewModal();
                            //setPreviewModalData(d);
                            window.open(window.location.origin + '/HopCMS/PreviewPage/' + d.RouteText, '_blank', 'noreferrer');
                        }}><PreviewIcon /></button>
                    </>,
                },

               
               ];

            const rowPreDisabled = (row) => {
                let { LocationGroup, EventSessionsGroup } = row;
                let { EventSessionsGroupDetails } = EventSessionsGroup;
                return EventSessionsGroupDetails.length == 0;
            }
            const ExpandedComponent = (data) => {

                let sessionData = data.data.EventSessionsGroup.EventSessionsGroupDetails;
                //let { EventSessionsGroup } = data;
                //let { EventSessionsGroupDetails } = EventSessionsGroup;
                return (
                   
                    <div className="tableInnerSession">
                        <table>
                            <thead>
                                <tr>
                                    <th>Session Topic</th>
                                    <th>Session Presenter</th> 
                                    <th>Session Date/Time</th>
                                    <th>Session Location</th>
                                    <th>Session Confirm Count </th>
                                    <th>Session backup Count</th>
                                    <th></th>

                                </tr>
                            </thead>
                            <tbody>
                                {subEventData(sessionData)}
                            </tbody>
                        </table>
                    </div>
                )



            };
            return <DataTableExtensions
                columns={columns}
                data={DataEvent}
                pagination={true} 
                filterPlaceholder="Search"
               
                
                print={false} export={false} > 
                <DataTable
                    columns={columns}
                    data={DataEvent}
                    className="table"
                    expandOnRowDoubleClicked={false}
                    expandableRows={true}
                    expandableRowsComponent={<ExpandedComponent data-tag="allowRowEvents" />}
                    expandableRowDisabled={rowPreDisabled}
                    highlightOnHover={true} pagination={true} paginationPerPage={10}
                    paginationRowsPerPageOptions={[25, 50, 75]}
                   
                   
                />
            </DataTableExtensions>
        }
    }




    

    const tableDataDisplay = () => {
        ShowLoader(true);
        let DataEvent = EventData;
       
        if (DataEvent != '' && DataEvent != null && DataEvent != undefined) {
            ShowLoader(false);
           return DataEvent.map((item) => {
               let { LocationGroup, EventSessionsGroup } = item;
               let { EventSessionsGroupDetails } = EventSessionsGroup;
             
               //console.log(EventSessionsGroupDetails);
               return (
                   EventSessionsGroupDetails.length > 0 ?
                       <>
                       <tr key={item.ID} > 
                               <td> <button className="plus-btn" onClick={expendContent} data-id={item.ID}>
                                   <span></span>
                                   <span></span>
                               </button></td>
                               <td><span className="text-small titleSpan">{item.EventHeader}</span></td>
                               <td><span className="text-small">{item.EventDescription}</span></td>
                               <td><span className="text-small">{item.ImageUrl}</span></td>
                               <td><span className="text-small">{LocationGroup != null && LocationGroup != '' ? LocationGroup.Text : ''}</span></td>
                               <td><span className="text-small"><a href={'mailto:' + item.EventEmail} title={item.EventEmail}>{item.EventEmail}</a></span></td>

                               <td className="lastChild">
                                   <button className="btn btn-table-link" title="Edit Event" onClick={(items) => {
                                       handleShow();
                                       
                                       EditAddEventObj(item);
                                   
                                       
                                   }}><EditEvent /></button>
                                   <button className="btn btn-table-link" title="Add Session" onClick={() => {
                                       sessionModalHandleShow();
                                       setAddSesstionData({
                                           ...addSesstionData,
                                           ['MainEventData']: item,
                                       })

                                   }}><AddSession /></button></td>
                       </tr>
                           <tr id={item.ID} className="hideRow">
                               <td className="spanOnly" colSpan="7">
                                   <div>
                                       <table>
                                           <thead>
                                               <tr>
                                                   <th>Topic</th>
                                                   {EventSessionsGroupDetails[0].EventSessions.EventDescription != '' && EventSessionsGroupDetails[0].EventSessions.EventDescription != null ? <th>Description</th> : ''}
                                                   {EventSessionsGroupDetails[0].EventSessions.EventPresenter != '' && EventSessionsGroupDetails[0].EventSessions.EventPresenter != null ? <th>Presenter</th> : ''}
                                                   <th>Date/Time</th>
                                                   <th>Location</th>
                                                   <th></th>
                                                   <th></th>
                                               </tr>
                                           </thead>
                                           <tbody>
                                               {subEventData(EventSessionsGroupDetails)}
                                           </tbody>
                                       </table>
                                   </div>
                           </td>
                       </tr> </>:
                       <tr key={item.ID}>
                           <td></td>
                           <td><span className="text-small titleSpan">{item.EventHeader}</span></td>
                           <td><span className="text-small">{item.EventDescription}</span></td>
                           <td><span className="text-small">{item.ImageUrl}</span></td>
                           <td><span className="text-small">{LocationGroup != null && LocationGroup != '' ? LocationGroup.Text : ''}</span></td>
                           <td><span className="text-small"><a href={'mailto:' + item.EventEmail} title={item.EventEmail}>{item.EventEmail}</a></span></td>

                           <td className="lastChild">
                               <button className="btn btn-table-link" title="Edit Event" onClick={(items) => {
                                   handleShow();

                                   EditAddEventObj(item);
                               }}><EditEvent /></button>

                              

                               <button className="btn btn-table-link" title="Add Session" onClick={() => {
                                   sessionModalHandleShow();
                                   setAddSesstionData({
                                       ...addSesstionData,
                                       ['MainEventData']: item,
                                   })

                               }}><AddSession /></button>

                           </td>
                        </tr>
                )

            })
        }
    }

    const OnChangeImageDat = (e) => {

        if (!e.target.files[0].name.match(/\.(jpg|jpeg|png|gif|bmp)$/)) {
            ShowMessageBox('Select valid image.', 3);
            e.stopPropagation();
            return false;
        }

        var maxSize = '5120';
        var fileSize = e.target.files[0].size / 1024;

        if (fileSize > maxSize) {
            ShowMessageBox('file size exceeds the allowable limit of {5MB}', 3);
            e.stopPropagation();
            return false;
        }

        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.addEventListener('load', () =>
                setimgageBlobData(reader.result.toString())
            );
        }
        
    }

    const addEventFieldData = (e) => {
        
    setaddEventFormData({
        ...addEventFormData,
        [e.target.name]: e.target.value
    });
    }


    const EditAddEventObj = (item) => {

        setaddEventFormData({
            ...addEventFormData,
            ['ModalTile']: 'Edit Event',
            ['ModalType']: 'Edit Event',
            ['item']: item,
            ['Header']: item.EventHeader,
            ['Description']: item.EventDescription,
            ['Details']: item.EventDetails,
            ['Image']: item.ImageData,
            ['RouteText']: item.RouteText,
            ['EventEmail']: item.EventEmail,
            ['StartDate']: item.StartDate,
            ['StartTime']: new Date(item.StartDate).toLocaleTimeString(),
            ['EndDate']: item.EndDate,
            ['EndTime']: new Date(item.EndDate).toLocaleTimeString(),
            
        });
       
    }



    const getDataSession = (e) => {
       // debugger;
    setAddSesstionData({
        ...addSesstionData,
        [e.target.name]: e.target.value
    });
    }

   
    const getEditDataSession = (e) => {
        setEditSesstionData({
            ...editSesstionData,
            [e.target.name]: e.target.value
        });

    }
    //


    const testRouteText = (paramRouteText) => {
        let EventDataRoute = EventData;
        let routeText = EventDataRoute.filter(item => item.RouteText == paramRouteText);
        return routeText.length > 0 ? true : false;
    }
    const saveAddEventModal = async (e) => {        
     //   alert('864')
        let eventEmailAddress = addEventFormData.EventEmail;
        
        if (addEventFormData.ModalType == 'AddEvent') {
            if (addEventFormData.Header.trim(' ') == '' && addEventFormData.Header.trim(' ').length == 0) {
                ShowMessageBox('Please enter header', 3);
                e.stopPropagation();
                return false;
            }
            else if (addEventFormData.Description == '' && addEventFormData.Description.length == 0) {
                ShowMessageBox('Please enter Description', 3);
                e.stopPropagation();
                return false;
            }
            else if (addEventFormData.RouteText == '' && addEventFormData.RouteText.length == 0) {
                ShowMessageBox('Please enter route text', 3);
                e.stopPropagation();
                return false;
            }
               else if (!routeTextValidation(addEventFormData.RouteText)) {
                ShowMessageBox('Invalid route text string. May only contains Letters, Numbers, Underscore, Dash or Hyphen', 3);
                e.stopPropagation();
                return false;
            }
            else if (testRouteText(addEventFormData.RouteText)) {
                ShowMessageBox('Route text already exits', 3);
                e.stopPropagation();
                return false;
            }

            else if (!validateEmail(eventEmailAddress)) {
                ShowMessageBox('Please enter a valid Email', 3);
                e.stopPropagation();
                return false;
            }

            
            else {

                
                let EndDateTime;
                if (new Date(addEventFormData.EndDate).toLocaleDateString() == '12/31/9999') {
                    //EndDateTime = new Date('12/31/9999').toISOString();
                    EndDateTime = new Date('12/31/9999');
                } else {
                    EndDateTime = addEventFormData.EndDate != '' ? new Date(addEventFormData.EndDate).toLocaleDateString() + ' ' + addEventFormData.EndTime : new Date('12/31/9999').toISOString();
                   // EndDateTime = new Date(EndDateTime).toISOString()
                }
               
                let StartDateTime = addEventFormData.StartDate != '' ? new Date(addEventFormData.StartDate).toLocaleDateString() + ' ' + addEventFormData.StartTime : new Date().toLocaleDateString() + ' ' + addEventFormData.StartTime;

               // EndDateTime = new Date(EndDateTime).toISOString();
                //StartDateTime = new Date(StartDateTime).toISOString();

                
                let param = {
                    EndDate: EndDateTime,
                    Event: {
                        EndDate: EndDateTime,
                        EventDescription: addEventFormData.Description,
                        EventDetails: addEventFormData.Details,
                        EventEmail: addEventFormData.EventEmail,
                        EventHeader: addEventFormData.Header,
                        EventSessionsGroup: null,
                        ID: 0,
                      //  ImageUrl: addEventFormData.Image != '' ? addEventFormData.Image.split('\\')[2] != undefined ? '/DeptContent/' + addEventFormData.Image.split('\\')[2] : addEventFormData.Image : addEventFormData.Image,
                        ImageUrl: (imgageBlobData != null) ? imgageBlobData.substring(imgageBlobData.indexOf(',') + 1) : null,
                        IsApprovalProcess: false,
                        LastUpdatedBy: props.UserDetails.NTLogin,
                        LastUpdatedTimeStamp: new Date(),
                        LocationGroup: {
                            EndDate: null,
                            ID: parseInt(addEventFormData.LocationGroup == 0 ? 44 : addEventFormData.LocationGroup),
                            LastUpdatedBy: null,
                            LastUpdatedTimeStamp: new Date(),
                            LocationGroupDetails: null,
                            OrderNumber: 0,
                            StartDate: null,
                            Text: null
                        },
                        OrderNumber: 0,
                        RouteText: addEventFormData.RouteText,
                        StartDate: StartDateTime,
                    },
                    ID: 0,
                    LastUpdatedBy: props.UserDetails.NTLogin,
                    LastUpdatedTimeStamp: new Date(),
                    OrderNumber: 1,
                    Session: null,
                    SessionGroupID: 0,
                    StartDate: StartDateTime,
                    TreeLevel: 0
                }
               
                var updateResponse = await HttpRequest('Content/SaveEmployeeEventDetails', 'POST', param);
                let res = await updateResponse.json();
                if (res.IsSuccessful) {
                    handleClose();
                   ShowMessageBox('Your Record has been saved', 1);
                let data = await fetchData();
                   setEventData(data.Data)
                }


            }
        }

        else {
            //alert('966')

            if (addEventFormData.Header.trim(' ') == '' && addEventFormData.Header.trim(' ').length == 0) {
                ShowMessageBox('Please enter header', 3);
                e.stopPropagation();
                return false;
            }
            else if (addEventFormData.Description == '' && addEventFormData.Description.length == 0) {
                ShowMessageBox('Please enter Description', 3);
                e.stopPropagation();
                return false;
            }
            else if (!validateEmail(eventEmailAddress)) {
                ShowMessageBox('Please enter a valid Email', 3);
                e.stopPropagation();
                return false;
            }
            else if (addEventFormData.RouteText.trim(' ') == '' && addEventFormData.RouteText.trim(' ').length == 0) {
                ShowMessageBox('Invalid route text string. May only contains Letters, Numbers, Underscore, Dash or Hyphen', 3);
                e.stopPropagation();
                return false;
            }


            else {
               
                let EndDateTime;
                if (new Date(addEventFormData.EndDate).toLocaleDateString() == '12/31/9999') {
                    //EndDateTime = new Date('12/31/9999').toISOString();
                    EndDateTime = new Date('12/31/9999');
                } else {
                    EndDateTime = new Date(addEventFormData.EndDate).toLocaleDateString() + ' ' + addEventFormData.EndTime;
                    
                }
               
                 
                let StartDateTime = addEventFormData.StartDate != '' ? new Date(addEventFormData.StartDate).toLocaleDateString() + ' ' + addEventFormData.StartTime : new Date().toLocaleDateString() + ' ' + addEventFormData.StartTime;

                
                //StartDateTime = new Date(StartDateTime).toISOString();
              

                 
                let param = {
                    EndDate: EndDateTime,
                    Event: {
                        EndDate: EndDateTime,
                        EventDescription: addEventFormData.Description,
                        EventDetails: addEventFormData.Details,
                        EventEmail: addEventFormData.EventEmail,
                        EventHeader: addEventFormData.Header,
                        EventSessionsGroup: null,
                        ID: parseInt(addEventFormData.item.ID),
                        ImageUrl: (imgageBlobData != null) ? imgageBlobData.substring(imgageBlobData.indexOf(',') + 1) : null,
                        IsApprovalProcess: false,
                        LastUpdatedBy: props.UserDetails.NTLogin,
                        LastUpdatedTimeStamp: new Date(),
                        LocationGroup: {
                            EndDate: null,
                            ID: parseInt(addEventFormData.LocationGroup == 0 ? addEventFormData.item.LocationGroup.ID : addEventFormData.LocationGroup),
                            LastUpdatedBy: null,
                            LastUpdatedTimeStamp: new Date(),
                            LocationGroupDetails: null,
                            OrderNumber: 0,
                            StartDate: null,
                            Text: null
                        },
                        OrderNumber: 1,
                        RouteText: addEventFormData.RouteText,
                        StartDate: StartDateTime,
                    },
                    ID: parseInt(addEventFormData.item.ID),
                    LastUpdatedBy:props.UserDetails.NTLogin,
                    LastUpdatedTimeStamp: new Date(),
                    OrderNumber: 1,
                    Session: null,
                    SessionGroupID: 0,
                    StartDate: StartDateTime,
                    TreeLevel: 0
                }
               
              //  debugger
                var updateResponse = await HttpRequest('Content/SaveEmployeeEventDetails', 'POST', param);
                 let res = await updateResponse.json();
                if (res.IsSuccessful) {
                    handleClose();
                    ShowMessageBox('Your Record has been saved', 1);
                    let data = await fetchData();
                    setEventData(data.Data)
               }


            }
        }

    }

    const saveSessionData = async (e) => {
       //alert('scsdfdsg')

        if (addSesstionData.Topic.trim(' ') == '' && addSesstionData.Topic.trim(' ').length == 0) {
            ShowMessageBox('Please enter topic', 3);
            e.stopPropagation();
            return false;
        }
        else if (addSesstionData.Location.trim(' ') == '' && addSesstionData.Location.trim(' ').length == 0) {
            ShowMessageBox('Please enter location', 3);
            e.stopPropagation();
            return false;
        }
        else if (addSesstionData.ConfirmMessage.trim(' ') == '' && addSesstionData.ConfirmMessage.trim(' ').length == 0) {
            ShowMessageBox('Please enter confirm message', 3);
            e.stopPropagation();
            return false;
        }

        else {
            let EndDateTime;
          //  debugger;
            console.log('addSesstionData')
            console.log(addSesstionData)
            if (new Date(addSesstionData.EndDate).toLocaleDateString() == '12/31/9999') {
                EndDateTime = new Date('12/31/9999');
            }
            else {
                EndDateTime = addSesstionData.EndDate != '' ? new Date(addSesstionData.EndDate).toLocaleDateString() + ' ' + addSesstionData.EndTime : new Date('12/31/9999').toISOString();
            }
            if (addSesstionData.SessionDate == '') {

                addSesstionData.SessionDate = new Date();
            }
            let StartDateTime = addSesstionData.StartDate != '' ? new Date(addSesstionData.StartDate).toLocaleDateString() + ' ' + addSesstionData.StartTime : new Date().toLocaleDateString() + ' ' + addSesstionData.StartTime;
            let SessionEndDateTime = addSesstionData.SessionDate != '' ? new Date(addSesstionData.SessionDate).toLocaleDateString() + ' ' + addSesstionData.SessionEndTime : new Date().toLocaleDateString();
            let SessionStartDateTime = addSesstionData.SessionDate != '' ? new Date(addSesstionData.SessionDate).toLocaleDateString() + ' ' + addSesstionData.SessionStartTime : new Date().toLocaleDateString();


            let param = {
                EndDate: EndDateTime,
                Event: null,
                ID: 0,
                LastUpdatedBy: props.UserDetails.NTLogin,
                LastUpdatedTimeStamp: new Date(),
                OrderNumber: 1,
                Session: {
                    EndDate: EndDateTime,
                    EventBackupCount: parseInt(addSesstionData.BackupCount),
                    EventBackupMessage: addSesstionData.BackupMessage,
                    EventConfirmCount: parseInt(addSesstionData.ConfirmCount),
                    EventConfirmationMessage: addSesstionData.ConfirmMessage,
                    EventDescription: addSesstionData.Description,
                    EventLocation: addSesstionData.Location,
                    EventPresenter: addSesstionData.Presenter,
                    EventSessionEndDate: SessionEndDateTime,
                    EventSessionStartDate: SessionStartDateTime,
                    EventTopic: addSesstionData.Topic,
                    ID: parseInt(addSesstionData.MainEventData.ID),
                    IsAlreadyRegistered: false,
                    IsEventSelected: null,
                    IsSessionFull: false
                },
                SessionsGroupID:parseInt(addSesstionData.MainEventData.ID),
                StartDate: StartDateTime,
                TreeLevel: 1,

            }
            var updateResponse = await HttpRequest('Content/SaveEmployeeEventDetails', 'POST', param);
           let res = await updateResponse.json();
           if (res.IsSuccessful) {
             
               let data = await fetchData();
           setEventData(data.Data)}

           
            sessionModalHandleClose();
            ShowMessageBox('Your Record has been saved', 1);

        }
    
       
    }


    const openEditModalEvent = (item) => {
 
    }

    const RenderAddEventModalData = (item) => {
        if(addEventFormData.ModalType =='AddEvent') {
            return (
                <>
                <Modal.Header closeButton>
                    <Modal.Title>{addEventFormData.ModalTile}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <ul>
                        <li><label>Header<span>*</span></label>
                            <input type="text" onChange={addEventFieldData} name="Header" value={addEventFormData.Header} className="form-control" maxLength='255' />
                            </li>
                            <li>
                                <label>Description<span>*</span></label>
                                <Editor
                                    data={addEventFormData.Description}
                                    initialValue=""
                                    init={{
                                        plugins: 'link image code',
                                        height: 200,
                                        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
                                    }}
                                    onChange={(event, editor) => {
                                        const data = event.target.getContent();
                                        setaddEventFormData({
                                            ...addEventFormData,
                                            ['Description']: data
                                        });                                        
                                    }
                                    }
                                />
                            </li>

                            <li>
                                <label>Details<span></span></label>
                                <Editor
                                    data={addEventFormData.Details}
                                    initialValue=""
                                    init={{
                                        plugins: 'link image code',
                                        height: 200,
                                        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
                                    }}
                                    onChange={(event, editor) => {
                                        const data = event.target.getContent();
                                        setaddEventFormData({
                                            ...addEventFormData,
                                            ['Details']: data
                                        });
                                    }
                                    }
                                />
                            </li>
                                       

                        <li><label>Image</label> <input type="file" onChange={OnChangeImageDat} name="Image" className="from-control" value={addEventFormData.Image} /></li>
                            {imgageBlobData ? <li><label>Display Image</label><img src={'data:image/JPEG;base64,' + imgageBlobData.substring(imgageBlobData.indexOf(',') + 1)} alt='' width="170px" height="225px" />
                                <button className='btn btn-secondary' onClick={() => {
                                    setimgageBlobData('');
                                }}>Delete</button>
                            </li> : ''} 
                        

                        
                        <li className="wd50"><label>Route Text<span>*</span></label><input type="text" className="form-control" onChange={addEventFieldData} value={addEventFormData.RouteText} name="RouteText" /></li>
                        <li className="wd50 fullInner"><label> Email<span>*</span></label><input type="text" className="form-control" onChange={addEventFieldData} name="EventEmail" value={addEventFormData.EventEmail} /></li>
                    <li><label>Location Group</label>
                            <select className="browser-default locationGroup" onChange={addEventFieldData} name="LocationGroup">
                                {locationSelect()}
                            
                        </select>
                        </li>
                        <li className="wd25"><label>Start Date</label>

                            <DatePicker
                                minDate={new Date()}
                                todayButton="Today Date"
                                selected={addEventFormData.StartDate == '' ? new Date() : new Date(addEventFormData.StartDate)} onChange={(date) => {
                                setaddEventFormData({
                                    ...addEventFormData,
                                    ['StartDate']: date
                                })
                             }} />


                        </li>
                        <li className="wd25"><label>Start time</label>
                        <select className="browser-default" onChange={addEventFieldData} name="StartTime">
                                {timeListDropDown()}
                        </select>

                    </li>
                        <li className="wd25">
                            <label>End Date</label>
                           
                            <DatePicker
                                    todayButton="Today Date"
                                    selected={addEventFormData.EndDate == '' ? new Date('12/31/9999') : new Date(addEventFormData.EndDate)}
                                    maxDate={new Date('12/31/9999')}
                                    onChange={(date) => {
                                        setaddEventFormData({
                                            ...addEventFormData,
                                            ['EndDate']: date
                                        })
                                        let changeDate = new Date(date).toLocaleDateString();
                                        let endTime = document.getElementById('addEventEndTime');
                                        if (changeDate == '12/31/9999' || changeDate > '12/31/9999') {
                                            endTime.disabled = true
                                        } else {
                                            endTime.disabled = false
                                        }
                                       
                                    }} />

                        </li>
                            <li className="wd25 fullInner"><label>End time</label>
                                <select className="browser-default" onChange={addEventFieldData} name="EndTime" disabled id="addEventEndTime">
                                {timeListDropDown()}
                        </select>
                    </li>
                </ul>


                </Modal.Body>
            </>)

        } else {
           
            return (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>{addEventFormData.ModalTile}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ul>
                            <li><label>Header<span>*</span></label><input type="text" onChange={addEventFieldData} name="Header" value={addEventFormData.Header} className="form-control" maxLength='255' /></li>
                            <li>
                                <label>Description<span>*</span></label>
                                
                                <Editor
                                    initialValue={addEventFormData.item.EventDescription != '' && addEventFormData.item.EventDescription != undefined && addEventFormData.item.EventDescription != null ? addEventFormData.item.EventDescription : ''}
                                  //  initialValue=""
                                    init={{
                                        plugins: 'link image code',
                                        height: 200,
                                        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
                                    }}
                                    onChange={(event, editor) => {
                                        const data = event.target.getContent();
                                        setaddEventFormData({
                                            ...addEventFormData,
                                            ['Description']: data
                                        });
                                    }
                                    }
                                />
                            </li>
                            <li>
                                <label>Details</label>
                                <Editor
                                    initialValue={addEventFormData.item.EventDetails != '' && addEventFormData.item.EventDetails != undefined && addEventFormData.item.EventDetails != null ? addEventFormData.item.EventDetails : ''}
                                    
                                    init={{
                                        plugins: 'link image code',
                                        height: 200,
                                        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
                                    }}
                                    onChange={(event, editor) => {
                                        const data = event.target.getContent();
                                        setaddEventFormData({
                                            ...addEventFormData,
                                            ['Details']: data
                                        });
                                    }
                                    }
                                />
                                
                            </li>
                            

                            {//<li><label>Image</label> <input type="file" onChange={addEventFieldData} name="Image" className="from-control" /></li>
                            }
                            <li><label>Image</label> <input type="file" onChange={OnChangeImageDat} name="Image" className="from-control" id="editEventImage"  /></li>
                            {
                                addEventFormData.item.ImageUrl != null || imgageBlobData != '' ? <li><img src={'data:image/JPEG;base64,' + (imgageBlobData != '' ? imgageBlobData.substring(imgageBlobData.indexOf(',') + 1) : addEventFormData.item.ImageUrl)} alt='' width="120px" height="170px" />
                                    <button className="btn btn-secondary" onClick={() => {
                                        setimgageBlobData('');
                                        document.getElementById('editEventImage').value ='';
                                    }}>Delete</button>
                                </li> : ''
                            } 
                            


                            <li className="wd50"><label>Route Text<span>*</span></label>
                                <input type="text" className="form-control" disabled onChange={addEventFieldData} value={addEventFormData.RouteText} name="RouteText" /></li>
                            <li className="wd50 fullInner"><label>Email<span>*</span></label>
                                <input type="text" className="form-control" onChange={addEventFieldData} name="EventEmail" value={addEventFormData.EventEmail} maxLength='255' /></li>
                            <li><label>Location Group</label>
                                <select className="browser-default" onChange={addEventFieldData} name="LocationGroup">

                                    {locationSelect(addEventFormData.item.LocationGroup != null && addEventFormData.item.LocationGroup !='' ?addEventFormData.item.LocationGroup.ID:'')}

                                </select>
                            </li>
                            <li className="wd25"><label>Start Date</label>
                                
                                <DatePicker
                                    minDate={new Date()}
                                    todayButton="Today Date"
                                    selected={addEventFormData.StartDate == '' ? new Date() : new Date(addEventFormData.StartDate)} onChange={(date) => {
                                    setaddEventFormData({
                                        ...addEventFormData,
                                        ['StartDate']: date
                                    })

                                }
                                } />

                            </li>
                            <li className="wd25"><label>Start time</label>
                                <select className="browser-default" onChange={addEventFieldData} name="StartTime">

                                    {addEventFormData.StartTime != '' && addEventFormData.StartTime != null && addEventFormData.StartTime != undefined ? timeListDropDownNew(addEventFormData.StartDate) : timeListDropDownNew(addEventFormData.StartDate)}
                                </select>

                            </li>

                            


                            <li className="wd25"><label>End Date</label>

                             
                                <DatePicker
                                    todayButton="Today Date"
                                    selected={addEventFormData.EndDate == '' ? new Date('12/31/9999') : new Date(addEventFormData.EndDate)} onChange={(date) => {
                                setaddEventFormData({
                                    ...addEventFormData,
                                    ['EndDate']: date
                                })

                                        let changeDate = new Date(date).toLocaleDateString();
                                        let endTime = document.getElementById('editEventEndTime');
                                        if (changeDate == '12/31/9999' || changeDate > '12/31/9999') {
                                            endTime.disabled = true
                                        } else {
                                            endTime.disabled = false
                                        }

                            }
                            } />

                        </li>
                            <li className="wd25 fullInner"><label>End time</label>
                                <select className="browser-default" onChange={addEventFieldData} name="EndTime" id="editEventEndTime" disabled={new Date(addEventFormData.EndDate).toLocaleDateString() == new Date('12/31/9999').toLocaleDateString()?'true':false}>
                                  
                                    {addEventFormData.EndDate != '' && addEventFormData.EndDate != null && addEventFormData.EndDate != undefined ? timeListDropDownNew(addEventFormData.EndDate) : timeListDropDownNew(addEventFormData.EndDate)}
                                    
                                </select>
                            </li>
                        </ul>


                    </Modal.Body>
                </>
                )
    }
}


    const saveEditSessionData = async (e) => {
        if (editSesstionData.Topic.trim(' ') == '' && editSesstionData.Topic.trim(' ').length == 0) {
            ShowMessageBox('Please enter topic', 3);
            e.stopPropagation();
            return false;
        }
        else if (editSesstionData.Location.trim(' ') == '' && editSesstionData.Location.trim(' ').length == 0) {
            ShowMessageBox('Please enter location', 3);
            e.stopPropagation();
            return false;
        }
        else if (editSesstionData.ConfirmMessage.trim(' ') == '' && editSesstionData.ConfirmMessage.trim(' ').length == 0) {
            ShowMessageBox('Please enter confirm message', 3);
            e.stopPropagation();
            return false;
        }

        else {
            let EndDateTime;
            if (new Date(editSesstionData.EndDate).toLocaleDateString() == '12/31/9999') {
                EndDateTime = new Date('12/31/9999');
            } else {
                EndDateTime = new Date(editSesstionData.EndDate).toLocaleDateString() + ' ' + editSesstionData.EndTime;               
            }
            let StartDateTime = editSesstionData.StartDate != '' ? new Date(editSesstionData.StartDate).toLocaleDateString() + ' ' + editSesstionData.StartTime : new Date().toLocaleDateString() + ' ' + editSesstionData.StartTime;

            let SessionEndDateTime = editSesstionData.SessionDate != '' ? new Date(editSesstionData.SessionDate).toLocaleDateString() + ' ' + editSesstionData.SessionEndTime : new Date().toLocaleDateString();
            let SessionStartDateTime = editSesstionData.SessionDate != '' ? new Date(editSesstionData.SessionDate).toLocaleDateString() + ' ' + editSesstionData.SessionStartTime : new Date().toLocaleDateString();
          
            let param = {
                EndDate: EndDateTime,
                Event: null,
                ID:parseInt(editSesstionData.MainEventData.ID),
                LastUpdatedBy: props.UserDetails.NTLogin,
                LastUpdatedTimeStamp: new Date(),
                OrderNumber: 1,
                Session: {
                    EndDate: EndDateTime,
                    EventBackupCount: parseInt(editSesstionData.BackupCount),
                    EventBackupMessage: editSesstionData.BackupMessage,
                    EventConfirmCount: parseInt(editSesstionData.ConfirmCount),
                    EventConfirmationMessage: editSesstionData.ConfirmMessage,
                    EventDescription: editSesstionData.Description,
                    EventLocation: editSesstionData.Location,
                    EventPresenter: editSesstionData.Presenter,
                    EventSessionEndDate: SessionEndDateTime,
                    EventSessionStartDate: SessionStartDateTime,
                    EventTopic: editSesstionData.Topic,
                    ID: parseInt(editSesstionData.MainEventData.EventSessions.ID),
                    IsAlreadyRegistered: false,
                    IsEventSelected: null,
                    IsSessionFull: false
                },
                SessionsGroupID: parseInt(editSesstionData.MainEventData.EventSessionsGroupID),
                StartDate: StartDateTime,
                TreeLevel: 1,

            }

            var updateResponse = await HttpRequest('Content/SaveEmployeeEventDetails', 'POST', param);
            let res = await updateResponse.json();
            if (res.IsSuccessful) {
                sessionEditModalHandleClose();
                ShowMessageBox('Your Record has been saved', 1);
                let data = await fetchData();
                setEventData(data.Data)
            }

           

        }
    }

    const previewModalRenderData = () => {
        
        let data = previewModalData;
        if (data != undefined && data != null && data != '') {
          
            return (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>{data.EventHeader}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="eventRouteText"><strong>Route Text: </strong>{data.RouteText}</div>
                        <div className="eventEmail"><strong>Event Email:</strong>{data.EventEmail}</div>
                        <div className="eventStartDate"><strong>Event Start Date:</strong>{localDateConvert(data.StartDate)}</div>
                        <div className="eventEndDate"><strong>Event End Date:</strong>{localDateConvert(data.EndDate)}</div>
                      
                        <div className="eventDetail" dangerouslySetInnerHTML={{ __html: '<strong>Event Detail:</strong>'+data.EventDetails }} />
                        <div className="eventDescription" dangerouslySetInnerHTML={{ __html: '<strong>Event Description:</strong>' + data.EventDescription }} />
                        <div className="sessionData">
                            {data.EventSessionsGroup.EventSessionsGroupDetails.length != 0 ?
                                <div className="tableInnerSession">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Session Topic</th>
                                                <th>Session Presenter</th>
                                                <th>Session Date/Time</th>
                                                <th>Session Location</th>
                                                <th>Session Confirm Count </th>
                                                <th>Session backup Count</th>
                                                <th></th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {previewSessionRender(data.EventSessionsGroup.EventSessionsGroupDetails)}
                                        </tbody>
                                    </table>
                                </div>:''} 
                        </div>
                    </Modal.Body>
                </>
                )

        }
        
    }

    const onChangeEvent = () => {
      
    }

    return (
        <Fragment>
            
            {/*
             <div className="key-point-container">
            <div>
                <Link to='/'>
                    <i><AddUser /></i>
                    <h2>36
                        <span>Total Registration</span>
                    </h2>
                </Link>
            </div>
            <div>
                <Link to='/'>
                    <i><EventSession /></i>
                    <h2>12
                        <span>Total Number of Event</span>
                    </h2>
                </Link>
            </div>
            <div>
                <Link  to='/'>
                <i><EventSession /></i>
                    <h2>15
                        <span>Remaining Registration</span>
                    </h2>
                </Link>
            </div>
        </div>
             */}
        <div className="table-container">
            <div className="titleBox">

                    {/*
                      <h2>Business Events</h2>
                     */}
                    <div className="table-top-link">
                        <button onClick={handleShow}><i><span></span></i>Add An Event</button>
                        {/* <button><i><ExcelIcon /></i>Export to Excel</button>
                         */} 
                    </div>
            </div>
                <div className="tableBox">
                    {DisplayTableData()}

                    {/*
                     
                        <table className="table">
        <thead>
        <tr>
            <th></th>
            <th>Event Header</th>
            <th>Event Description</th>
            <th>Event Image Path</th>
            <th>Location Group</th>
            <th>Event Email</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        {tableDataDisplay()}
        </tbody>
        </table>

                     */}
        </div>
            
            </div>

            {/* Add Event modal Start*/}
            <Modal
                show={showAddEventModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                animation={false}
                enforceFocus={false}
                className="addEventPage">
               

                {RenderAddEventModalData()}
                <Modal.Footer>
                    <Button variant="primary" onClick={saveAddEventModal}>Save</Button>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    
                    </Modal.Footer>

               
            </Modal>

            {/* Add Event modal End*/}


            {/* Add Event session modal Start*/}
            <Modal
                show={showSessionModal}
                onHide={sessionModalHandleClose}
                backdrop="static"
                keyboard={false}
                enforceFocus={false}
                animation={false}
                className="addEventPage">
                <Modal.Header closeButton>
                    <Modal.Title>Add Session</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul>
                        <li><label>Topic<span>*</span></label>
                            <input type="text" className="form-control" onChange={getDataSession} value={addSesstionData.Topic} name="Topic" maxLength='255' />
                        </li>
                        <li>
                            <label>Presenter</label>
                            <input type="text" className="form-control" onChange={getDataSession} value={addSesstionData.Presenter} name="Presenter" maxLength='255' />
                        </li>
                        <li>
                            <label>Location<span>*</span></label>
                            <input type="text" className="form-control" onChange={getDataSession} value={addSesstionData.Location} name="Location" maxLength='255' />
                        </li>
                        <li>
                            <label>Description</label>
                            <input type="text" className="form-control" onChange={getDataSession} value={addSesstionData.Description} name="Description" maxLength='255' />
                        </li>
                        <li className="wd50"><label>Confirm Count</label><input type="text" className="form-control" name="ConfirmCount" onPaste={e => { e.preventDefault() }} maxLength="2" onKeyPress={(event) => { if (! /\d/.test(event.key)) { event.preventDefault() } }} value={addSesstionData.ConfirmCount} onChange={getDataSession} /></li>
                        <li className="wd50 fullInner"><label>Backup Count</label><input type="text" maxLength="2" onPaste={e => { e.preventDefault() }} onKeyPress={(event) => { if (! /\d/.test(event.key)) { event.preventDefault() } }} className="form-control" name="BackupCount" value={addSesstionData.BackupCount} onChange={getDataSession} /></li>
                    <li>
                            <label>Confirm Message<span>*</span></label>                            
                            <Editor
                                initialValue=''

                                init={{
                                    plugins: 'link image code',
                                    height: 200,
                                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
                                }}
                                onChange={(event, editor) => {
                                    const data = event.target.getContent();
                                    setAddSesstionData({
                                        ...addSesstionData,
                                        ['ConfirmMessage']: data
                                    });
                                }
                                }
                            />
                        </li>
                       
                        <li>
                            <label>Backup Message</label>                           
                            <Editor
                                initialValue=''

                                init={{
                                    plugins: 'link image code',
                                    height: 200,
                                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
                                }}
                                onChange={(event, editor) => {
                                    const data = event.target.getContent();
                                    setAddSesstionData({
                                        ...addSesstionData,
                                        ['BackupMessage']: data
                                    });
                                }
                                }
                            />
                        </li>
                        
                        <li className="wd333"><label>Session Date</label>
                            <DatePicker
                                minDate={new Date()}
                                todayButton="Today Date"
                                selected={addSesstionData.SessionDate == '' ? new Date() : addSesstionData.SessionDate} onChange={(date) => {
                                setAddSesstionData({
                                    ...addSesstionData,
                                    ['SessionDate']: date
                                })
                            }} />

                        </li>
                        <li className="wd333"><label>Session Start Time</label>
                            <select className="browser-default" name="SessionStartTime" onChange={getDataSession}>
                                {timeListDropDown()}
                            </select>

                        </li>

                        <li className="wd333 fullInner"><label>Session End Time</label>
                            <select className="browser-default" name="SessionEndTime" onChange={getDataSession}>
                                {timeListDropDown()}
                            </select>

                        </li>

                        <li className="wd25"><label>Registration Start Date</label>

                            <DatePicker
                                minDate={new Date()}
                                todayButton="Today Date"
                                selected={addSesstionData.StartDate == '' ? new Date() : addSesstionData.StartDate}
                                onChange={(date) => {
                                setAddSesstionData({
                                    ...addSesstionData,
                                    ['StartDate']: date
                                })
                            }} />

                            
                        </li>
                        <li className="wd25"><label>Start time</label>
                            <select className="browser-default" name="StartTime" onChange={getDataSession}>
                                {timeListDropDown()}
                            </select>

                        </li>
                        <li className="wd25"><label>Registration End Date</label>
                            {/*<input type="date" name="EndDate" value={addSesstionData.EndDate} onChange={getDataSession} />*/}
                            <DatePicker
                                todayButton="Today Date"
                                maxDate={new Date('12/31/9999')}
                                selected={addSesstionData.EndDate == '' ? new Date('12/31/9999') : addSesstionData.EndDate} onChange={(date) => {
                                setAddSesstionData({
                                    ...addSesstionData,
                                    ['EndDate']: date
                                })
                                    let changeDate = new Date(date).toLocaleDateString();
                                    let endTime = document.getElementById('addSessionEndTime');
                                    if (changeDate == '12/31/9999' || changeDate > '12/31/9999') {
                                        endTime.disabled = true
                                    } else {
                                        endTime.disabled = false
                                    }
                            }
                            } />

                        </li>
                        <li className="wd25 fullInner"><label>End time</label>
                            <select className="browser-default" name="EndTime" id="addSessionEndTime" disabled onChange={getDataSession}>
                                {timeListDropDown()}
                            </select>
                        </li>
                    </ul>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={saveSessionData}>Save</Button>
                    <Button variant="secondary" onClick={sessionModalHandleClose}>Close</Button>
                    
                </Modal.Footer>
                   
            </Modal>

            {/* Add Event session modal End*/}



            {/* Edit Event session modal Start*/}
            <Modal
                show={showEditSessionModal}
                onHide={sessionEditModalHandleClose}
                backdrop="static"
                keyboard={false}
                animation={false}
                
                enforceFocus={false}
                className="addEventPage">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Session</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                     
   
                    <ul>
                        <li><label>Topic<span>*</span></label><input type="text" className="form-control" onChange={getEditDataSession} value={editSesstionData.Topic} name="Topic" maxLength='255' /></li>
                        <li><label>Presenter</label><input type="text" className="form-control" onChange={getEditDataSession} value={editSesstionData.Presenter} name="Presenter" maxLength='255' /></li>
                        <li><label>Location<span>*</span></label><input type="text" className="form-control" onChange={getEditDataSession} value={editSesstionData.Location} name="Location" maxLength='255' /></li>
                        <li><label>Description</label><input type="text" className="form-control" onChange={getEditDataSession} value={editSesstionData.Description} name="Description" maxLength='255' /></li>
                        <li className="wd50"><label>Confirm Count</label><input type="text" maxLength="2" onPaste={e => { e.preventDefault() }} onKeyPress={(event) => { if (! /\d/.test(event.key)) { event.preventDefault() } }} className="form-control" name="ConfirmCount" value={editSesstionData.ConfirmCount} onChange={getEditDataSession} /></li>
                        <li className="wd50 fullInner"><label>Backup Count</label><input onPaste={e => { e.preventDefault() }} maxLength="2" type="text" onKeyPress={(event) => { if (! /\d/.test(event.key)) { event.preventDefault() } }} className="form-control" name="BackupCount" value={editSesstionData.BackupCount} onChange={getEditDataSession} /></li>
                        <li>
                            <label>Confirm Message<span>*</span></label>                           
                            <Editor
                                value={editSesstionData.ConfirmMessage}

                                init={{
                                    plugins: 'link image code',
                                    height: 200,
                                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
                                }}
                                onChange={(event, editor) => {
                                    const data = event.target.getContent();
                                    setEditSesstionData({
                                        ...editSesstionData,
                                        ['ConfirmMessage']: data
                                    });
                                }
                                }
                            />
                        </li>
                        
                        <li>
                            <label>Backup Message</label>
                            <Editor
                                value={editSesstionData.BackupMessage}

                                init={{
                                    plugins: 'link image code',
                                    height: 200,
                                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
                                }}
                                onChange={(event, editor) => {
                                    const data = event.target.getContent();
                                    setEditSesstionData({
                                        ...editSesstionData,
                                        ['BackupMessage']: data
                                    });
                                }
                                }
                            />

                        </li>
                       
                        <li className="wd333"><label>Session Date</label>
                            <DatePicker
                                minDate={new Date()}
                                todayButton="Today Date"
                                selected={editSesstionData.SessionDate == '' ? new Date() : new Date(editSesstionData.SessionDate)} onChange={(date) => {
                               setEditSesstionData({
                                   ...editSesstionData,
                                   ['SessionDate']: date,
                                })
                            }} />

                          

                        </li>
                        <li className="wd333"><label>Session Start Time </label>
                            <select className="browser-default" name="SessionStartTime" onChange={getEditDataSession}>
                                {editSesstionData.SessionStartTime != '' && editSesstionData.SessionStartTime != null ? timeListDropDown(editSesstionData.SessionStartTime) : timeListDropDown()}
                               
                            </select>

                        </li>

                        <li className="wd333 fullInner"><label>Session End Time</label>
                            <select className="browser-default" name="SessionEndTime" onChange={getEditDataSession}>
                                {editSesstionData.SessionEndTime != '' && editSesstionData.SessionEndTime != null ? timeListDropDown(editSesstionData.SessionEndTime) : timeListDropDown() }
                             </select>

                        </li>
                        
                        <li className="wd25"><label>Registration Start Date</label>

                            <DatePicker
                                minDate={new Date()}
                                todayButton="Today Date"
                                selected={editSesstionData.StartDate == '' ? new Date() : new Date(editSesstionData.StartDate)}
                                onChange={(date) => {
                                    setEditSesstionData({
                                        ...editSesstionData,
                                        ['StartDate']: date
                                    })
                                }} />


                        </li>
                        <li className="wd25"><label>Start time</label>
                            <select className="browser-default" name="StartTime" onChange={getEditDataSession}>
                                {editSesstionData.StartTime != '' && editSesstionData.StartTime != null ? timeListDropDown(editSesstionData.StartTime) : timeListDropDown()}
                            </select>

                        </li>
                        <li className="wd25"><label>Registration End Date</label>
                            {/*<input type="date" name="EndDate" value={addSesstionData.EndDate} onChange={getDataSession} />*/}
                            <DatePicker
                                todayButton="Today Date"
                                maxDate={new Date('12/31/9999')}
                                selected={editSesstionData.EndDate == '' ? new Date('12/31/9999') : new Date(editSesstionData.EndDate)} onChange={(date) => {
                                setEditSesstionData({
                                    ...editSesstionData,
                                    ['EndDate']: date
                                })
                                    let changeDate = new Date(date).toLocaleDateString();
                                    let endTime = document.getElementById('editSessionEndDate');
                                    if (changeDate == '12/31/9999' || changeDate > '12/31/9999') {
                                        endTime.disabled = true
                                    } else {
                                        endTime.disabled = false
                                    }
                            }
                            } />

                        </li>
                        <li className="wd25 fullInner"><label>End time</label>
                            <select className="browser-default" name="EndTime" onChange={getEditDataSession} id="editSessionEndDate" disabled={new Date(editSesstionData.EndDate).toLocaleDateString() == new Date('12/31/9999').toLocaleDateString() ? 'true' : false}>
                                {editSesstionData.EndTime != '' && editSesstionData.EndTime != null ? timeListDropDown(editSesstionData.EndTime) : timeListDropDown()}
                             </select>
                        </li>
                    </ul>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={saveEditSessionData}>Save</Button>
                    <Button variant="secondary" onClick={sessionEditModalHandleClose}>Close</Button>
                  
                </Modal.Footer>

            </Modal>

            {/* Edit Event session modal End*/}




            <MessageBox show={showMessageBox} onHide={HideMessageBox} type={messageType} message={message} />


            {/*  <Button variant="primary" onClick={handleShow}>Launch demo modal</Button>

 <button className="btn btn-primary" onClick={hideShowPreviewModal}>Test Modal</button>
            <Button variant="primary" onClick={showModalMessage}>Modal For Message Display      </Button>*/}


            {/*
             Modal For Preview Start
             */}
            <Modal
                show={previewModal}
                onHide={closePreviewModal}
                backdrop="static"
                keyboard={false}
                animation={false}
                enforceFocus={false}
                size='xl'
                className="previewModal addEventPage">

                
                {previewModalRenderData()}
                <p>&nbsp;</p>
                <Modal.Footer>
                    
                    <Button variant="primary" onClick={closePreviewModal}>Close</Button>
                </Modal.Footer>


            </Modal> {/*
             Modal For Preview End
             */}


            {// Modal Register user show Start
              }
            <Modal
                show={showRegisterUserModal}
                onHide={closeRegisterUserModal}
                backdrop="static"
                keyboard={false}
                animation={false}
                enforceFocus={false}
                size='xl'
                className="previewModal addEventPage">
                

                {renderRegisterUserModal()}
                <p>&nbsp;</p>
                <Modal.Footer>

                    <Button variant="primary" onClick={closeRegisterUserModal}>Close</Button>
                </Modal.Footer>


                </Modal>
            {// Modal Register user show End
            }


        </Fragment>
    )
}
export default Dashbaord;

import React, { Component } from 'react';
import 'materialize-css';
import { NavMenu } from './NavMenu';
import { Spinner } from 'react-bootstrap';
export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
        <>
            <NavMenu style={{ display: 'none' }} />
            {this.props.children}

            <div className="loaderOverlay" id="loader" >
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>

        </>
    );
  }
}

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import HopCms from './HopCms/HopCms';
import App from './App';
import registerServiceWorker, { unregister } from './registerServiceWorker';
import { createStore } from 'redux';
import { countReducer } from '../src/Store/Reducer'
import { Provider } from 'react-redux';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
let store = createStore(countReducer);
const urlStr = window.location.href.split('/');
const urlIndex = urlStr.indexOf('HopCMS');
if (urlIndex != -1) {
    window.document.body.classList.add('dashboard-page')
  
}

ReactDOM.render(

    <BrowserRouter basename={baseUrl}>
        <Provider store={store}>
          
            {urlIndex < 1 ? (<App />) : (<HopCms />)}
        </Provider>

    </BrowserRouter>
    ,
    rootElement);

//registerServiceWorker();
unregister();



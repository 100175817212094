import React, { Component } from 'react';
import { HttpRequest, ShowLoader, HTMLTableExport } from '../common/CommonOperations';
import { Link } from 'react-router-dom';
export class SelfClose extends Component {
    static displayName = SelfClose.name;
    constructor(props) {
        super(props);
        this.state = {
            tableData: '',
        }
    }
    async componentDidMount() {
        
        await this.getChartData();
        await HTMLTableExport(document.getElementById('OrganizationChart'), 'OrganizationChart');
        await setTimeout(this.onClose(), 2000);
    }
    componentWillUnmount() {
        clearInterval(this.setTimeout)
    }

    onClose = () => {
        window.opener = null;
        window.open("", "_self");
        window.close();
    };

    getChartData = async () => {
        // GetEmployeeOrgChartDetails
        let windowLocation = window.location.href.split('/');

        let empId = '150348';
        if (windowLocation.length > 4 && windowLocation[windowLocation.length - 1] != '') {
            empId = windowLocation[windowLocation.length - 1];
        }

        var response = await HttpRequest('BusinessCardEmployeeProfile/GetEmployeeOrgChartDetails/' + empId, 'get');
        const res = await response.json();
        
        this.setState({ tableData: res.OrgChartInfo })        
    }

    renderTable = () => {
        if (this.state.tableData != '' && this.state.tableData != null) {

            return this.state.tableData.map((item, index) => {
                return (
                    <>
                        <tr>
                            <td>{item.PersonID}</td>
                            <td>{item.PersonName}</td>
                            <td>{item.PersonJobName}</td>
                            <td>{item.PersonEmployeeNumber}</td>
                        </tr>
                    </>
                )
            })

        }
    }



    

    render() {
       

        return (
            <>
                <table id='OrganizationChart' style={{ display: 'none' }}>
                    <thead>
                        <tr>
                            <th>Person Id</th>
                            <th>Full Name</th>
                            <th>Job Name</th>
                            <th>Employee Number</th>
                            			
                        </tr>
                    </thead>
                    <tbody >{this.renderTable()}</tbody>

                </table>

                <h1>Self Close</h1>
            </>
        );
    }
}



import React, { Component } from 'react';
import { HttpRequest} from '../common/CommonOperations';
import './style/custom.css';
export class AcademyAwards extends Component {
    static displayName = AcademyAwards.name;
    constructor(props) {
        super(props);
        this.state = { categoryList: [], userResponseDetail: [], totalPicksCount: 0, selectedPicksCount: 0, isInProgess: false, isComplete: false };
        this.UserDetails = this.props.UserDetails;
    }
    componentDidMount() {
        console.log('AcademyAwards NTLogin:', this.props.UserDetails.NTLogin);
        if (this.props.UserDetails.NTLogin == null || this.props.UserDetails.AcademyAwardsEndDate == null) return
        else {
            let awardsLastDate = this.props.UserDetails.AcademyAwardsEndDate;
            var todaydate = (new Date()).valueOf();
            var awardsEndDate = (new Date(awardsLastDate)).valueOf();
            if (awardsEndDate < todaydate) {
                let path = "/AcademyAwards/Leaderboard";
                this.props.history.push(path)
            }

            else {
                this.GetAcademyResults();
                document.title = 'Academy Awards';
            }

        }
    }
    goToSelectPicks = () => {
        var path = "/AcademyAwards/SelectPicks";
        this.props.history.push(path)
    }

    GetAcademyResults = async () => {
        var response = await HttpRequest('AcademyAwards/GetAcademyResults', 'get');
        const res = await response.json();
        if (res) {
            this.setState({ categoryList: res.AcademyNominationCategories, userResponseDetail: res.AcademyNominationUserResponses });
            this.SetAcademyNominationCatPropsForSelection();
        }
        if (this.state.userResponseDetail != null && this.state.userResponseDetail.length > 0)
            this.props.history.push('/AcademyAwards/SelectPicks')
    }
    SetAcademyNominationCatPropsForSelection() {
        this.setState((state) => ({ totalPicksCount: this.state.categoryList.length + 1 }));
        for (var i = 0; i < this.state.categoryList.length; i++) {
            if (this.state.userResponseDetail)
                this.setState((state) => ({ selectedPicksCount: this.state.userResponseDetail.length, isInProgess: true }))
            else
                this.setState((state) => ({ selectedPicksCount: 0, isInProgess: false }))
        }
        this.setState((state) => ({ isComplete: this.state.totalPicksCount === this.state.selectedPicksCount }));
        if (this.state.isInProgess && !this.state.isComplete) {
            var path = "/AcademyAwards/SelectPicks";
            this.props.history.push(path)
        }
        else if (this.state.isComplete) {
            var path = "/AcademyAwards/Review";
            this.props.history.push(path)
        }
    }
    render() {
        return (
            <>
                <div className="aaMainPage">
                    <div className="container">
                        <div className="col m12 s12">
                            <div className="col m12 s12 aaMainBlock">
                                <img src="/images/AcademyAwards/bannerImg.jpg" className="img-responsive center-block" alt="" />
                                <div className="col m12 s12 text-center" style={{ padding: '40px 0' }}>
                                    <img src="/images/AcademyAwards/dishWhiteLogo.png" style={{ height: '50px' }} alt="" />
                                </div>
                                <div className="aaMainPageTextBlock mainblock" >
                                    <p>We know youve been oohing and ahhing at Aunjanue Ellis in King Richard, breathless at Kristen Stewart's performance in Spencer and marveling at Andrew Garfield's energy and vocals in tick, tick�BOOM! Time to put your movie viewing to good use.</p>                                      
                                    <p>That�s right�the Academy Awards Challenge is back!</p>
                                    <p>Was there a film that you think will take home an Oscar (or five)? Now is the time to tell us! Do you think Encanto will win Best Original Song? Will the sands of Dune be enough to snag top production design honors? Will Drive My Car be driving home with an iconic Oscar statuette</p>
                                    <p>Click "Make Your Picks" to begin. For each of the 23 awards, select the tile of the person or movie you think will take home the award. Each award has a weighted point value for a total of 375 points. Make your selections for this year's Academy Awards Challenge by <strong>12 p.m. MT on Sunday, March 27.</strong></p>

                                </div>
                                <div className="col m12 s12 text-center" >
                                    <button onClick={() => this.goToSelectPicks()} type="button" className="btn btn-primary" style={{ margin: 'auto', padding: '0 20px' }}>Make your Picks</button>
                                </div>
                                <br /><br /><br />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );

    }
}

import React, { Component } from 'react';
import { HttpRequest, ShowLoader } from '../common/CommonOperations';
import { Link } from 'react-router-dom';
export class ProfilePage extends Component {
    static displayName = ProfilePage.name;
    constructor(props) {
        super(props);
        this.state = {
            EmployeeDetail: '',  
            DirectReports: '',
            EmployeeHirerachy: '',
            hideContent: true,
        }
    }
    componentDidMount() {
        this.GetSearchDetailsByPersonID();
        this.getChartData();
    }

    GetSearchDetailsByPersonID = async () => {
        //var response = await HttpRequest('BusinessCardEmployeeProfile/GetSearchDetailsByPersonID/' + this.UserDetails.PersonID, 'get');
        let windowLocation = window.location.href.split('/');
      
        let empId =  '150348';
        if (windowLocation.length > 4 && windowLocation[windowLocation.length - 1] !='') {
            empId = windowLocation[windowLocation.length - 1];
        }
        //empId = empId == '' ? this.UserDetails.PersonID : empId
        var response = await HttpRequest('BusinessCardEmployeeProfile/GetSearchDetailsByPersonID/' + empId, 'get');
        const res = await response.json();
        if (res.EmployeeDetails != null) {
            this.setState({ EmployeeDetail: res.EmployeeDetails, DirectReports: res.DirectReports, EmployeeHirerachy: res.EmployeeHirerachy, hideContent:false })
        }
    }

    getChartData = async() => {
// GetEmployeeOrgChartDetails
       let empId = '150348';
        var response = await HttpRequest('BusinessCardEmployeeProfile/GetEmployeeOrgChartDetails/' + empId, 'get');
        const res = await response.json();
    }
    RenderEmployeeHirerachy = () => {


        if (this.state.EmployeeHirerachy == null || this.state.EmployeeHirerachy == undefined || this.state.EmployeeHirerachy.length == 0 || this.state.EmployeeHirerachy[0] == null) { return ''; }

        return this.state.EmployeeHirerachy.map((item, index) => {
            return (
                <>
                    <div className="listContianer">
                        {index > 0 ? <div className="borderCenter"></div>:''}
                        <div className="reportingBlock">
                            <a href={'/ProfilePage/' + item.SearchHierarchyPersonID }>{item.SearchHierarchyName}</a><br />
                            {item.SearchHierarchyJobName}
                        </div>
                    </div>
                </>
                )
        });
    }


    



    TitleDetails = () => {
     
        if (this.state.EmployeeDetail == null || this.state.EmployeeDetail == undefined || this.state.EmployeeDetail.length == 0) { return '' }; 
return (
    <>
        <h1>{this.state.EmployeeDetail.FullName}
            <span>{this.state.EmployeeDetail.JobName}</span>   
        </h1>
        <img src={this.state.EmployeeDetail.CompanyImageUrl} />
    </>
    )
    }

    RenderDirectReports = () => {
        if (this.state.DirectReports == null || this.state.DirectReports == undefined || this.state.DirectReports.length == 0) { return '' }; 
        return this.state.DirectReports.map((item, index) => {
            return (
                <>
                    <div className="col l3 m3 s12">
                        <div className="directEmpBox">
                            {item.SearchHierarchyImage != null ? <img src={'data:image/JPEG;base64, ' + item.SearchHierarchyImage} /> : <img src='images/Search/AnonymousUser.png' />}
                            <span>
                                <a href={'/ProfilePage/' + item.SearchHierarchyPersonID}>{item.SearchHierarchyName}</a><br />
                                {item.SearchHierarchyJobName}
                            </span>
                        </div>
                    </div>
                </>
                )
        })
    }


    EmployeePhotoRenderSection = () => {
        if (this.state.EmployeeDetail == null || this.state.EmployeeDetail == undefined || this.state.EmployeeDetail.length == 0) { return '' }; 
        return (
            <>
                <li><img src={this.state.EmployeeDetail.EmpImage != null ? 'data:image/JPEG;base64, ' + this.state.EmployeeDetail.EmpImage : 'images/Search/AnonymousUser.png'} /></li>
                <li className="profilePic">{this.state.EmployeeDetail.LocationName}</li>
                <li><a href={'https://mail.google.com/mail/?view=cm&fs=1&to=' + this.state.EmployeeDetail.EmailAddress} target="_blank">{this.state.EmployeeDetail.EmailAddress}</a></li>
                <li className="badgeImgGroup">
                    <span style={{ display: this.state.EmployeeDetail.DishscriberImageUrl != '' && this.state.EmployeeDetail.DishscriberImageUrl != null ? 'block' : 'none' }}><img src={'https://intranet.global.dish.com/' + this.state.EmployeeDetail.DishscriberImageUrl} /> </span>
                    <span style={{ display: this.state.EmployeeDetail.SlingscriberImageUrl != '' && this.state.EmployeeDetail.SlingscriberImageUrl != null ? 'block' : 'none' }}><img src={'https://intranet.global.dish.com/' + this.state.EmployeeDetail.SlingscriberImageUrl} /></span>
                    <span style={{ display: this.state.EmployeeDetail.isEmpBaseCampCompleted == true ?'block':'none'}}><img src='https://intranet.global.dish.com/Images/Search/BasecampBadge.png' /></span>
                    <span style={{ display: this.state.EmployeeDetail.IsBoostScriber == true ? 'block' : 'none' }}><img src='https://intranet.global.dish.com/Images/Search/BoostscriberBadge.png' /></span>
                    <span style={{ display: this.state.EmployeeDetail.PatentsCount != null && this.state.EmployeeDetail.PatentsCount != '' ? 'block' : 'none' }}><img src='https://intranet.global.dish.com/images/Search/patentbadge.png' />
                        <span className='text-box'>
                            {this.state.EmployeeDetail.PatentsCount }
                        </span>
                    </span>

                    <span className="anniversaryDate" dangerouslySetInnerHTML={{ __html: this.getYearDiff(this.state.EmployeeDetail.AnniversaryDate) }}></span>
                    <span style={{ display: this.state.EmployeeDetail.DSAChairmans != '' && this.state.EmployeeDetail.DSAChairmans !=null ? 'block' :'none'}}><img src='https://intranet.global.dish.com/Images/Search/DSAChairmans.png' title={this.state.EmployeeDetail.DSAChairmans} /></span>
                    <span style={{ display: this.state.EmployeeDetail.EmployeeSharesImageUrl != '' && this.state.EmployeeDetail.EmployeeSharesImageUrl != null ? 'block' : 'none' }}> <img src={'https://intranet.global.dish.com/' + this.state.EmployeeDetail.EmployeeSharesImageUrl} /></span>
                    <span style={{ display: this.state.EmployeeDetail.LiftBadgeImageUrl != '' && this.state.EmployeeDetail.LiftBadgeImageUrl != null  ? 'block' : 'none' }}> <img src={'https://intranet.global.dish.com/' + this.state.EmployeeDetail.LiftBadgeImageUrl} /></span>
    
                </li>

            </>
        )
    }

    DateCal = (date) => {
        if (date != null || date != '') {
            let givenDate = new Date(date);
            let dates, months, years;
            dates = givenDate.getDate();
            months = givenDate.getMonth()+1;
            years = givenDate.getFullYear();
            return `${months < 10 ? '0' + months : months}/${dates < 10 ? '0' + dates : dates}/${years}`;
        }
        
        
    }

    getYearDiff = (useDate) => {
        if (useDate == null) { return ''; }
    let startDate = new Date(useDate);
    let endDate = new Date();
    let currentAnniversaryDate, anniversaryCount;
    let yearDiff = endDate.getFullYear() - startDate.getFullYear();

    if (startDate.getMonth() > endDate.getMonth()) {
        yearDiff--;
    }
    else if (startDate.getMonth() === endDate.getMonth()) {
        if (startDate.getDate() > endDate.getDate()) {
            yearDiff--;
        }
    }
    var d = new Date(startDate);
    if (yearDiff >= 1 && yearDiff < 5) {

        d.setFullYear(d.getFullYear() + 1);
        currentAnniversaryDate = d.getFullYear()
        anniversaryCount = 1;
    }

    else if (yearDiff >= 5 && yearDiff < 10) {
        d.setFullYear(d.getFullYear() + 5);
        currentAnniversaryDate = d.getFullYear();
        anniversaryCount = 5;
    }

    else if (yearDiff >= 10 && yearDiff < 15) {
        d.setFullYear(d.getFullYear() + 10);
        currentAnniversaryDate = d.getFullYear();
        anniversaryCount = 10;
    }

    else if (yearDiff >= 15 && yearDiff < 20) {
        d.setFullYear(d.getFullYear() + 15);
        currentAnniversaryDate = d.getFullYear();
        anniversaryCount = 15;
    }
    else if (yearDiff >= 20 && yearDiff < 25) {
        d.setFullYear(d.getFullYear() + 20);
        currentAnniversaryDate = d.getFullYear();
        anniversaryCount = 20;
    }
    else if (yearDiff >= 25 && yearDiff < 30) {
        d.setFullYear(d.getFullYear() + 25);
        currentAnniversaryDate = d.getFullYear();
        anniversaryCount = 25;
    }
    else if (yearDiff >= 30 && yearDiff < 35) {
        d.setFullYear(d.getFullYear() + 30);
        currentAnniversaryDate = d.getFullYear();
        anniversaryCount = 30;
    }
    else if (yearDiff >= 35 && yearDiff < 40) {
        d.setFullYear(d.getFullYear() + 35);
        currentAnniversaryDate = d.getFullYear();
        anniversaryCount = 35;
    }
    else if (yearDiff >= 40 && yearDiff < 45) {
        d.setFullYear(d.getFullYear() + 40);
        currentAnniversaryDate = d.getFullYear();
        anniversaryCount = 40;
    }
        if (anniversaryCount == 1) {
            return `<img src="https://intranet.global.dish.com/Images/AnniversaryBadge/1st_anniv_badge.png" /><span class="an1">${currentAnniversaryDate}</span>`;
        } else if (anniversaryCount == 5) {
            return `<img src="https://intranet.global.dish.com/Images/AnniversaryBadge/${anniversaryCount}th_anniv_badge.png" /><span class="an5">${currentAnniversaryDate}</span>`
        } else if (anniversaryCount == 10) {
            return `<img src="https://intranet.global.dish.com/Images/AnniversaryBadge/${anniversaryCount}th_anniv_badge.png" /><span class="an10">${currentAnniversaryDate}</span>`
        } else if (anniversaryCount == 15) {
            return `<img src="https://intranet.global.dish.com/Images/AnniversaryBadge/${anniversaryCount}th_anniv_badge.png" /><span class="an15">${currentAnniversaryDate}</span>`
        }else if (anniversaryCount == 20) {
            return `<img src="https://intranet.global.dish.com/Images/AnniversaryBadge/${anniversaryCount}th_anniv_badge.png" /><span class="an20">${currentAnniversaryDate}</span>`
        } else if (anniversaryCount == 25) {
            return `<img src="https://intranet.global.dish.com/Images/AnniversaryBadge/${anniversaryCount}th_anniv_badge.png" /><span class="an25">${currentAnniversaryDate}</span>`
        } else if (anniversaryCount == 30) {
            return `<img src="https://intranet.global.dish.com/Images/AnniversaryBadge/${anniversaryCount}th_anniv_badge.png" /><span class="an30">${currentAnniversaryDate}</span>`
        } else if (anniversaryCount == 35) {
            return `<img src="https://intranet.global.dish.com/Images/AnniversaryBadge/${anniversaryCount}th_anniv_badge.png" /><span class="an35">${currentAnniversaryDate}</span>`
        } else if (anniversaryCount == 40) {
            return `<img src="https://intranet.global.dish.com/Images/AnniversaryBadge/${anniversaryCount}th_anniv_badge.png" /><span  class="an45">${currentAnniversaryDate}</span>`
        }else {
            return '';   
        }
}


    EmployeeInfoSectionRender = () => {
        if (this.state.EmployeeDetail == null || this.state.EmployeeDetail == undefined || this.state.EmployeeDetail.length == 0) { return '' }; 
        return (
            <>
                <div className="col s12 l4 m4">
                    <ul className="employeeInformationDisplayList">
                        <li><span>Employee #: </span>{this.state.EmployeeDetail.EmployeeNumber}</li>
                        <li><span>NT Login: </span>{this.state.EmployeeDetail.NtLogin}</li>
                        <li><span>Oracle ID: </span>{this.state.EmployeeDetail.OracleId}</li>
                        <li style={{ display: this.state.EmployeeDetail.LocationID != '' && this.state.EmployeeDetail.LocationID !=null?'block':'none'}}><span>Location ID: </span>{this.state.EmployeeDetail.LocationID}</li>
                        <li style={{ display: this.state.EmployeeDetail.DepartmentName != '' && this.state.EmployeeDetail.DepartmentName ? 'block':'none'}}><span>Department Name: </span>{this.state.EmployeeDetail.DepartmentName}</li>
                        <li style={{ display: this.state.EmployeeDetail.SeatingLocation != '' && this.state.EmployeeDetail.SeatingLocation != null ? 'block' : 'none' }}><span>Seating Location: </span>{this.state.EmployeeDetail.SeatingLocation}</li>
                        <li style={{ display: this.state.EmployeeDetail.DepartmentID != '' && this.state.EmployeeDetail.DepartmentID != null ? 'block':'none'}}><span>Department ID: </span>{this.state.EmployeeDetail.DepartmentID}</li>
                        <li style={{ display: this.state.EmployeeDetail.LegacyID != '' && this.state.EmployeeDetail.LegacyID !=null?'block':'none'}}><span>Legacy ID: </span>{this.state.EmployeeDetail.LegacyID}</li>
                        <li style={{ display: this.state.EmployeeDetail.HireDate != '' && this.state.EmployeeDetail.HireDate != null ? 'block' : 'none' }}><span>Latest Hire Date: </span>{this.DateCal(this.state.EmployeeDetail.HireDate)}</li>
                        <li style={{ display: this.state.EmployeeDetail.AnniversaryDate != '' && this.state.EmployeeDetail.AnniversaryDate != null ? 'block':'none'}}><span>Anniversary Date: </span>{this.DateCal(this.state.EmployeeDetail.AnniversaryDate)}</li>
                        {this.state.EmployeeDetail.JobName != 'Contractor' ?<>
                            <li style={{ display:this.state.EmployeeDetail.HR1PersonID != '' && this.state.EmployeeDetail.HR1PersonID != null?'block':'none' }}><span>Primary HR Business Partner: </span><Link to={/ProfilePage/ + this.state.EmployeeDetail.HR1PersonID}>{this.state.EmployeeDetail.HR1FullName}</Link></li>
                            <li style={{ display: this.state.EmployeeDetail.HR2PersonID != '' && this.state.EmployeeDetail.HR1PersonID != null ? 'block' : 'none' }}><span>Secondary HR Business Partner: </span><Link to={/ProfilePage/ + this.state.EmployeeDetail.HR2PersonID}>{this.state.EmployeeDetail.HR2FullName}</Link></li>
                            </>:''
                            }
                        
                        <li style={{ display: this.state.EmployeeDetail.OfficePhoneNumber != '' && this.state.EmployeeDetail.OfficePhoneNumber !=null?'block':'none'}}><span>Office Phone Number: </span>{this.state.EmployeeDetail.OfficePhoneNumber}</li>
                        <li style={{ display: this.state.EmployeeDetail.DeskPhoneExtension != '' && this.state.EmployeeDetail.DeskPhoneExtension != null ? 'block':'none'}}><span>Desk Phone Extension: </span>{this.state.EmployeeDetail.DeskPhoneExtension}</li>
                        <li style={{ display: this.state.EmployeeDetail.MobilePhoneNumber != '' && this.state.EmployeeDetail.MobilePhoneNumber != null?'block':'none' }}><span>Mobile Phone Number: </span>{this.state.EmployeeDetail.MobilePhoneNumber}</li>
                        

                    </ul>
                </div>
                <div className="col s12 l4 m4">
                    <ul className="employeeInformationDisplayList">
                        <li style={{ display: this.state.EmployeeDetail.TeamName != '' && this.state.EmployeeDetail.TeamName != null ? 'block' : 'none' }}><span>Team Name: </span>{this.state.EmployeeDetail.TeamName}</li>
                        <li style={{ display: this.state.EmployeeDetail.ProfileDescription != '' && this.state.EmployeeDetail.ProfileDescription != null ? 'block' : 'none' }}><span>Job Description: </span>{this.state.EmployeeDetail.ProfileDescription}</li>
                        
                        
                        <li style={{ display: this.state.EmployeeDetail.PreferredPronouns != '' && this.state.EmployeeDetail.PreferredPronouns != null ? 'block' : 'none' }}><span>Preferred Pronouns: </span>{this.state.EmployeeDetail.PreferredPronouns}</li>
                        <li style={{ display: this.state.EmployeeDetail.ERGMembership != '' && this.state.EmployeeDetail.ERGMembership !=null?'block': 'none'}}><span>ERG Membership: </span>{this.state.EmployeeDetail.ERGMembership}</li>
                        <li style={{ display: this.state.EmployeeDetail.ProfileQuestion1 != '' && this.state.EmployeeDetail.ProfileQuestion1 !=null ? 'block':'none'}}><span>{this.state.EmployeeDetail.ProfileQuestion1}</span> {this.state.EmployeeDetail.ProfileAnswer1}</li>
                        <li style={{ display: this.state.EmployeeDetail.ProfileQuestion2 != '' && this.state.EmployeeDetail.ProfileQuestion2 != null ? 'block' : 'none' }}><span>{this.state.EmployeeDetail.ProfileQuestion2}</span> {this.state.EmployeeDetail.ProfileAnswer2}</li>
                        <li style={{ display: this.state.EmployeeDetail.ProfileQuestion3 != '' && this.state.EmployeeDetail.ProfileQuestion3 != null ? 'block' : 'none' }}><span>{this.state.EmployeeDetail.ProfileQuestion3}</span> {this.state.EmployeeDetail.ProfileAnswer3}</li>
                        <li style={{ display: this.state.EmployeeDetail.ProfileQuestion4 != '' && this.state.EmployeeDetail.ProfileQuestion4 != null ? 'block' : 'none' }}><span>{this.state.EmployeeDetail.ProfileQuestion4}</span> {this.state.EmployeeDetail.ProfileAnswer4}</li>
                        <li style={{ display: this.state.EmployeeDetail.ProfileQuestion5 != '' && this.state.EmployeeDetail.ProfileQuestion5 != null ? 'block' : 'none' }}><span>{this.state.EmployeeDetail.ProfileQuestion5}</span> {this.state.EmployeeDetail.ProfileAnswer5}</li>
                       
                    </ul>
                </div>
           </>
            )
    }



    render() {
        return (
            <>
                <div className="container" style={{ display: this.state.hideContent == true? 'none':'block' }}>
                    <div className="row">
                        <div className="col l10 m10 s12">
                            <div className="row">
                                <div className="col l12 m12 s12">
                                    <div className="card horizontal employeeProfileReview">
                                      
                                        <div className="card-stacked">
                                            <div className="card-content">
                                                <div className="row">
                                                    <div className="col l12 m12 s12">
                                                        <div className="titleContianer">
                                                            {this.TitleDetails()}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col s12 l3 m3">
                                                        <ul className="profileImgSection">
                                                            {this.EmployeePhotoRenderSection()}
                                                        </ul>
                                                    </div>
                                                    {this.EmployeeInfoSectionRender()}
                                                </div>

                                                <div className="row">
                                                    
                                                        {this.RenderDirectReports()}
                                                    
                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                  
                                </div>
                            </div>

                           
                            <div className="row">
                                <div className="col l12 m12 s12">
                                </div>
                            </div>
                            <div className="row">
                                <div className="col l12 m12 s12">
                                </div>
                            </div>
                        </div>
                        <div className="col l2 m2 s12">
                            <div className="employeeHirerachy">
                                {this.RenderEmployeeHirerachy()}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

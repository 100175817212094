import React, { Component } from 'react';
import { HttpRequest, HttpRequestWithouLoader, GetStartAndEndDate, CheckSupportIDException, HTMLTableExport, ShowLoader, ActiveMenu, validateEmail } from '../common/CommonOperations';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import dateFormat from 'dateformat';
import { Select } from 'react-materialize';
import { MessageBox } from '../../components/common/modalBox/MessageBox';


export class PayrollPasswordReset extends Component {
    static displayName = PayrollPasswordReset.name;
    constructor(props) {
        super(props);
        this.state = { otherSelect: false, showMessageBox: false, message: '', messageType: '1', blobFile: null};
        this.UserDetails = this.props.UserDetails; 
      //  this.getBlobFile = this.getBlobFile.bind(this)
    }
    componentDidMount() {

        //let menuTittleName = this.props.history.location.pathname;
        //var employeeNumber = null;
        //debugger;
        //var managerEmployeeNumber = null;
        //let splitId = menuTittleName.split('/');
        //if (splitId.length > 2) {
        //    employeeNumber = splitId[2].toString();

        //    if (employeeNumber != null && splitId.length > 3) {
        //        managerEmployeeNumber = splitId[3].toString();
        //    }
      //  this.getBlobFile();
       


        document.title = 'Payroll Work Center Login Assistance';
        //   }
    }

    getBlobFile = async () => {
        var path = 'images' + '/DeptContent/AcademyAwards2022/DUNE.jpg'
        fetch(path)
            .then((response) =>{
                return response.blob()
            }).catch(() => { console.log('error') })
            .then((blob) =>{
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => this.setState({ blobFile: reader.result.toString() })
                // here the image is a blob
            }).catch(() => { console.log('error') });
    }

    UpdateUserDetailFromGoogleApi = async () => {
        var objData = {
            customSchemas: {
                Questions: {
                    Question1: 'Best Burger in city',
                    Answer1: 'Burger King',
                     Question2: 'Best Burger in city 2',
                    Answer2: 'Burger King 2'
                }
            }
        }
        let param = {
            "NTName": "suhail.bandey",
            "Data": JSON.stringify(objData)
        }
        try {
            var updateResponse = await HttpRequest('SymptomTracker/UpdateUserDetailFromGoogleApi', 'POST', param);
            let res = await updateResponse.json();
           
        }
        catch (e) {

        }
    }



    ShowMessageBox = (message, messageType) => this.setState({ showMessageBox: true, messageType: messageType, message: message });
    HideMessageBox = () => this.setState({ showMessageBox: false });

    selectChange = (e) => {
        if (e.target.value == 'Other') {
            this.setState({ otherSelect: true })
            document.getElementById('otherReason').value = '';
        }
        else {
            this.setState({ otherSelect: false })
        }
    }

    clearData = () => {
        document.getElementById('firstName').value = '';
        document.getElementById('lastName').value = '';
        document.getElementById('dishEmployeeID').value = '';
        document.getElementById('emailId').value = '';
        document.getElementById('Select-37').value = '';
        document.getElementById('otherReason').value = '';
        document.getElementById('desRequest').value = '';
        let dd = document.querySelector('.select-wrapper .select-dropdown.dropdown-trigger');
    }
    submitPayroll = async (e) => {
        e.preventDefault();
        let firtName = document.getElementById('firstName').value;
        let lastName = document.getElementById('lastName').value;
        let employeeNumber = document.getElementById('dishEmployeeID').value;
        let emailAddress = document.getElementById('emailId').value;
        let reason = document.getElementById('Select-37').value;
        let otherReason = document.getElementById('otherReason');
        let desRequest = document.getElementById('desRequest').value;
        let reqEmpId = this.UserDetails.GoogleData.externalIds[0].value;
        let reqEmailAddress = this.UserDetails.GoogleData.primaryEmail;
        if (firtName.length == 0) {
            this.ShowMessageBox("First name cannot be blank", 3); return false;
        }
        else if (lastName.length == 0) {
            this.ShowMessageBox("Last name cannot be blank", 3); return false;
        }
        else if (employeeNumber.length == 0) {
            this.ShowMessageBox("Employee number cannot be blank", 3); return false;
        }

        else if (emailAddress.length == 0) {
            this.ShowMessageBox("Email address cannot be blank", 3); return false;
        }
        else if (!validateEmail(emailAddress)) {
            this.ShowMessageBox("Please enter a valid email address which include @ and .", 3); return false;
        }

        else if (reason.length == 0) {
            this.ShowMessageBox("Reason cannot be blank", 3); return false;
        }

        else if (reason == "Other" && otherReason.value.length == 0) {
            this.ShowMessageBox("Other Reason cannot be blank", 3); return false;
        }
        else if (desRequest.length == 0) {
            this.ShowMessageBox("Description cannot be blank", 3); return false;
        }

        let value = this.UserDetails.GoogleData.relations[0].value.split(',');
        // let managerEmailAddress = value[1] + '.' + value[0].replace('CN=', '').replace('\\', '') + '@dish.com';
        if (otherReason.value.length == 0)
            otherReason = null;

        let param = {
            FirstName: firtName, LastName: lastName, EmployeeNumber: employeeNumber,
            EmailAddress: emailAddress, Reason: reason, OtherReason: otherReason, Message: desRequest,
            RequestorFirstName: this.UserDetails.GoogleData.name.givenName, RequestorLastName: this.UserDetails.GoogleData.name.familyName,
            RequestorEmployeeNumber: reqEmpId, RequestorEmailAddress: reqEmailAddress
        }

        var response = await HttpRequest('Content/SendEmailPayrollPasswordResetRequest', 'POST', param);
        const responseData = await response.json();
        debugger
        if (responseData.IsSuccessful) {
            this.clearData();
            desRequest = '';
            otherReason = '';
            reason = '';
            this.ShowMessageBox("Your request has been received.<br /> Allow up to 2 business days for a Payroll WorkCenter administrator to contact you.<br /> Please note that as of September 10th, 2013, the Payroll WorkCenter website has been updated.<br/>Employees will need to set up a new account through the First Time User option (Registration Code: Dish-123456).<br /> Please consult the new Payroll WorkCenter registration instructions on the Pay and Timekeeping page of The Hop for further instructions.<br/> ", 1); return false;
        }
        else {
            this.ShowMessageBox((responseData.ErrorMessage == undefined) ? responseData.errorMessage : responseData.FriendlyMessage + responseData.ErrorMessage, 3);
            document.getElementById('desRequest').value = '';
            this.clearData();
            return false;
        }
    }

    formElements = () => {
        if (this.UserDetails.GoogleData == null || this.UserDetails.GoogleData == undefined) { return '' }

        return (
            <>
                <form onSubmit={this.submitPayroll}>

                    <div className="input-field">
                        <input id="firstName" type="text" defaultValue={this.UserDetails.GoogleData.name.givenName} className="validate" />
                        <label htmlFor="firstName" className="active">* First Name</label>
                    </div>
                    <div className="input-field">
                        <input id="lastName" type="text" defaultValue={this.UserDetails.GoogleData.name.familyName} className="validate" />
                        <label htmlFor="lastName" className="active" >* Last Name</label>
                    </div>
                    <div className="input-field">
                        <input id="dishEmployeeID" type="text" defaultValue={this.UserDetails.GoogleData.externalIds[0].value} className="validate" />
                        <label htmlFor="dishEmployeeID" className="active">* Dish EmployeeID</label>
                    </div>
                    <div className="input-field">
                        <input id="emailId" type="text" defaultValue={this.UserDetails.GoogleData.primaryEmail} className="validate" />
                        <label htmlFor="emailId" className="active">Email Address</label>
                    </div>


                    <Select
                        id="Select-37"
                        label="* Reason for Request"
                        multiple={false}
                        onChange={this.selectChange}
                        options={{
                            classes: '',
                            dropdownOptions: {
                                alignment: 'left',
                                autoTrigger: true,
                                closeOnClick: true,
                                constrainWidth: true,
                                coverTrigger: true,
                                hover: false,
                                inDuration: 150,
                                onCloseEnd: null,
                                onCloseStart: null,
                                onOpenEnd: null,
                                onOpenStart: null,
                                outDuration: 250
                            }

                        }}
                        value="" >
                        <option disabled value="">Select Reason for Request</option>
                        <option value="Direct Deposit">Direct Deposit</option>
                        <option value="W-4">W-4</option>
                        <option value="General Payroll Questions">General Payroll Questions</option>
                        <option value="Other">Other</option>
                    </Select>
                    <div className="input-field" style={{ display: this.state.otherSelect == true ? 'block' : 'none' }}>
                        <input id="otherReason" type="text" className="validate" />

                    </div>
                    <div className="input-field">
                        <textarea id="desRequest" type="text" className="materialize-textarea"></textarea>
                        <label htmlFor="desRequest">* Description of Request</label>
                    </div>

                    <div className="input-field">
                        <button className="btn btn-primary waves-effect waves-light" type="submit" name="action">Submit </button>

                    </div>
                </form>
            </>)

    }

    render() {
        if (this.UserDetails.GoogleData == null)
            return <></>
        return (
            <>
                <div className="container payrollPasswordReset">
                    <div className="row">

                        <div className="col s12 m12 l12">
                            <p></p>
                            <div className="row">
                                <div className="col s12 m12 l12">
                                    <div className="card horizontal">
                                        <div className="card-image">
                                            <img src="images/hoplogo.png" className="hoplogo-image" />
                                        </div>
                                        <div className="card-stacked">
                                            <div className="card-content">
                                                <div className="row">
                                                    <div className="col s12">
                                                        <h5>Payroll WorkCenter Login Assistance</h5>
                                                        <ul>
                                                            <li>Fill in the fields below, then press the <q>Submit</q> button to send your request.</li>
                                                            <li>Allow up to 2 business days for a Payroll WorkCenter administrator to contact you.</li>
                                                            <li>Payroll Administrators will solely provide email responses to your request. Please provide a valid email address.</li>
                                                            <li>Access PRWC from home at <a href="http://dish.okta.com" target="_blank">http://dish.okta.com</a></li>
                                                            <li>Use Dish NT Credentials when accessing PRWC from personal computer, kiosk, or mobile device.<br /> (User Name: First.Last@Dish.com Password: Your Dish NT Password)</li>
                                                        </ul>
                                                        <p></p>
                                                        <hr />
                                                        <p></p>
                                                    </div>
                                                </div>

                                                <div className="row">

                                                    <div className="col l6 m6 s12">
                                                        {this.formElements()}
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
                <MessageBox show={this.state.showMessageBox} onHide={this.HideMessageBox} type={this.state.messageType} message={this.state.message} />
            </>
        );
    }
}

import React, { Component } from 'react';
import { HttpRequest, ShowLoader, HTMLTableExport } from '../common/CommonOperations';
import { Link } from 'react-router-dom';
import { MessageBox } from '../../components/common/modalBox/MessageBox';
export class Weather extends Component {
    static displayName = Weather.name;
    constructor(props) {
        super(props);
        this.state = {
            tableData: '',
            showMessageBox: false, message: '', messageType: '1', temp:''
        }
        this.UserDetails = this.props.UserDetails;
    }
    async componentDidMount() {
        
        await this.getChartData();
       
    }

    getChartData = async () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.showPosition);
            } else {
                alert("Geolocation is not supported by this browser.");
            }
    }
    showPosition = async(position) => {
        let url = 'https://api.openweathermap.org/data/2.5/weather?lat=' + position.coords.latitude + '&lon=' + position.coords.longitude + '&units=imperial&appid=7a673eb1820c445d50ec50ef866d60ec'
        const response = await fetch(url);
        const myJson = await response.json();
        this.setState({ temp: myJson })
    }

    ShowMessageBox = (message, messageType) => this.setState({ showMessageBox: true, messageType: messageType, message: message });
    HideMessageBox = () => this.setState({ showMessageBox: false });


    render() {
        if (this.UserDetails.GoogleData == null )
            return (<></>)

        return (
            <>
                <div className="container employeeExtn">
                    <div className="row">
                        <div className="col l12 m12 s12">

                            <div className="card horizontal employeeProfileReview" >
                                <div className="card-image">

                                    {this.state.temp != '' ? <>
                                        <div className="imgBox bgChange">
                                            <img src={'http://openweathermap.org/img/wn/' + this.state.temp.weather[0].icon + '@2x.png'} />
                                        </div>
                                       <p>{this.state.temp.weather[0].description.toLocaleString()}</p>
                                    </> : ''}

                                    
                                </div>
                                <div className="card-stacked">
                                    <div className="card-content">
                                      

                                            <div className="row">
                                                <div className="col s12 l12">
                                                    <h5>Weather at your location</h5>
                                                </div>
                                            </div>
                                        <div className="row">
                                            <div className="col l6 s12 m6">
                                                
                                                    {this.state.temp != '' ? <>

                                                    <p style={{ fontWeight: "bold" }}>{this.state.temp.main.temp_min + ' / ' + this.state.temp.main.temp_max} &deg;F</p>
                                                    </> : ''}

                                              
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col l6 s12 m6">
                                                <div className="input-field">
                                                 
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <MessageBox show={this.state.showMessageBox} onHide={this.HideMessageBox} type={this.state.messageType} message={this.state.message} />
            </>
        );
    }
}



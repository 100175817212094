import React, { Component } from 'react';
import Footer from "../footer/footer";
import Navigation from "../navigation/navigation.component";
import { Spinner } from 'react-bootstrap';

export default class Home extends Component {
    static displayName = Home.name;
    constructor(props) {
        super(props);
        this.state = {
            UserDetails: this.props.UserDetails, 
        }

    }
    
    async  componentWillReceiveProps(nextProps) {
        await this.setState({ UserDetails: nextProps.UserDetails });
    }

    render() {
        return (
            <div className="wapper">
                <Navigation data={this.state.UserDetails} />
                    <div className="center-container">
                        {this.props.children}
                    </div>

                    <Footer />  
                    <div className="loaderOverlay" id="loader" >
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                </div>

        );
    }
}


import React, { Component } from "react";

import { Button, Modal } from 'react-bootstrap';

export class MessageBox extends Component {

    constructor(props) {
        super(props)
        this.Title = 'Success';
        this.className = 'modalSuccess';
        this.MessageType = props.type
        this.Message = props.message;
        if (this.MessageType == '2') {
            this.Title = 'Warning';
            this.className = 'modalWarning';
        }
        else if (this.MessageType == '3') {
            this.Title = 'Error'
            this.className = 'modalError';
        }
        else {
            this.Title = 'Success';
            this.className = 'modalSuccess';
        }

    }
    componentWillReceiveProps(nextProps) { this.RefreshData(nextProps); }
    RefreshData(nextProps) {

        this.Message = nextProps.message;
        this.MessageType = nextProps.type;
        if (this.MessageType == '2') {
            this.Title = 'Warning';
            this.className = 'modalMessage modalWarning';
        }
        else if (this.MessageType == '3') {
            this.Title = 'Error';
            this.className = 'modalMessage modalError';
        }
        else {
            this.Title = 'Success';
            this.className = 'modalMessage modalSuccess';
        }
    }
    isHTML(str) {
        var a = document.createElement('div');
        a.innerHTML = str;

        for (var c = a.childNodes, i = c.length; i--;) {
            if (c[i].nodeType == 1) return true;
        }

        return false;
    }

    render() {


        return (
            <div>
                <Modal
                    {...this.props} size="md"
                    backdrop="static"
                    keyboard={false}
                    animation={false}
                    enforceFocus={false}
                    centered className={this.className}>
                    <Modal.Header closeButton >
                        <Modal.Title id="contained-modal-title-vcenter">
                            {this.Title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.isHTML(this.Message) ? <p dangerouslySetInnerHTML={{ __html: this.Message }} />: <p>{this.Message}</p>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.props.onHide}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }


}
import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);
        this.state = {
            userResponseDetail: [],
        };
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }
    componentDidMount() {
    }
    toggleNavbar() { this.setState((state) => ({ collapsed: !state.collapsed  })); }

    render() {
        return (
            <header>
                <Navbar style={{ display: 'none' }}>
                    <NavItem >
                        <NavLink title="Scheduling" tag={Link} id="SessionTimeout" className="text-dark" to="/SessionTimeout"><div>
                            <span className="menuText">SessionTimeout </span></div></NavLink>
                    </NavItem>  
                </Navbar>
            </header>
        );
    }
}

import React,{Component} from "react";
import { Link } from "react-router-dom";
import logoPart from './../../assets/images/logo.png';
import { useLocation } from "react-router-dom";
import SidenavBar  from './sidenav.component';
import { ReactComponent as NotificationIcon } from './../../assets/images/bell.svg';
import { ReactComponent as TopArrow } from './../../assets/images/arrowRightDark.svg';
import { useState, useEffect } from 'react';
const Navigation = (props) => {
    const [userName, setUsername] = useState('');
  
    useEffect(() => {
            setUsername(props.data != null && props.data != '' && props.data != undefined ? props.data : '')
    }, []);
    console.log(userName);
   const menuCollapse = (props) =>{
    let rootDiv = document.querySelector('.wapper');
    rootDiv.classList.toggle('sidenavOpen');
   }

    return(
        <>
            <header className="main-header">
                <Link className="logo" to='/' ><img src={logoPart} /></Link>
                <h4>Business Event CMS Admin</h4>
               
                <div className="navbar-main">
                    {/*<Link to='/'><i><NotificationIcon /><span></span></i></Link>
                    <Link to='/'><i>A</i>Welcome, {}</Link>*/}

                </div>    
            </header>
          
        </>   
        
    )
}

export default Navigation;
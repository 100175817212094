import React, { Component } from 'react';
import { HttpRequest, HttpRequestWithouLoader, GetStartAndEndDate, CheckSupportIDException, HTMLTableExport, ShowLoader, ActiveMenu, validateEmail } from '../common/CommonOperations';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import dateFormat from 'dateformat';
import { Select } from 'react-materialize';
import { MessageBox } from '../../components/common/modalBox/MessageBox';

export class BusinessCardRequest extends Component {
    static displayName = BusinessCardRequest.name;
    constructor(props) {
        super(props);
        this.state = { otherSelect: false, showMessageBox: false, message: '', messageType: '1', director: '' };
        this.UserDetails = this.props.UserDetails;
    }
    componentDidMount() {
        //   debugger;
        document.title = 'Business Card Request';
        this.GetDirectorEmail(this.UserDetails.NTLogin);
    }
    ShowMessageBox = (message, messageType) => this.setState({ showMessageBox: true, messageType: messageType, message: message });
    HideMessageBox = () => this.setState({ showMessageBox: false });

    selectChange = (e) => {
        if (e.target.value == 'Other') {
            this.setState({ otherSelect: true })
            document.getElementById('otherReason').value = '';
        }
        else {
            this.setState({ otherSelect: false })
        }
    }
    submitPayroll = async (e) => {

        e.preventDefault();

        debugger;
        let reg = new RegExp('^[0-9]*$');
        let fristName = document.getElementById('firstName');
        let lastName = document.getElementById('lastName');
        let title = document.getElementById('title');
        let address = document.getElementById('address');
        let cityName = document.getElementById('city');
        let stateName = document.getElementById('state');
        let zipCode = document.getElementById('zip');
        let officePhone = document.getElementById('officePhone');
        let mobileNumber = document.getElementById('mobilePhone');
        let numBussinesCard = document.getElementById('numberOfBussinessCard');
        let faxNumber = document.getElementById('Fax');
        let emailAddress = document.getElementById('emailAddress');
        let poBox = document.getElementById('poBox');

        let specialInstructions = document.getElementById('specialInstructions');
        if (fristName.value.length == 0) {
            this.ShowMessageBox('Frist Name can not be blank', 3);
            fristName.focus();
            return false;
        } else if (lastName.value.length == 0) {
            this.ShowMessageBox('Last Name can not be blank', 3);
            lastName.focus();
            return false;
        } else if (title.value.length == 0) {
            this.ShowMessageBox('Title can not be blank', 3);
            title.focus();
            return false;
        } else if (address.value.length == 0) {
            this.ShowMessageBox('Address can not be blank', 3);
            address.focus();
            return false;
        } else if (cityName.value.length == 0) {
            this.ShowMessageBox('City can not be blank', 3);
            cityName.focus();
            return false;
        } else if (stateName.value.length == 0) {
            this.ShowMessageBox('State can not be blank', 3);
            stateName.focus();
            return false;
        } else if (zipCode.value.length == 0) {
            this.ShowMessageBox('Zip can not be blank', 3);
            zipCode.focus();
            return false;
        } else if (reg.test(zipCode.value) == false) {
            this.ShowMessageBox('Please enter numerical value in zip code', 3);
            zipCode.focus();
            zipCode.value = '';
            return false;
        } else if (zipCode.value.length > 5) {
            this.ShowMessageBox('Zip code length can not be greater then 5', 3);
            zipCode.focus();
            zipCode.value = '';
            return false;
        } else if (officePhone.value.length == 0) {
            this.ShowMessageBox('Office phone can not be blank', 3);
            officePhone.focus();
            return false;
        } else if (reg.test(officePhone.value) == false) {
            this.ShowMessageBox('Please enter numerical value in office phone', 3);
            officePhone.focus();
            officePhone.value = '';
            return false;
        }
        else if (emailAddress.value.length == 0) {
            this.ShowMessageBox('Email Address can not be blank', 3);
            emailAddress.focus();
            return false;
        } else if (!validateEmail(emailAddress.value)) {
            this.ShowMessageBox('Please enter a valid email address', 3);
            emailAddress.value = '';
            emailAddress.focus();
            return false;
        } else if (numBussinesCard.value.length == 0) {
            this.ShowMessageBox('Please select number of card', 3);
            numBussinesCard.value = '';
            numBussinesCard.focus();
            return false;
        }

        let supervisorName = this.UserDetails.GoogleData.relations[0].value;
        //  let managerEmailAddress = value[1] + '.' + value[0].replace('CN=', '').replace('\\', '') + '@dish.com';
        //  let supervisorName = value[1] + '.' + value[0].replace('CN=', '').replace('\\', '');
        let GetRadioName = document.querySelectorAll('.radioGroup input');
        let radioVal = ''

        GetRadioName.forEach((item) => {
            if (item.checked) {
                radioVal = item.value;
            }
        });




        let param = {
            RequesterEmployeeNumber: this.UserDetails.GoogleData.externalIds[0].value,
            RequesterFullName: this.UserDetails.GoogleData.name.fullName,
            RequesterWorkPhone: (this.UserDetails.GoogleData.phones == null) ? "" : this.UserDetails.GoogleData.phones[0].value,
            RequesterJobName: this.UserDetails.GoogleData.organizations[0].title,
            RequesterSupervisorName: supervisorName,
            RequesterApproverEmailAddress: this.state.director,
            FirstName: fristName.value,
            LastName: lastName.value,
            JobName: title.value,
            Address: address.value,
            City: cityName.value,
            State: stateName.value,
            Zipcode: zipCode.value,
            WorkPhone: officePhone.value,
            MobilePhone: mobileNumber.value == '' ? null : mobileNumber.value,
            Fax: faxNumber.value == '' ? null : faxNumber.value,
            EmailAddress: emailAddress.value,
            POBox: poBox.value == '' ? null : poBox.value,
            HrBusinessCard: radioVal == '' ? null : radioVal,
            SpecialInstructions: specialInstructions.value == '' ? null : specialInstructions.value,
            CardCount: numBussinesCard.value,
        }
        console.log('param');
        console.log(param);
        debugger;
        var response = await HttpRequest('BusinessCardEmployeeProfile/SendEmailBusinessCardRequestInfo', 'POST', param);
        const responseData = await response.json();
        //debugger;
        if (responseData.IsSuccessful) {
            this.ShowMessageBox("Your request has been received.<br /> Allow up to 2 business days for a Payroll WorkCenter administrator to contact you.<br /> Please note that as of September 10th, 2013, the Payroll WorkCenter website has been updated.<br/>Employees will need to set up a new account through the First Time User option (Registration Code: Dish-123456).<br /> Please consult the new Payroll WorkCenter registration instructions on the Pay and Timekeeping page of The Hop for further instructions.<br/><a ext-Link href='/'>Return to the homepage</a>. ", 1); return false;
        }
        else if (responseData.errorMessage != undefined) {
            this.ShowMessageBox(responseData.errorMessage, 3);
        }
        else {
            this.ShowMessageBox('Error occured, please try again later', 3);
            return false;
        }
    }

    GetDirectorEmail = async (ntLogin) => {

        console.log('confirmation for latest code')
        if (ntLogin == undefined) return false;
        //  debugger;
        var responseGoogle = await HttpRequest('SymptomTracker/GetUserDetailFromGoogleApi/' + ntLogin.toLowerCase(), 'GET');
        //debugger;
        var dataGoogle = await responseGoogle.json();
        if (dataGoogle.success == true) {
            debugger;
            let apiData = JSON.parse(dataGoogle.data);
            try {
                var managerNLogin = apiData.customSchemas.Manager_UPN.Manager_UPN.split('@')[0].toString();
                var designation = apiData.customSchemas.HOP_Title.HOP_Title;

                designation.toLowerCase().indexOf('director') == -1 ? this.GetDirectorEmail(managerNLogin) : this.setState({ director: apiData.primaryEmail });
            } catch (ex) {
                console.log(ex);
            }
        }
        // this.setState({ director: 'deepthi.putti@dish.com' });

    }

    formElements = () => {
        // debugger;
        if (this.UserDetails.GoogleData == null || this.UserDetails.GoogleData == undefined) { return '' }
        // debugger;
        return (
            <>
                <form onSubmit={this.submitPayroll}>
                    <div className="input-field">
                        <input id="firstName" type="text" defaultValue={this.UserDetails.GoogleData.name.givenName} className="validate" />
                        <label htmlFor="firstName" className="active">* First Name</label>
                    </div>
                    <div className="input-field">
                        <input id="lastName" type="text" defaultValue={this.UserDetails.GoogleData.name.familyName} className="validate" />
                        <label htmlFor="lastName" className="active" >* Last Name</label>
                    </div>
                    <div className="input-field">
                        <input id="title" type="text" defaultValue={this.UserDetails.GoogleData.organizations[0].title} className="validate" />
                        <label htmlFor="title" className="active">* Title</label>
                    </div>


                    <div className="input-field">
                        <input id="address" type="text" className="validate" />
                        <label htmlFor="address" >* Address</label>
                    </div>
                    <div className="input-field">
                        <input id="city" type="text" className="validate" />
                        <label htmlFor="city" >* City</label>
                    </div>

                    <div className="input-field">
                        <input id="state" type="text" className="validate" />
                        <label htmlFor="state" >* State</label>
                    </div>

                    <div className="input-field">
                        <input id="zip" type="text" className="validate" />
                        <label htmlFor="zip" >* Zip</label>
                    </div>

                    <div className="input-field">
                        <input id="officePhone" type="text" className="validate" />
                        <label htmlFor="officePhone" >* Office Phone</label>
                    </div>
                    <div className="input-field">
                        <input id="mobilePhone" type="text" defaultValue="NA" className="validate" />
                        <label htmlFor="mobilePhone" className="active">Mobile Phone</label>
                    </div>
                    <div className="input-field">
                        <input id="Fax" type="text" className="validate" />
                        <label htmlFor="Fax" >Fax</label>
                    </div>
                    <div className="input-field">
                        <input id="emailAddress" type="text" defaultValue={this.UserDetails.GoogleData.primaryEmail} className="validate" />
                        <label htmlFor="emailAddress" className="active">Email Address</label>
                    </div>
                    <div className="input-field">
                        <input id="poBox" type="text" className="validate" />
                        <label htmlFor="poBox" >P.O. Box</label>
                    </div>

                    <Select
                        id="numberOfBussinessCard"
                        label="* Number of Cards:If ordering more then 260 cards, additional approval may be required."
                        multiple={false}
                        onChange={this.selectChange}
                        options={{
                            classes: '',
                            dropdownOptions: {
                                alignment: 'left',
                                autoTrigger: true,
                                closeOnClick: true,
                                constrainWidth: true,
                                coverTrigger: true,
                                hover: false,
                                inDuration: 150,
                                onCloseEnd: null,
                                onCloseStart: null,
                                onOpenEnd: null,
                                onOpenStart: null,
                                outDuration: 250
                            }

                        }}
                        value="" >
                        <option disabled value=""></option>
                        <option value="260">260</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>

                    </Select>

                    <div className="input-field">
                        <textarea id="specialInstructions" type="text" className="materialize-textarea"></textarea>
                        <label htmlFor="specialInstructions">Special Instructions: Use for any additional comments or special instructions for the copy center.</label>
                    </div>
                    <div className="input-field radioGroup">
                        <label>HR Business Card</label>
                        <label>
                            <input name="hrCard" className="with-gap" type="radio" value="HR Business Card" />
                            <span>HR Business Card</span>
                        </label>
                        <label>
                            <input name="hrCard" className="with-gap" type="radio" value="HR Benefits & Leaves Business Card" />
                            <span>HR Benefits & Leaves Business Card</span>
                        </label>


                    </div>
                    <div className="input-field">
                        <button className="btn btn-primary waves-effect waves-light" type="submit" name="action">Submit </button>

                    </div>
                </form>
            </>)

    }
    RequesterInformation = () => {
        if (this.UserDetails.GoogleData == null || this.UserDetails.GoogleData == undefined) { return '' }
        // let value = this.UserDetails.GoogleData.relations[0].value.split(',');
        // let managerEmailAddress = value[1] + '.' + value[0].replace('CN=', '').replace('\\', '') + '@dish.com';
        return (
            <>
                <li><span>Employee Name: </span>{this.UserDetails.GoogleData.name.fullName}</li>
                <li><span>Employee Title: </span>{this.UserDetails.GoogleData.organizations[0].title}</li>
                <li><span>Extension: </span>{

                    (this.UserDetails.GoogleData.phones == null) ? "" : this.UserDetails.GoogleData.phones[0].value
                }</li>
                <li><span>HR Employee Number: </span>{this.UserDetails.GoogleData.externalIds[0].value}</li>
                <li><span>Approver Email: </span>{this.state.director}</li>
            </>
        )
    }
    render() {
        if (this.UserDetails.GoogleData == null)
            return <></>
        return (
            <>
                <div className="container payrollPasswordReset">
                    <div className="row">

                        <div className="col s12 m12 l12">
                            <p></p>
                            <div className="row">
                                <div className="col s12 m12 l12">
                                    <div className="card horizontal">
                                        <div className="card-image">
                                            <img src="images/hoplogo.png" className="hoplogo-image" />
                                        </div>
                                        <div className="card-stacked">
                                            <div className="card-content">
                                                <div className="row">
                                                    <div className="col s12">
                                                        <h5>Business Card Request</h5>
                                                        <hr />

                                                        <div className="card cardbusiness">
                                                            <div className="card-content">
                                                                <span className="card-title">Requester Information</span>
                                                                <ul>
                                                                    {this.RequesterInformation()}
                                                                </ul>
                                                            </div>

                                                        </div>
                                                        <p></p>

                                                        <p></p>
                                                    </div>
                                                </div>

                                                <div className="row">

                                                    <div className="col l6 m6 s12">
                                                        {this.formElements()}
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
                <MessageBox show={this.state.showMessageBox} onHide={this.HideMessageBox} type={this.state.messageType} message={this.state.message} />
            </>
        );
    }
}

import React, { PureComponent } from 'react';

import { HttpRequest, ShowLoader } from '../common/CommonOperations';


export class BracketBreaks extends PureComponent {
    static displayName = BracketBreaks.name;

    constructor(props) {
        super(props);
        this.state = {
            otherSelect: false,
            showMessageBox: false, message: '', messageType: '1', EmployeeProfileList: null, EmployeeProfileQuestionList: [], newQuestionList: [],
            checkedValueArray: [], managerName: '', mobilePhoneNumber: '', mobileCodeNumber: '+1', apimobilePhoneNumber: '', apimobileCodeNumber: '+1', showModalProfile: false, CropImageBlob: null,

            src: null, DisplayMessage: false,
            messageDisplayResponse: '', data: null,
        };
        this.UserDetails = this.props.UserDetails;
    }

    async  componentDidMount() {
        let gender = '';
        if (this.props.history.location.pathname.indexOf('mens') > 0) {
            gender = 'mens'
        }
        else if (this.props.history.location.pathname.indexOf('womens') > 0) {
            gender = 'womens'
        }
        else if (this.props.history.location.pathname.indexOf('other') > 0) {
            gender = 'other'
        }

        await this.getData(gender);
    }

    // RedirectPage = async()=> {
    //     document.getElementById('SubmitForm').click();
    //}

    getData = async (gender) => {
        if (this.UserDetails.NTLogin) {
            console.log('this.props.UserDetails.NTLogin');
            console.log(this.props.UserDetails.NTLogin);
            console.log(this.props.UserDetails);
            var response = await HttpRequest(`SymptomTracker/BracketBreaks/${gender}/${this.props.UserDetails.NTLogin}/${this.props.UserDetails.GoogleData.organizations[0].department}`, 'get');
            const dataT = await response.json();
            this.setState({ data: dataT })

            setTimeout(() => document.getElementById('SubmitForm').click(), 1000);
        }

    }

    loaderTrue = () => {

        ShowLoader(true)
    }

    render() {
        return (
            <>
                {ShowLoader(true)}
            <div
                dangerouslySetInnerHTML={{ __html: this.state.data }}
                />
              
           </>
        )
    }
}





import React, { Component } from 'react';
import { HttpRequest } from '../common/CommonOperations';
import { connect } from "react-redux";
import { SetTreeSelectedEmployee, } from '../../Store/Action'
class Review extends Component { 
    static displayName = Review.name;
    constructor(props) {
        super(props);
        this.state = {selectedItemNumber: 0, categoryList: [], categoryDetail: [], categoryNumber: '', categoryIndexNumber: 0, selectedcategoryId: '',
            urlResult: '', indexNumber: null, showComponent: false, indexSelectPicks: 0, userResponseDetail: [], categoryResponseDetail:[],count:0};
        this.UserDetails = this.props.UserDetails;
    }
    componentDidMount() {
        if (this.props.UserDetails.AcademyAwardsEndDate == null) return
        else {
            let awardsLastDate = this.props.UserDetails.AcademyAwardsEndDate;
            var todaydate = (new Date()).valueOf();
            var awardsEndDate = (new Date(awardsLastDate)).valueOf();
            if (awardsEndDate < todaydate) {
                let path = "/AcademyAwards/Leaderboard";
                this.props.history.push(path)
            }

            else {
                let splitId = null;
                this.GetAcademyResultsForUserByPersonID(splitId);
                document.title = 'Academy Awards Review';
            }
        }

    }
    valueToSelectPick = async(index) => {
        this.setState({
            indexSelectPicks : index
        })
        await this.props.SetTreeSelectedEmployee(index);
        var path = "/AcademyAwards/SelectPicks";
        this.props.history.push(path)
    }
    reviewTiebreakers = async () => {
        let indexofTieBreaker = this.state.categoryResponseDetail.filter((item, index) => {
            if (item.IsTieBreakerQuestion == true) {
                this.valueToSelectPick(index);
            }
        }) 
    }
    GetAcademyResultsForUserByPersonID = async (PersonID) => {      
        var response = null;
        if (PersonID != null)
            response = await HttpRequest('AcademyAwards/GetAcademyResultsForUserByPersonID/' + PersonID, 'get');
        else
            response = await HttpRequest('AcademyAwards/GetAcademyResults/', 'get');
        const res = await response.json();
        this.setState({
            categoryList: res, userResponseDetail: res.AcademyNominationUserResponses, categoryResponseDetail: res.AcademyNominationCategories
        })
    }
    renderCategory = () => {
        if (this.state.categoryList == null ||
            this.state.categoryList.length == 0) {
            return;
        }

        else {
            var categorylisting = this.state.categoryList.AcademyNominationCategories;
            if (categorylisting == undefined) { return ''; }
            else {
                return (
                    categorylisting.map((item, index) =>
                        <>
                            {item.IsTieBreakerQuestion != true ?
                                <li className="col s12 m4  reviewBlock desktopBlock">
                                    <div className="reviewBlockDetails blockDetailstyle card">

                                        <span>
                                            <p className="resultBlockTextHeader">
                                                <span className="descres ng-binding" id={item.ID}>{item.Description}</span>
                                                <span className="sub-title ng-binding">{item.PointValue} <span className="pts">pts</span></span>
                                            </p>
                                            <div className="line"></div>

                                            <div className="reviewBlockText revBlockText ng-binding ng-scope"> {this.renderSelectedNominee(item, index)}</div>
                                            <span className="nomineesTrailerText edittext">
                                                <button type="button" className="btn btn-primary" style={{ margin: 'auto', padding: '0 20px' }}
                                                    onClick={() => this.valueToSelectPick(index)}>Edit</button>
                                            </span>
                                        </span>


                                    </div>

                                </li>
                                :
                                <></>}
                        </>
                    )
                )
            }
        }

    }
    renderSelectedNominee = (item, index) => {
        let result = item.AcademyNominationCategoryDetails.filter(itemRes =>
            (this.state.categoryList.AcademyNominationUserResponses != null) ?
                this.state.categoryList.AcademyNominationUserResponses.some((field) =>
                    field.AcademyNominationCategoryID ==
                    itemRes.AcademyNominationCategoryID &&
                    field.AcademyNomineeID == itemRes.AcademyNominee.ID
                ) : null
        );
        if (result == null || result.length == 0) {
            return;
        }
        else {
            return (
                <>
                    <span key={result[0].ID} id={result[0].ID}>
                        {result[0].AcademyNominee.NomineeName}</span>
                </>
            )
        }
    }

    render() {
        if (this.UserDetails.GoogleData == null)
            return <></>
            return (
                <>
                    <div className="container">
                        <div className="selectPicksPage">
                            <div className="scoreBar row">
                                <div className="progressBarContianer" style={{ paddingTop: '15px' }}>
                                    <div className="col s12 m12 progress progressbrand" style={{ padding: '0px' }}>
                                        <div className="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="25" style={{ width: (24 / 24) * 100 + '%' }}></div>
                                    </div>
                                    <div className="itemDisplay" >
                                        <img id="image" src="/images/AcademyAwards/leaf.png" alt="" />
                                        <div className="scoreboards">
                                            <center>
                                                <span id="upText1" >{this.state.userResponseDetail != null ? this.state.userResponseDetail.length : 0}</span>
                                                <span id="upTextimg1"><img src="/images/AcademyAwards/lineslice.png" alt="" /></span>
                                                <span id="downText1">{this.state.categoryResponseDetail.length}</span>
                                            </center>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col s12 m12 categoryHeader">
                                <span className="categorytitle">Review</span>

                            </div>
                            <div className="userGridDisplay">
                                <ul className="row">{this.renderCategory()} </ul>
                            </div>
                            <div className="col-lg-12 nomineesReviewText text-center">
                                <button onClick={() => this.reviewTiebreakers()} className="btn btn-primary">REVIEW TIEBREAKERS</button>
                            </div>
                        </div>
                    </div>
                </>
            );
    }
}
const mapDispatchToProps = dispatch => ({
    SetTreeSelectedEmployee: (value) => { dispatch(SetTreeSelectedEmployee(value)) },
})
export default connect(null, mapDispatchToProps)(Review)
import React, { Component } from 'react';

export class ThankYouMessage extends Component {
    static displayName = ThankYouMessage.name;
    componentDidMount() {
        document.title = 'Academy Awards';
    }
    render() {
        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-7 col-md-offset-3 col-sm-7 col-sm-offset-3 col-lg-7 col-lg-offset-3">
                            <h2>
                                Thank you for participating in The Academy Awards, we hope you come back next year!
            </h2>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

import React, { PureComponent } from 'react';
import { HttpRequest, ShowLoader } from '../common/CommonOperations';
import { ToastHeader } from 'react-bootstrap';
import { Button, Modal } from 'react-bootstrap';
import { ConformationBox } from '../common/modalBox/ConformationBox';
import { MessageBox } from '../../components/common/modalBox/MessageBox';   
export class FirstAidClasses extends PureComponent {
    static displayName = FirstAidClasses.name;
    constructor(props) {
        super(props);
        this.state = {
            urlType: '',
            getApiData: {},
            meridianfirstaid: {},
            formType: '',
            registerationTitle: '',
            showConfirmBox: false,
            message:'',
            rigisterModal: false,
            revokeId: '',
            sessionId:''
        };
        this.UserDetails = this.props.UserDetails;
    }

    ShowConfirmBox = (message) => { this.setState({ showConfirmBox: true, message: message }); }
    HideConfirmBox = () => { this.setState({ showConfirmBox: false }); }


    ShowMessageBox = (message, messageType) => this.setState({ showMessageBox: true, messageType: messageType, message: message });
    HideMessageBox = () => this.setState({ showMessageBox: false });





    async  componentDidMount() {
       
        //await this.getData(urlPath);
       
        ///await this.GetDataMeridian();

        let urlVal = this.props.history.location.pathname.split('/')
        urlVal = urlVal.filter(item => item != '');
        console.log(urlVal);
        await this.GetApiDataFn(urlVal[1]+'|');
        document.body.classList.add('businessEvent');
    }

    GetApiDataFn = async (strUrl) => {
        let response = await HttpRequest('Content/GetBusinessSessionDetailsById/' +strUrl + this.props.UserDetails.PersonID, 'Get');
        const res = await response.json();
        await this.setState({ getApiData: res.Data })
        console.log(res.Data);
        
    }

    removeFn = (str) => {
      
        debugger;
         let strArr = str.split(' ');
        // strArr[strArr.length - 1] = 'PP';

         let fnStr = strArr.filter((item, pos) => {
           return strArr.indexOf(item) == pos;
        })

        let finalStr = '';
         fnStr.forEach((item) => {

            finalStr += item + ' ';
         })
        //return finalStr.replaceAll(':00', '').replaceAll('PP', 'PM');
         return finalStr.replaceAll(':00', '');
    }


    getTable = (tableData, type) => {
       
            let EventSessionsGroupDetails = tableData;
            return EventSessionsGroupDetails.map((item) => {
                let { EventSessions } = item;
                console.log(EventSessions.IsAlreadyRegistered);
                return (
                    <tr key={EventSessions.EventTopic}>
                        {EventSessions.EventTopic != '' && EventSessions.EventTopic != null ? <td>{EventSessions.EventTopic}</td> :<td></td> } 
                        {EventSessions.EventDescription != '' && EventSessions.EventDescription != null ? <td>{EventSessions.EventDescription}</td> : <td></td>}
                        {EventSessions.EventPresenter != '' && EventSessions.EventPresenter != null ? <td>{EventSessions.EventPresenter}</td> : <td></td>}
                        <td><span className="text-small">{new Date(EventSessions.EventSessionStartDate).toLocaleDateString() + ' ' + new Date(EventSessions.EventSessionStartDate).toLocaleTimeString([], { timeStyle: 'short' }) + ' - ' + new Date(EventSessions.EventSessionEndDate).toLocaleTimeString([], { timeStyle: 'short' })}</span></td>

                        


                        {EventSessions.EventLocation != '' && EventSessions.EventLocation != null ? <td>{EventSessions.EventLocation}</td> : <td></td>}
                        <td>
                            <div style={{ display: EventSessions.IsSessionFull ? 'block' : 'none' }}>SESSION FULL</div>
                            <div style={{ display: !EventSessions.IsSessionFull && !EventSessions.IsAlreadyRegistered && !EventSessions.IsWaitListRegistered ? 'block' : 'none' }}><button onClick={() => this.openModalRegister(EventSessions, 'registerNow')}>REGISTER NOW</button></div>
                            <div style={{ display: EventSessions.IsAlreadyRegistered || EventSessions.IsWaitListRegistered ? 'block' : 'none' }}><button onClick={() => { this.setState({ revokeId: EventSessions.ID }); this.ShowConfirmBox('Are you sure you want to unregister yourself?') }} href="#">UNREGISTER</button></div>
                        </td>
                    </tr>

                )
            });
        
                                            
    }

    getData = () => {
        
        
        let data = this.state.getApiData != null ? this.state.getApiData[0] : undefined;
        if (data == undefined || data == null || data == '') {
            return <h5>Event not found </h5>;
        }
           else if (data != null && data.length != 0) {
                const { $id, EventHeader, EventDescription, EventDetails, EventEmail, EventSessionsGroup, ImageUrl } = data;
                const { EventSessionsGroupDetails } = EventSessionsGroup;

                return(
    
                    <>
                        {ImageUrl != '' && ImageUrl != null ?
                        <div className="row">
                            <div className="col s12 m12 l12">
                                <center>                                       
                                        <img src={'data:image/JPEG;base64,' + ImageUrl} alt='' width="100%" height="250px" />
                                </center>

                            </div>
                        </div>:''}
                        <div className="row">
                            <div className="col s12 m12 l12">
                    <h5>{EventHeader}</h5>
                    <div className="event-disc" dangerouslySetInnerHTML={{ __html: EventDescription.replaceAll('<p>&nbsp;</p>','') }} />
                            </div>
                        </div>
                        {EventSessionsGroupDetails.length!= 0 ? <div className="row">
                            <div className="col s12 m12 l12">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Topic</th>
                                            <th>Description</th>
                                            <th>Presenter</th>
                                            <th>Date/Time</th>
                                            <th>Location</th>
                                            <th></th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.getTable(EventSessionsGroupDetails)}
                                    </tbody>
                                </table>
                            </div>
                        </div>: ''}
                        

                        <div className="row">
                            <div className="col s12 m12 l12">
                                <div className="event-disc" dangerouslySetInnerHTML={{ __html: EventDetails }} />
                            </div>
                        </div>
                    </>
                )
            } 
            
        
   

    }
  
    openModalRegister = (value, type) => {
        
        console.log(value.id);
        this.setState({rigisterModal: true, formType: type, sessionId: value.ID })


    
    }

   // rigisterModal
   // showRegisterModal

    //getApiData
    //meridianfirstaid

    modalDataFill = () => {
        if (this.state.getApiData) {
            let formVal = this.state.formType;
            if (this.UserDetails.GoogleData != null && this.UserDetails.GoogleData != undefined) {
               // debugger;
                    return (
                        <>
                            <div>Register</div>
                            <div className="input-field">
                                <input id="emplolyeeId" type="text" disabled defaultValue={this.UserDetails.GoogleData.externalIds[0].value} className="validate" />
                                <label htmlFor="emplolyeeId" className="active">Employee ID:</label>
                            </div>
                            <div className="input-field">
                                <input id="firstName" type="text" disabled defaultValue={this.UserDetails.GoogleData.name.givenName} className="validate" />
                                <label htmlFor="firstName" className="active">First Name:</label>
                            </div>
                            <div className="input-field">
                                <input id="lastName" type="text" disabled defaultValue={this.UserDetails.GoogleData.name.familyName} className="validate" />
                                <label htmlFor="lastName" className="active">Last Name:</label>
                            </div>
                            <div className="input-field">
                                <input id="email" type="text" disabled defaultValue={this.UserDetails.GoogleData.primaryEmail} className="validate" />
                                <label htmlFor="email" className="active">Email:</label>
                            </div>

                            <div className="input-field">
                                <input id="department" type="text" disabled defaultValue={this.UserDetails.GoogleData.organizations[0].department} className="validate" />
                                <label htmlFor="department" className="active">Department:</label>
                            </div>
                        </>
                    )
                }
            
        }
    }

    submitFormRegister = async(e) => {
        if (this.state.getApiData) {
            console.log(this.state.getApiData);
         
            let param = {
                SessionID: this.state.sessionId,
                PersonID: parseInt(this.props.UserDetails.PersonID),
                EventEmailAddress: this.state.getApiData[0].EventEmail,
                IsApprovalProcess: this.state.getApiData[0].IsApprovalProcess,
                EmployeeEmailAddress: document.querySelector('#email').value,
                AdditionalInfo: '', 

            }
            var response = await HttpRequest('Content/SaveEmployeeEventDetailsSessionRegister', 'POST', param);
            const responseData = await response.json();
            this.setState({ rigisterModal: !this.state.rigisterModal })
            if (responseData.IsSuccessful) {
                //this.ShowMessageBox("Please enter Answer 1.", 1);
                this.ShowMessageBox(responseData.FriendlyMessage, 1);
                setTimeout(() => { window.location.reload() }, 2000);

            }
            else {
                this.ShowMessageBox(responseData.FriendlyMessage, 3);
                setTimeout(() => { window.location.reload() }, 2000)
                e.stopPropagation();
                return false;
            }
        }

       
       
    }


    showRegisterModal = () => {
        this.setState({ rigisterModal: !this.state.rigisterModal })
    }


    revokeRegisteration = async () => {
        
        let param = {
            SessionID: parseInt(this.state.revokeId),
            PersonID: parseInt(this.props.UserDetails.PersonID),
            EventEmailAddress: this.state.getApiData[0].EventEmail,
            IsApprovalProcess: this.state.getApiData[0].IsApprovalProcess,
            eventApprovalProcess: this.state.getApiData[0].IsApprovalProcess
           

        }
        //var employeeInfo = { SessionID: eventID, PersonID: $scope.userInfo.PersonID, IsApprovalProcess: $scope.eventApprovalProcess };

        var response = await HttpRequest('Content/RevokeEventSession', 'POST', param);
        const responseData = await response.json();
        if (responseData.IsSuccessful) {

            this.ShowMessageBox(responseData.FriendlyMessage, 1);
            setTimeout(() => { window.location.reload() },2000)
        }
        else {
            this.ShowMessageBox(responseData.FriendlyMessage, 3);
            setTimeout(() => { window.location.reload() }, 2000)

        }

    }
    onConfirmEvent = async (id) => {
      
        this.setState({ showConfirmBox: !this.state.showConfirmBox });
       await this.revokeRegisteration();
      
}

    render() {
        
        return (
            <>
            <div className="container">
                <div className="row">
                    <div className="col s12 m12 l12">

                        <div className="card horizontal">
                        
                            <div className="card-stacked">
                                <div className="card-content firstAidClassMain">
                                 
                                    {this.getData()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

                <ConformationBox className="conformBusinessEvent" show={this.state.showConfirmBox} onHide={this.HideConfirmBox} onConfirm={this.onConfirmEvent} message={this.state.message} />
              
                <Modal className="datePickerModal modalBusinessEvent" show={this.state.rigisterModal} centered onHide={this.showRegisterModal}>
                <Modal.Header closeButton>
                        <Modal.Title>Registration</Modal.Title>
                    </Modal.Header>
                    
                <Modal.Body>
                        {this.modalDataFill()}
                </Modal.Body>
                <Modal.Footer className="spEqual">
                        <button className="btn btn-secondary" onClick={this.showRegisterModal}>Cancel</button>
                        <button className="btn btn-primary" onClick={this.submitFormRegister} >Submit</button>

                </Modal.Footer>
                </Modal>
                <MessageBox show={this.state.showMessageBox} onHide={this.HideMessageBox} type={this.state.messageType} message={this.state.message} />

                </>
        )
    }
}





import { browserHistory } from 'react-router-dom';
import { useEffect } from "react";

export const ShowLoader = (value) => {
    if (document.getElementById("loader") == null) { return }
    (document.getElementById("loader") && value ? document.getElementById("loader").style.display = 'block' : document.getElementById("loader").style.display = 'none')
}


export const ErrorLog = (errorObj, screenName, operationName) => {
    ShowLoader(false);
   
}

export const replaceAll = (string, search, replace) => {
    return string.split(search).join(replace);
}


export const DateFormate = (val) => {
    // debugger;
    if (val == undefined || val.length == 0) { return ''; }
    let date = new Date(val);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (day < 10) {
        day = '0' + day;
    }

    if (month < 10) {
        month = '0' + month;


    }
    return month + '/' + day + '/' + year;

}

export const IsNumberWithComma = (evt) => {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode == 44) { return true }//allow comma value
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}
export const HttpRequest = async (URL, RequestType, Parameters = null) => {
    try {
        ShowLoader(true);
        await CheckSession();
        var response = null
        if (RequestType.toLowerCase() == 'get') {
            response = await fetch(URL);
        }
        else {
            var requestOptions = {
                method: RequestType.toUpperCase(),
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(Parameters)
            };
            response = await fetch(URL, requestOptions);
        }

        ShowLoader(false);
        return response;
    } catch (e) {

        ShowLoader(false);
        if (e == "TypeError: Failed to fetch") {
            setTimeout(() => {
              //  document.getElementById('SessionTimeout').click();
            }, 1000)
        }

        ErrorLog(e, 'common', 'HttpRequest');
    }
}
export const HttpRequestWithouLoader = async (URL, RequestType, Parameters = null) => {
    try {
        await CheckSession();
        var response = null
        if (RequestType.toLowerCase() == 'get') {
            response = await fetch(URL);
        }
        else {
            var requestOptions = {
                method: RequestType.toUpperCase(),
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(Parameters)
            };
            response = await fetch(URL, requestOptions);
        }

        ShowLoader(false);
        return response;
    } catch (e) {

        ShowLoader(false);
        ErrorLog(e, 'common', 'HttpRequest');
    }
}

export const CheckSupportIDException = async (response) => {
    var data = await response.text();
    if (data.indexOf("Your support ID is") > -1) {
        document.getElementById('CheckSupportIDValue').textContent = data;
        document.getElementById('CheckSupportIDModal').style.display = 'block';
        return []
    }
    else {
        if (data.length == 0)
            return []
        else {
            return JSON.parse(data)

        }
    }
}
const CheckSession = async () => {
    var response = await fetch('Auth/CheckSession');
    var data = await response.json();
    if (window.location.host.indexOf('localhost') == -1) {
        if (data.key == 'True') {            
            if (sessionStorage.getItem("redirectURL") == null && window.location.href.indexOf('Session') == -1) {
                sessionStorage.setItem('redirectURL', window.location.href);
            }
            document.getElementById('SessionTimeout').click();
        }
    }

}
export const SaveFileFromServer = (base64, fileName) => {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    var blob = new Blob([bytes], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName + "_" + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString();
    link.click();

}
export const GetStartAndEndDate = (type) => {
    var finalStartDate = null;
    var finalEndDate = null;
    if (type.toLowerCase() == 'thisweek') {
        var current = new Date();            // get current date    
        var currentEnd = new Date();
        var weekstart = current.getDate() - (current.getDay() + 1);
        var currentweekend = current.getDate();       // end day is the first day + 6 
        startdate = new Date(current.setDate(weekstart));
        enddate = new Date(currentEnd.setDate(currentweekend));
        var a = new Date();
        finalStartDate = "" + (startdate.getMonth() + 1) + "/" + startdate.getDate() + "/" + startdate.getFullYear();
        finalEndDate = "" + (a.getMonth() + 1) + "/" + a.getDate() + "/" + a.getFullYear();
        return { StartDate: finalStartDate, EndDate: finalEndDate }
    }
    else if (type.toLowerCase() == 'lastweek') {

        var dateNow = new Date();
        var dateNow2 = new Date();
        var firstDayOfTheWeek = (dateNow.getDate() - ((dateNow.getDay()) + 1));
        var lastDayOfTheWeek = firstDayOfTheWeek + 6;
        var startdate = new Date(dateNow.setDate(firstDayOfTheWeek - 7));
        var enddate = new Date(dateNow2.setDate(lastDayOfTheWeek - 7));
        finalStartDate = "" + (startdate.getMonth() + 1) + "/" + startdate.getDate() + "/" + startdate.getFullYear();
        finalEndDate = "" + (enddate.getMonth() + 1) + "/" + enddate.getDate() + "/" + enddate.getFullYear();
        return { StartDate: finalStartDate, EndDate: finalEndDate }
    }
    else if (type.toLowerCase() == '7days') {
        let now = new Date();
        let now2 = new Date();
        let lastdays = new Date(now2.setDate(now2.getDate() - 7));
        finalEndDate = "" + (now.getMonth() + 1) + "/" + now.getDate() + "/" + now.getFullYear();
        finalStartDate = "" + (lastdays.getMonth() + 1) + "/" + lastdays.getDate() + "/" + lastdays.getFullYear();
        return { StartDate: finalStartDate, EndDate: finalEndDate }
    }
    else if (type.toLowerCase() == '14days') {
        let now = new Date();
        let now2 = new Date();
        let lastdays = new Date(now2.setDate(now2.getDate() - 14));
        finalEndDate = "" + (now.getMonth() + 1) + "/" + now.getDate() + "/" + now.getFullYear();
        finalStartDate = "" + (lastdays.getMonth() + 1) + "/" + lastdays.getDate() + "/" + lastdays.getFullYear();
        return { StartDate: finalStartDate, EndDate: finalEndDate }
    }
    else if (type.toLowerCase() == '30days') {
        let now = new Date();
        let now2 = new Date();
        let lastdays = new Date(now2.setDate(now2.getDate() - 30));
        finalEndDate = "" + (now.getMonth() + 1) + "/" + now.getDate() + "/" + now.getFullYear();
        finalStartDate = "" + (lastdays.getMonth() + 1) + "/" + lastdays.getDate() + "/" + lastdays.getFullYear();
        return { StartDate: finalStartDate, EndDate: finalEndDate }
    }
    else if (type.toLowerCase() == '60days') {

        let now = new Date();
        let now2 = new Date();
        let lastdays = new Date(now2.setDate(now2.getDate() - 60));
        finalEndDate = "" + (now.getMonth() + 1) + "/" + now.getDate() + "/" + now.getFullYear();
        finalStartDate = "" + (lastdays.getMonth() + 1) + "/" + lastdays.getDate() + "/" + lastdays.getFullYear();
        return { StartDate: finalStartDate, EndDate: finalEndDate }
    }
    else if (type.toLowerCase() == '90days') {
        let now = new Date();
        let now2 = new Date();
        let lastdays = new Date(now2.setDate(now2.getDate() - 90));
        finalEndDate = "" + (now.getMonth() + 1) + "/" + now.getDate() + "/" + now.getFullYear();
        finalStartDate = "" + (lastdays.getMonth() + 1) + "/" + lastdays.getDate() + "/" + lastdays.getFullYear();
        return { StartDate: finalStartDate, EndDate: finalEndDate }
    }
    else if (type.toLowerCase() == '180days') {
        let now = new Date();
        let now2 = new Date();
        let lastdays = new Date(now2.setDate(now2.getDate() - 180));
        finalEndDate = "" + (now.getMonth() + 1) + "/" + now.getDate() + "/" + now.getFullYear();
        finalStartDate = "" + (lastdays.getMonth() + 1) + "/" + lastdays.getDate() + "/" + lastdays.getFullYear();
        return { StartDate: finalStartDate, EndDate: finalEndDate }
    }
    else if (type.toLowerCase() == '365days') {
        let now = new Date();
        let now2 = new Date();
        let lastdays = new Date(now2.setDate(now2.getDate() - 365));
        finalEndDate = "" + (now.getMonth() + 1) + "/" + now.getDate() + "/" + now.getFullYear();
        finalStartDate = "" + (lastdays.getMonth() + 1) + "/" + lastdays.getDate() + "/" + lastdays.getFullYear();
        return { StartDate: finalStartDate, EndDate: finalEndDate }
    }
    else {
        return { StartDate: finalStartDate, EndDate: finalEndDate }
    }

}

export const HTMLTableExport = (tableID, filename = '') => {
    /*var data_type = 'data:application/vnd.ms-excel';
    var table_html = '<table>' + table.innerHTML + '</table>';
    var a = document.createElement('a');
    a.href = data_type + ', ' + table_html;
    a.download = fileName + '_' + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + '.xls';
    a.click();*/
    let uri = 'data:application/vnd.ms-excel;base64,',
        template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><title></title><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
        base64 = function (s) { return window.btoa(decodeURIComponent(encodeURIComponent(s))) }, format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
    let table = tableID
    if (!table.nodeType) table = document.getElementById(table)
    var ctx = { worksheet: filename || 'Worksheet', table: table.innerHTML }

    var link = document.createElement('a');
    link.download = filename;
    link.href = uri + base64(format(template, ctx));
    link.click();
}

function MakeTableToggleClick(event) {
    var htmlTableId = event.target.parentNode.parentNode.parentElement.id;
    var toggleId = event.target.parentNode.getAttribute('toggleid');
    if (toggleId == null) {
        return
    }
    document.querySelectorAll('#' + htmlTableId + ' tbody tr[toggleID="' + toggleId + '"]').forEach(
        function (item, ind) {
            if (item.className.toLowerCase().indexOf('hiderow') > -1) { item.classList.remove('hideRow'); item.classList.add('showRow'); item.previousSibling.classList.add('minusIcon'); }
            else if (item.className.toLowerCase().indexOf('showrow') > -1) { item.classList.remove('showRow'); item.classList.add('hideRow'); item.previousSibling.classList.remove('minusIcon'); }
        })
};
export const MakeTableToggle = (htmlTableId) => {

    var data = [];
    document.querySelectorAll('#' + htmlTableId + ' tbody tr').forEach(function (item, ind) {

        var toggleId = item.getAttribute('toggleId');
        if (document.querySelectorAll('#' + htmlTableId + ' tbody tr[toggleID="' + toggleId + '"]').length > 1) {
            if (data.filter(item => item == toggleId).length == 0) {
                data.push(toggleId);
                item.classList.add('showIcon');
                item.addEventListener('click', MakeTableToggleClick)
            }
            else {
                item.classList.add('hideRow');
            }
        }
    });
    if (document.querySelector('#' + htmlTableId) != null) {
        document.querySelector('#' + htmlTableId).style.display = 'table';
    }

}

export const OrderByEmployeeNumber = (a, b) => {
    const bandA = a.EmployeeNumber.toUpperCase();
    const bandB = b.EmployeeNumber.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
        comparison = 1;
    } else if (bandA < bandB) {
        comparison = -1;
    }
    return comparison;
}

export const ActiveMenu = (menuId) => {
    document.querySelectorAll('.sideNavLink  a').forEach(function (item) { item.classList.remove('active') });
    var menu = document.getElementById(menuId);
    if (menu != null) { menu.classList.add('active'); }
}


export const SetDateInModelsOnSelectedDate = (selectedDate) => {
    var current = new Date(selectedDate);//new Date();            // get current date    
    var weekstart = current.getDate() - (current.getDay());
    var startdate = new Date(current.setDate(weekstart));
    var finalStartDate = "" + (startdate.getMonth() + 1) + "/" + startdate.getDate() + "/" + startdate.getFullYear();
    document.querySelectorAll('.weekRepeat .weekDates').item(0).innerText = new Date(finalStartDate).toLocaleDateString();
    for (var i = 1; i < 14; i++) {
        var initialDate = new Date(finalStartDate);
        var nextDate = initialDate.setDate(initialDate.getDate() + i);
        document.querySelectorAll('.weekRepeat .weekDates').item(i).innerText = new Date(nextDate).toLocaleDateString();
    }
}

export const GetPendingApprovalCountForEachSection = async (ntLogin) => {
    var param = { NtLogin: ntLogin }
    var response = await HttpRequest("CoreHours/GetPendingApprovalCountForEachSection", 'post', param);
    var data = await CheckSupportIDException(response)

    return data;
}

export const validateEmail = (email) => {
    return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};


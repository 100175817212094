import {ActionTypes } from '../Store/Action'

const initialState = {
    treeSelectedEmployee:[],
    
};
export const countReducer = function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        
        case ActionTypes.TREESELECTEDEMPLOYEE:
            return {
                treeSelectedEmployee: payload
            }
        
        default:
            return state;
    }
};


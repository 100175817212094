import React, { Component } from 'react';
import { HttpRequest, ShowLoader, HTMLTableExport } from '../common/CommonOperations';
import { Link } from 'react-router-dom';

//import data from '../199.json';

export class OrganizationChart extends Component {
    static displayName = OrganizationChart.name;
    constructor(props) {
        super(props);
        this.state = {
            tableData: '',
            ApiUserDetails: null,
            data: null,
            SameLevel: null,
            SubLevel: null,
            ManagerData: null,
        }
        this.UserDetails = this.props.UserDetails;
    }
    async componentDidMount() {     
        
        await this.GetEmployeeDetailsFormApi();
        await this.getChartData();
    }

    GetEmployeeDetailsFormApi = async () => {
        let menuTittleName = this.props.history.location.pathname;
        let ntLogin = this.UserDetails.NTLogin;
        
        let splitId = menuTittleName.substring(menuTittleName.lastIndexOf('/') + 1);
        
        if (splitId !== 'OrganizationChart' && splitId !== '') {
            ntLogin = splitId;
        }
        
        var responseGoogle = await HttpRequest('SymptomTracker/GetUserDetailFromGoogleApi/' + ntLogin, 'GET');
        const dataGoogle = await responseGoogle.json();
        if (dataGoogle.success == true) {
            var approvalUser = await JSON.parse(dataGoogle.data);
            await this.setState({ ApiUserDetails: approvalUser });

        } else if (dataGoogle.success == false) {
           
                var responseGoogleElse = await HttpRequest('SymptomTracker/GetUserDetailFromGoogleApi/' + ntLogin, 'GET');

                let dataGoogleElse = await responseGoogleElse.json();
                let approvalUserElse = await JSON.parse(dataGoogleElse.data);

                await this.setState({ ApiUserDetails: approvalUserElse });
        }
    }
    onClose = () => {
        window.opener = null;
        window.open("", "_self");
        window.close();
    };
    getChartData = async () => {
        let menuTittleName = this.props.history.location.pathname;
        let ntLogin = this.UserDetails.NTLogin;

        let splitId = menuTittleName.substring(menuTittleName.lastIndexOf('/') + 1);

        if (splitId !== 'OrganizationChart' && splitId !=='')  {
            ntLogin = splitId;
        }
        var response = await HttpRequest('SymptomTracker/GetOrganizationChartFromGoogleApi/' + ntLogin, 'get');
        const res = await response.json();
        const resJson = JSON.parse(res.data);
        this.setState({ ManagerData: resJson.manager, SameLevel: resJson.sameLevel, SubLevel: resJson.subLevel })
    }
    GetNtLogin = (str) => {

        let email = str.split('@');
        return email[0];
    }

    RenderOrgChart = () => {
        if (this.state.ManagerData == null) { return <></> }; 
     
            return (
                <>
                    <div className="managerBoxContainer">
                        <div className="flw">
                    <div className="managerBox">
                            <div className="boxHeaderTop">
                                
                        </div>
                            <div className="boxBody">
                                <div className="userPick"><img src={this.state.ManagerData.profilePictureUrl} /></div>
                                    <div className="userText">
                                        <h2>{this.state.ManagerData.fullName}</h2>
                                        <Link to={'/OrganizationChart/' + this.GetNtLogin(this.state.ManagerData.email)} className="upLevelIcon">
                                            <svg viewBox="0 0 24 24">
                                                <path fill="currentColor" d="M13,18V10L16.5,13.5L17.92,12.08L12,6.16L6.08,12.08L7.5,13.5L11,10V18H13M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z" />
                                            </svg>
                                        </Link>
                                </div>
                        </div>
                            </div>
                        </div>
                        <div className="flw">
                            <div className="sameLevel" style={{ width: 217 * (this.state.SameLevel.length + 1) + 'px' }}>  {this.SameLevelEmployee()} </div>
                        </div>
                    </div>
                    
                </>
          )
    }

    SameLevelEmployee = () => {
        if (this.state.SameLevel == null) { return <></> };
        
        return this.state.SameLevel.map((item, index) => {
            return (
                <>
                    
                        <div className="sameLevelBox">
                        <div className="boxHeaderSecondLevel">
                           
                            </div>
                            <div className="boxBody">
                                <div className="userPick"><img src={item.profilePictureUrl} /></div>
                            <div className="userText"> <h2><Link to={'/OrganizationChart/' + this.GetNtLogin(item.email)}>{item.fullName}</Link></h2></div>
                            </div>

                        </div>
                   
                    {index == this.state.SameLevel.length - 1 ? <>
                        <div className={this.state.SubLevel == null || this.state.SubLevel==''   ? 'lastItem noChild' :'lastItem'}>
                            <div className="sameLevelBox">
                                <div className="boxHeaderSecondLevel">
                                    

                                </div>
                                <div className="boxBody">
                                    <div className="userPick">
                                        {this.state.ApiUserDetails != null ? <img src={this.state.ApiUserDetails.thumbnailPhotoUrl} /> : ''}
                                    </div>
                                    <div className="userText">
                                        <h2><Link to={'/OrganizationChart/' + this.GetNtLogin(this.state.ApiUserDetails.emails[0].address)}>{this.state.ApiUserDetails != null ? this.state.ApiUserDetails.name.fullName : ''}</Link></h2>
                                    </div>
                                </div>

                            </div>
                            {this.SubLevelEmplyee()}
                        </div>
                    </> : ''}

                </>
                )
        })
    }
   
    SubLevelEmplyee = () => {
        if (this.state.SubLevel == null) { return <></> };
        return this.state.SubLevel.map((item1) => {
            return (
                <>
                    <div className="boxUser">
                        <div className="boxHeader">
                           
                        </div>
                        <div className="boxBody">
                            <div className="userPick"><img src={item1.profilePictureUrl} /></div>
                            <div className="userText">
                                <h2><Link to={'/OrganizationChart/' + this.GetNtLogin(item1.email)}>{item1.fullName}</Link></h2>
                            </div>
                        </div>

                    </div>
                </>
            )
        }) 

    }


    ManagerTable = () => {
        if (this.state.ManagerData == null) { return <></> }; 
        return (
            <>  <td>{this.state.ManagerData.employeeId}</td>
                <td>{this.state.ManagerData.fullName}</td>
                <td>{this.state.ManagerData.email}</td>
            </>
            )
    }

    SameLevelTable = () => {
        if (this.state.SameLevel == null) { return <></> };

        return this.state.SameLevel.map((item, index) => {
            return (
                <>
                    <tr>
                        <td>{item.employeeId}</td>
                    <td>{item.fullName}</td>
                    <td>{item.email}</td>
                </tr>
                {
                index == this.state.SameLevel.length - 1 ?
                    <>
                                <tr>
                                    <td>{this.state.ApiUserDetails.externalIds[0].value}</td>
                                    <td>{this.state.ApiUserDetails.name.fullName}</td>
                                    <td>{this.state.ApiUserDetails.emails[0].address}</td>
                                    
                                </tr>
                </> : ''
                    }
                    </>
                )
        })
    }

    SubLevelEmplyeeTable = () => {
        if (this.state.SubLevel == null) { return <></> };
        return this.state.SubLevel.map((item1) => {
            return (
                <>
                    <tr>
                        <td>{item1.employeeId}</td>
                        <td>{item1.fullName}</td>
                        <td>{item1.email}</td>
                    </tr>
                      
                </>
            )
        })

    }


    DownloladOrgChart = (e) => {
        HTMLTableExport(document.getElementById('OrganizationChart'), 'OrganizationChart');
    }
    PrintPage = () => {
        window.print();
    }
    render() {
       

        return (
            <>
                <div className="container orgChartMain">
                    <br />
                    <br />
                    <div className="row">
                        <div className="col s12 l12 m12">
                            <button onClick={this.DownloladOrgChart} className="btn btn-primary">
                                <svg viewBox="0 0 24 24" style={{ width: '20px', position: 'relative', marginRight: '10px', top: '5px' }}>
                                    <path fill="currentColor" d="M14,2H6C4.89,2 4,2.89 4,4V20C4,21.11 4.89,22 6,22H18C19.11,22 20,21.11 20,20V8L14,2M12,19L8,15H10.5V12H13.5V15H16L12,19M13,9V3.5L18.5,9H13Z" />
                                </svg>Export to Excel</button>
                            <button onClick={this.PrintPage} className="btn btn-primary" style={{ float: 'right' }}>
                                <svg viewBox="0 0 24 24" style={{ width: '20px', position: 'relative', marginRight: '10px', top: '5px' }}>
                                    <path fill="currentColor" d="M12,2C9.11,2 6.6,3.64 5.35,6.04C2.34,6.36 0,8.91 0,12A6,6 0 0,0 6,18V22H18V18H19A5,5 0 0,0 24,13C24,10.36 21.95,8.22 19.35,8.04C18.67,4.59 15.64,2 12,2M8,13H16V20H8V13M9,14V15H15V14H9M9,16V17H15V16H9M9,18V19H15V18H9Z" />
                                </svg>Print</button>
                        </div>
                    </div>
                   <br /><br />
                    <div className="row">
                        <div className="col s12 l12 m12">
                            {this.RenderOrgChart()}
                        </div>
                    </div>
                </div>

                <table id='OrganizationChart' style={{ display:'none' }}>
                    <thead>
                        <tr>
                            <th>Employee Id</th>
                            <th>Name</th>
                            <th>Email</th>
                        </tr>
                    </thead>

                    <tbody>
                        
                        <tr>
                            {this.ManagerTable()}
                        </tr>
                      
                        
                            {this.SameLevelTable()}
                            {this.SubLevelEmplyeeTable()}
                    </tbody>

                </table>
                {/*<div className="download-org">
                    <h2>Download Organization Chart</h2>
                    <button onClick={this.DownloladOrgChart} className="btn btn-primary">
                        <svg viewBox="0 0 24 24">
                            <path fill="currentColor" d="M14,2H6C4.89,2 4,2.89 4,4V20C4,21.11 4.89,22 6,22H18C19.11,22 20,21.11 20,20V8L14,2M12,19L8,15H10.5V12H13.5V15H16L12,19M13,9V3.5L18.5,9H13Z" />
                        </svg>
                        Download Now
                    </button>
                </div>*/}

            </>
        );
    }
}



import React, { Component } from 'react';
import { HttpRequest, ShowLoader } from '../common/CommonOperations';
import { Link } from 'react-router-dom';
export class ProfilePageLumapps extends Component {
    static displayName = ProfilePageLumapps.name;
    constructor(props) {
        super(props);
        this.state = {
            EmployeeDetail: '',  
            DirectReports: '',
            EmployeeHirerachy: '',
            hideContent: true,
            SameLevel: '',
        }
    }
    componentDidMount() {
        this.GetSearchDetailsByPersonID();
        this.getChartData();
    }

    GetSearchDetailsByPersonID = async () => {
        //var response = await HttpRequest('BusinessCardEmployeeProfile/GetSearchDetailsByPersonID/' + this.UserDetails.PersonID, 'get');
        //var response = await HttpRequest('BusinessCardEmployeeProfile/GetSearchDetailsLumappByPersonID/' + this.UserDetails.PersonID, 'get');

        
        let windowLocation = window.location.href.split('/');
      
        let empId =  '150348';
        if (windowLocation.length > 4 && windowLocation[windowLocation.length - 1] !='') {
            empId = windowLocation[windowLocation.length - 1];
        }
        //empId = empId == '' ? this.UserDetails.PersonID : empId
       // var response = await HttpRequest('BusinessCardEmployeeProfile/GetSearchDetailsByPersonID/' + empId, 'get');
        var response = await HttpRequest('BusinessCardEmployeeProfile/GetSearchDetailsLumappByPersonID/' + empId, 'get');
        const res = await response.json();
        if (res.EmployeeDetails != null) {
            this.setState({ EmployeeDetail: res.EmployeeDetails, DirectReports: res.DirectReports, EmployeeHirerachy: res.EmployeeHirerachy, SameLevel: res.SameManager, hideContent:false })
        }
    }

    getChartData = async() => {
// GetEmployeeOrgChartDetails
        let windowLocation = window.location.href.split('/');

        let empId = '150348';
        if (windowLocation.length > 4 && windowLocation[windowLocation.length - 1] != '') {
            empId = windowLocation[windowLocation.length - 1];
        }
       
        var response = await HttpRequest('BusinessCardEmployeeProfile/GetEmployeeOrgChartDetails/' + empId, 'get');
        const res = await response.json();
    }
    RenderEmployeeHirerachy = () => {


        if (this.state.EmployeeHirerachy == null || this.state.EmployeeHirerachy == undefined || this.state.EmployeeHirerachy.length == 0 || this.state.EmployeeHirerachy[0] == null) { return ''; }

        return this.state.EmployeeHirerachy.map((item, index) => {
            return (
                <>
                    <div className="listContianer">
                        {index > 0 ? <div className="borderCenter"></div>:''}
                        <div className="reportingBlock">
                            <a href={'/ProfilePageLumapps/' + item.SearchHierarchyPersonID }>{item.SearchHierarchyName}</a><br />
                            {item.SearchHierarchyJobName}
                        </div>
                    </div>
                </>
                )
        });
    }


    



    TitleDetails = () => {
     
        if (this.state.EmployeeDetail == null || this.state.EmployeeDetail == undefined || this.state.EmployeeDetail.length == 0) { return '' }; 
return (
    <>
        <h1>{this.state.EmployeeDetail.FullName}
            <span>{this.state.EmployeeDetail.JobName}</span>   
        </h1>
        <img src={this.state.EmployeeDetail.CompanyImageUrl} />
    </>
    )
    }

    RenderDirectReports = () => {
        if (this.state.DirectReports == null || this.state.DirectReports == undefined || this.state.DirectReports.length == 0) { return '' }; 
        return this.state.DirectReports.map((item, index) => {
            return (
                <>
                    <div className="mainHirChildBox">
                        <div className="reportingBlock">
                            <a href={'/ProfilePageLumapps/' + item.SearchHierarchyPersonID}>
                                <span className="hiImgBox">{item.SearchHierarchyImage != null ? <img src={'data:image/JPEG;base64, ' + item.SearchHierarchyImage} /> : <img src="images/default-avatar-128.jpg" />}</span>{item.SearchHierarchyName}<span className="jobTitle">{item.SearchHierarchyJobName}</span>
                            </a>
                        </div>
                    </div>

                   
                </>
                )
        })
    }

    SameLevelEmployee = () => {
        if (this.state.SameLevel == null || this.state.SameLevel == undefined || this.state.SameLevel.length == 0) { return '' };
        return this.state.SameLevel.map((item, index) => {
            return (
                <>
                    <div className="mainHirChildBox">
                        <div className="reportingBlock">
                            <a href={'/ProfilePageLumapps/' + item.SearchHierarchyPersonID}>
                                <span className="hiImgBox">{item.SearchHierarchyImage != null ? <img src={'data:image/JPEG;base64, ' + item.SearchHierarchyImage} /> : <img src="images/default-avatar-128.jpg" />}</span>   {item.SearchHierarchyName} <span className="jobTitle">{item.SearchHierarchyJobName}</span>
                            </a>
                        </div>
                    </div>


                </>
            )
        })
    }



    EmployeePhotoRenderSection = () => {
        if (this.state.EmployeeDetail == null || this.state.EmployeeDetail == undefined || this.state.EmployeeDetail.length == 0) { return '' }; 
        return (
            <>
      
                <li className="badgeImgGroup badgeImgGroupLumapps">
                    <span style={{ display: this.state.EmployeeDetail.DishscriberImageUrl != '' && this.state.EmployeeDetail.DishscriberImageUrl != null ? 'inline-flex' : 'none' }}><img src={'https://intranet.global.dish.com/' + this.state.EmployeeDetail.DishscriberImageUrl} /> </span>
                    <span style={{ display: this.state.EmployeeDetail.SlingscriberImageUrl != '' && this.state.EmployeeDetail.SlingscriberImageUrl != null ? 'inline-flex' : 'none' }}><img src={'https://intranet.global.dish.com/' + this.state.EmployeeDetail.SlingscriberImageUrl} /></span>
                    <span style={{ display: this.state.EmployeeDetail.isEmpBaseCampCompleted == true ?'inline-flex':'none'}}><img src='https://intranet.global.dish.com/Images/Search/BasecampBadge.png' /></span>
                    <span style={{ display: this.state.EmployeeDetail.IsBoostScriber == true ? 'inline-flex' : 'none' }}><img src='https://intranet.global.dish.com/Images/Search/BoostscriberBadge.png' /></span>
                    <span style={{ display: this.state.EmployeeDetail.PatentsCount != null && this.state.EmployeeDetail.PatentsCount != '' ? 'inline-flex' : 'none' }}><img src='https://intranet.global.dish.com/images/Search/patentbadge.png' />
                        <span className='text-box'>
                            {this.state.EmployeeDetail.PatentsCount }
                        </span>
                    </span>

                    <span className="anniversaryDate" dangerouslySetInnerHTML={{ __html: this.getYearDiff(this.state.EmployeeDetail.AnniversaryDate) }}></span>
                    <span style={{ display: this.state.EmployeeDetail.DSAChairmans != '' && this.state.EmployeeDetail.DSAChairmans != null ? 'inline-flex' :'none'}}><img src='https://intranet.global.dish.com/Images/Search/DSAChairmans.png' title={this.state.EmployeeDetail.DSAChairmans} /></span>
                    <span style={{ display: this.state.EmployeeDetail.EmployeeSharesImageUrl != '' && this.state.EmployeeDetail.EmployeeSharesImageUrl != null ? 'inline-flex' : 'none' }}> <img src={'https://intranet.global.dish.com/' + this.state.EmployeeDetail.EmployeeSharesImageUrl} /></span>
                    <span style={{ display: this.state.EmployeeDetail.LiftBadgeImageUrl != '' && this.state.EmployeeDetail.LiftBadgeImageUrl != null ? 'inline-flex' : 'none' }}> <img src={'https://intranet.global.dish.com/' + this.state.EmployeeDetail.LiftBadgeImageUrl} /></span>
    
                </li>

            </>
        )
    }

    DateCal = (date) => {
        if (date != null || date != '') {
            let givenDate = new Date(date);
            let dates, months, years;
            dates = givenDate.getDate();
            months = givenDate.getMonth()+1;
            years = givenDate.getFullYear();
            return `${months < 10 ? '0' + months : months}/${dates < 10 ? '0' + dates : dates}/${years}`;
        }
        
        
    }

    getYearDiff = (useDate) => {
        if (useDate == null) { return ''; }
    let startDate = new Date(useDate);
    let endDate = new Date();
    let currentAnniversaryDate, anniversaryCount;
    let yearDiff = endDate.getFullYear() - startDate.getFullYear();

    if (startDate.getMonth() > endDate.getMonth()) {
        yearDiff--;
    }
    else if (startDate.getMonth() === endDate.getMonth()) {
        if (startDate.getDate() > endDate.getDate()) {
            yearDiff--;
        }
    }
    var d = new Date(startDate);
    if (yearDiff >= 1 && yearDiff < 5) {

        d.setFullYear(d.getFullYear() + 1);
        currentAnniversaryDate = d.getFullYear()
        anniversaryCount = 1;
    }

    else if (yearDiff >= 5 && yearDiff < 10) {
        d.setFullYear(d.getFullYear() + 5);
        currentAnniversaryDate = d.getFullYear();
        anniversaryCount = 5;
    }

    else if (yearDiff >= 10 && yearDiff < 15) {
        d.setFullYear(d.getFullYear() + 10);
        currentAnniversaryDate = d.getFullYear();
        anniversaryCount = 10;
    }

    else if (yearDiff >= 15 && yearDiff < 20) {
        d.setFullYear(d.getFullYear() + 15);
        currentAnniversaryDate = d.getFullYear();
        anniversaryCount = 15;
    }
    else if (yearDiff >= 20 && yearDiff < 25) {
        d.setFullYear(d.getFullYear() + 20);
        currentAnniversaryDate = d.getFullYear();
        anniversaryCount = 20;
    }
    else if (yearDiff >= 25 && yearDiff < 30) {
        d.setFullYear(d.getFullYear() + 25);
        currentAnniversaryDate = d.getFullYear();
        anniversaryCount = 25;
    }
    else if (yearDiff >= 30 && yearDiff < 35) {
        d.setFullYear(d.getFullYear() + 30);
        currentAnniversaryDate = d.getFullYear();
        anniversaryCount = 30;
    }
    else if (yearDiff >= 35 && yearDiff < 40) {
        d.setFullYear(d.getFullYear() + 35);
        currentAnniversaryDate = d.getFullYear();
        anniversaryCount = 35;
    }
    else if (yearDiff >= 40 && yearDiff < 45) {
        d.setFullYear(d.getFullYear() + 40);
        currentAnniversaryDate = d.getFullYear();
        anniversaryCount = 40;
    }
        if (anniversaryCount == 1) {
            return `<img src="https://intranet.global.dish.com/Images/AnniversaryBadge/1st_anniv_badge.png" /><span class="an1">${currentAnniversaryDate}</span>`;
        } else if (anniversaryCount == 5) {
            return `<img src="https://intranet.global.dish.com/Images/AnniversaryBadge/${anniversaryCount}th_anniv_badge.png" /><span class="an5">${currentAnniversaryDate}</span>`
        } else if (anniversaryCount == 10) {
            return `<img src="https://intranet.global.dish.com/Images/AnniversaryBadge/${anniversaryCount}th_anniv_badge.png" /><span class="an10">${currentAnniversaryDate}</span>`
        } else if (anniversaryCount == 15) {
            return `<img src="https://intranet.global.dish.com/Images/AnniversaryBadge/${anniversaryCount}th_anniv_badge.png" /><span class="an15">${currentAnniversaryDate}</span>`
        }else if (anniversaryCount == 20) {
            return `<img src="https://intranet.global.dish.com/Images/AnniversaryBadge/${anniversaryCount}th_anniv_badge.png" /><span class="an20">${currentAnniversaryDate}</span>`
        } else if (anniversaryCount == 25) {
            return `<img src="https://intranet.global.dish.com/Images/AnniversaryBadge/${anniversaryCount}th_anniv_badge.png" /><span class="an25">${currentAnniversaryDate}</span>`
        } else if (anniversaryCount == 30) {
            return `<img src="https://intranet.global.dish.com/Images/AnniversaryBadge/${anniversaryCount}th_anniv_badge.png" /><span class="an30">${currentAnniversaryDate}</span>`
        } else if (anniversaryCount == 35) {
            return `<img src="https://intranet.global.dish.com/Images/AnniversaryBadge/${anniversaryCount}th_anniv_badge.png" /><span class="an35">${currentAnniversaryDate}</span>`
        } else if (anniversaryCount == 40) {
            return `<img src="https://intranet.global.dish.com/Images/AnniversaryBadge/${anniversaryCount}th_anniv_badge.png" /><span  class="an45">${currentAnniversaryDate}</span>`
        }else {
            return '';   
        }
}


    EmployeeInfoSectionRender = () => {
        if (this.state.EmployeeDetail == null || this.state.EmployeeDetail == undefined || this.state.EmployeeDetail.length == 0) { return '' }; 
        return (
            <>
                <div className="col s12 l4 m4">
                    <ul className="employeeInformationDisplayList">
                        <li><span>Employee #: </span>{this.state.EmployeeDetail.EmployeeNumber}</li>
                        <li><span>NT Login: </span>{this.state.EmployeeDetail.NtLogin}</li>
                        <li><span>Oracle ID: </span>{this.state.EmployeeDetail.OracleId}</li>
                        <li style={{ display: this.state.EmployeeDetail.LocationID != '' && this.state.EmployeeDetail.LocationID !=null?'block':'none'}}><span>Location ID: </span>{this.state.EmployeeDetail.LocationID}</li>
                        <li style={{ display: this.state.EmployeeDetail.DepartmentName != '' && this.state.EmployeeDetail.DepartmentName ? 'block':'none'}}><span>Department Name: </span>{this.state.EmployeeDetail.DepartmentName}</li>
                        <li style={{ display: this.state.EmployeeDetail.SeatingLocation != '' && this.state.EmployeeDetail.SeatingLocation != null ? 'block' : 'none' }}><span>Seating Location: </span>{this.state.EmployeeDetail.SeatingLocation}</li>
                        <li style={{ display: this.state.EmployeeDetail.DepartmentID != '' && this.state.EmployeeDetail.DepartmentID != null ? 'block':'none'}}><span>Department ID: </span>{this.state.EmployeeDetail.DepartmentID}</li>
                        <li style={{ display: this.state.EmployeeDetail.LegacyID != '' && this.state.EmployeeDetail.LegacyID !=null?'block':'none'}}><span>Legacy ID: </span>{this.state.EmployeeDetail.LegacyID}</li>
                        <li style={{ display: this.state.EmployeeDetail.HireDate != '' && this.state.EmployeeDetail.HireDate != null ? 'block' : 'none' }}><span>Latest Hire Date: </span>{this.DateCal(this.state.EmployeeDetail.HireDate)}</li>
                        <li style={{ display: this.state.EmployeeDetail.AnniversaryDate != '' && this.state.EmployeeDetail.AnniversaryDate != null ? 'block':'none'}}><span>Anniversary Date: </span>{this.DateCal(this.state.EmployeeDetail.AnniversaryDate)}</li>
                        {this.state.EmployeeDetail.JobName != 'Contractor' ?<>
                            <li style={{ display:this.state.EmployeeDetail.HR1PersonID != '' && this.state.EmployeeDetail.HR1PersonID != null?'block':'none' }}><span>Primary HR Business Partner: </span><Link to={/ProfilePageLumapps/ + this.state.EmployeeDetail.HR1PersonID}>{this.state.EmployeeDetail.HR1FullName}</Link></li>
                            <li style={{ display: this.state.EmployeeDetail.HR2PersonID != '' && this.state.EmployeeDetail.HR1PersonID != null ? 'block' : 'none' }}><span>Secondary HR Business Partner: </span><Link to={/ProfilePageLumapps/ + this.state.EmployeeDetail.HR2PersonID}>{this.state.EmployeeDetail.HR2FullName}</Link></li>
                            </>:''
                            }
                        
                        <li style={{ display: this.state.EmployeeDetail.OfficePhoneNumber != '' && this.state.EmployeeDetail.OfficePhoneNumber !=null?'block':'none'}}><span>Office Phone Number: </span>{this.state.EmployeeDetail.OfficePhoneNumber}</li>
                        <li style={{ display: this.state.EmployeeDetail.DeskPhoneExtension != '' && this.state.EmployeeDetail.DeskPhoneExtension != null ? 'block':'none'}}><span>Desk Phone Extension: </span>{this.state.EmployeeDetail.DeskPhoneExtension}</li>
                        <li style={{ display: this.state.EmployeeDetail.MobilePhoneNumber != '' && this.state.EmployeeDetail.MobilePhoneNumber != null?'block':'none' }}><span>Mobile Phone Number: </span>{this.state.EmployeeDetail.MobilePhoneNumber}</li>
                        

                    </ul>
                </div>
                <div className="col s12 l4 m4">
                    <ul className="employeeInformationDisplayList">
                        <li style={{ display: this.state.EmployeeDetail.TeamName != '' && this.state.EmployeeDetail.TeamName != null ? 'block' : 'none' }}><span>Team Name: </span>{this.state.EmployeeDetail.TeamName}</li>
                        <li style={{ display: this.state.EmployeeDetail.ProfileDescription != '' && this.state.EmployeeDetail.ProfileDescription != null ? 'block' : 'none' }}><span>Job Description: </span>{this.state.EmployeeDetail.ProfileDescription}</li>
                        
                        
                        <li style={{ display: this.state.EmployeeDetail.PreferredPronouns != '' && this.state.EmployeeDetail.PreferredPronouns != null ? 'block' : 'none' }}><span>Preferred Pronouns: </span>{this.state.EmployeeDetail.PreferredPronouns}</li>
                        <li style={{ display: this.state.EmployeeDetail.ERGMembership != '' && this.state.EmployeeDetail.ERGMembership !=null?'block': 'none'}}><span>ERG Membership: </span>{this.state.EmployeeDetail.ERGMembership}</li>
                        <li style={{ display: this.state.EmployeeDetail.ProfileQuestion1 != '' && this.state.EmployeeDetail.ProfileQuestion1 !=null ? 'block':'none'}}><span>{this.state.EmployeeDetail.ProfileQuestion1}</span> {this.state.EmployeeDetail.ProfileAnswer1}</li>
                        <li style={{ display: this.state.EmployeeDetail.ProfileQuestion2 != '' && this.state.EmployeeDetail.ProfileQuestion2 != null ? 'block' : 'none' }}><span>{this.state.EmployeeDetail.ProfileQuestion2}</span> {this.state.EmployeeDetail.ProfileAnswer2}</li>
                        <li style={{ display: this.state.EmployeeDetail.ProfileQuestion3 != '' && this.state.EmployeeDetail.ProfileQuestion3 != null ? 'block' : 'none' }}><span>{this.state.EmployeeDetail.ProfileQuestion3}</span> {this.state.EmployeeDetail.ProfileAnswer3}</li>
                        <li style={{ display: this.state.EmployeeDetail.ProfileQuestion4 != '' && this.state.EmployeeDetail.ProfileQuestion4 != null ? 'block' : 'none' }}><span>{this.state.EmployeeDetail.ProfileQuestion4}</span> {this.state.EmployeeDetail.ProfileAnswer4}</li>
                        <li style={{ display: this.state.EmployeeDetail.ProfileQuestion5 != '' && this.state.EmployeeDetail.ProfileQuestion5 != null ? 'block' : 'none' }}><span>{this.state.EmployeeDetail.ProfileQuestion5}</span> {this.state.EmployeeDetail.ProfileAnswer5}</li>
                       
                    </ul>
                </div>
           </>
            )
    }

    employeeHir = () =>{
        if (this.state.EmployeeHirerachy == null || this.state.EmployeeHirerachy == undefined || this.state.EmployeeHirerachy.length == 0 || this.state.EmployeeHirerachy[0] == null) { return ''; }
        let len = this.state.EmployeeHirerachy.length;
        return this.state.EmployeeHirerachy.map((item, index) => {
            return (
                <>
                    <div className={index == len - 1 ? 'mainHirCon imgBox-lastChild' : 'mainHirCon imgBox'+index} style={{ marginLeft:40*index+'px'}}>
                        <div className="reportingBlock">
                            <a className={`${len - 1 == index ? "active" : ""}`}href={'/ProfilePageLumapps/' + item.SearchHierarchyPersonID}>
                                <span className="hiImgBox">
                                    {item.SearchHierarchyImage != null ? <img src={'data:image/JPEG;base64, ' + item.SearchHierarchyImage} /> : <img src="images/default-avatar-128.jpg" />}
                                </span> {item.SearchHierarchyName}
                                <span className="jobTitle">{item.SearchHierarchyJobName}</span>

                            </a>
                        </div>
                        {len - 1 == index ? <div className="mainHirChildCon">{this.RenderDirectReports()}</div> : ''}
                        {len - 1 == index ? <div className="sameLevel">{this.SameLevelEmployee()}</div> : ''}
                    </div>
                    
                </>
            )
        });
        
    }
    ProfileTopSection = () => {
        if (this.state.EmployeeDetail == null || this.state.EmployeeDetail == undefined || this.state.EmployeeDetail.length == 0) { return '' };
        return(
            <>
                <div className="profileImg">
                    <div className="ImgBox">
                        <img src={this.state.EmployeeDetail.EmpImage != null ? 'data:image/JPEG;base64, ' + this.state.EmployeeDetail.EmpImage : 'images/Search/AnonymousUser.png'} />
                    </div>
                    <h2>{this.state.EmployeeDetail.FullName}</h2>
                </div>
                <div className="emailAddress">
                    <a href={'https://mail.google.com/mail/?view=cm&fs=1&to=' + this.state.EmployeeDetail.EmailAddress} target="_blank" className="iconDisplay"><svg aria-hidden="true" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" width="1em"><path d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z" fill="currentColor"></path></svg></a>
                </div>
            </>
      )
    }

    ListDataDisplay = () => {
        if (this.state.EmployeeDetail == null || this.state.EmployeeDetail == undefined || this.state.EmployeeDetail.length == 0) { return '' };
        return (
            <>
                <li className="user-details-list-item lumx-list-item lumx-list-item--size-regular" style={{ display:this.state.EmployeeDetail.JobName != '' && this.state.EmployeeDetail.JobName !=null?'block':'none'}}>
                    <div className="lumx-list-item__wrapper">
                        <div className="lumx-list-item__before">
                            <i className="lumx-icon lumx-icon--color-dark lumx-icon--has-shape lumx-icon--size-m lumx-icon--path">
                                <svg aria-hidden="true" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" width="1em"><path d="M2,3H22C23.05,3 24,3.95 24,5V19C24,20.05 23.05,21 22,21H2C0.95,21 0,20.05 0,19V5C0,3.95 0.95,3 2,3M14,6V7H22V6H14M14,8V9H21.5L22,9V8H14M14,10V11H21V10H14M8,13.91C6,13.91 2,15 2,17V18H14V17C14,15 10,13.91 8,13.91M8,6A3,3 0 0,0 5,9A3,3 0 0,0 8,12A3,3 0 0,0 11,9A3,3 0 0,0 8,6Z" fill="currentColor"></path></svg>
                            </i>
                        </div>
                        <div className="lumx-list-item__content">
                            <div className="user-details-list-item__content">
                                <span className="user-details-list-item__title">Title</span>
                                <span className="user-details-list-item__value">{this.state.EmployeeDetail.JobName}</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="user-details-list-item lumx-list-item lumx-list-item--size-regular" style={{ display: this.state.EmployeeDetail.EmployeeNumber != '' && this.state.EmployeeDetail.EmployeeNumber != null ? 'block' : 'none' }}>
                    <div className="lumx-list-item__wrapper">
                        <div className="lumx-list-item__before">
                            <i className="lumx-icon lumx-icon--color-dark lumx-icon--has-shape lumx-icon--size-m lumx-icon--path">
                                <svg aria-hidden="true" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" width="1em"><path d="M4,2H6V4C6,5.44 6.68,6.61 7.88,7.78C8.74,8.61 9.89,9.41 11.09,10.2L9.26,11.39C8.27,10.72 7.31,10 6.5,9.21C5.07,7.82 4,6.1 4,4V2M18,2H20V4C20,6.1 18.93,7.82 17.5,9.21C16.09,10.59 14.29,11.73 12.54,12.84C10.79,13.96 9.09,15.05 7.88,16.22C6.68,17.39 6,18.56 6,20V22H4V20C4,17.9 5.07,16.18 6.5,14.79C7.91,13.41 9.71,12.27 11.46,11.16C13.21,10.04 14.91,8.95 16.12,7.78C17.32,6.61 18,5.44 18,4V2M14.74,12.61C15.73,13.28 16.69,14 17.5,14.79C18.93,16.18 20,17.9 20,20V22H18V20C18,18.56 17.32,17.39 16.12,16.22C15.26,15.39 14.11,14.59 12.91,13.8L14.74,12.61M7,3H17V4L16.94,4.5H7.06L7,4V3M7.68,6H16.32C16.08,6.34 15.8,6.69 15.42,7.06L14.91,7.5H9.07L8.58,7.06C8.2,6.69 7.92,6.34 7.68,6M9.09,16.5H14.93L15.42,16.94C15.8,17.31 16.08,17.66 16.32,18H7.68C7.92,17.66 8.2,17.31 8.58,16.94L9.09,16.5M7.06,19.5H16.94L17,20V21H7V20L7.06,19.5Z" fill="currentColor"></path></svg>
                            </i>
                        </div>
                        <div className="lumx-list-item__content">
                            <div className="user-details-list-item__content">
                                <span className="user-details-list-item__title">Employee #</span>
                                <span className="user-details-list-item__value">{this.state.EmployeeDetail.EmployeeNumber}</span>
                            </div>
                        </div>
                    </div>
                </li>

                <li className="user-details-list-item lumx-list-item lumx-list-item--size-regular" style={{ display: this.state.EmployeeDetail.OfficePhoneNumber != '' && this.state.EmployeeDetail.OfficePhoneNumber != null ? 'block' : 'none' }}>
                    <div className="lumx-list-item__wrapper">
                        <div className="lumx-list-item__before">
                            <i className="lumx-icon lumx-icon--color-dark lumx-icon--has-shape lumx-icon--size-m lumx-icon--path">
                                <svg aria-hidden="true" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" width="1em"><path d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" fill="currentColor"></path></svg>
                            </i>
                        </div>
                        <div className="lumx-list-item__content">
                            <div className="user-details-list-item__content">
                                <span className="user-details-list-item__title">Office Phone Number</span>
                                <span className="user-details-list-item__value">{this.state.EmployeeDetail.OfficePhoneNumber}</span>
                            </div>
                        </div>
                    </div>
                </li>

                <li className="user-details-list-item lumx-list-item lumx-list-item--size-regular" style={{ display: this.state.EmployeeDetail.MobilePhoneNumber != '' && this.state.EmployeeDetail.MobilePhoneNumber != null ? 'block' : 'none' }}>
                    <div className="lumx-list-item__wrapper">
                        <div className="lumx-list-item__before">
                            <i className="lumx-icon lumx-icon--color-dark lumx-icon--has-shape lumx-icon--size-m lumx-icon--path">
                                <svg aria-hidden="true" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" width="1em"><path d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" fill="currentColor"></path></svg>
                            </i>
                        </div>
                        <div className="lumx-list-item__content">
                            <div className="user-details-list-item__content">
                                <span className="user-details-list-item__title">Mobile Phone Number</span>
                                <span className="user-details-list-item__value">{this.state.EmployeeDetail.MobilePhoneNumber}</span>
                            </div>
                        </div>
                    </div>
                </li>

                <li className="user-details-list-item lumx-list-item lumx-list-item--size-regular" style={{ display: this.state.EmployeeDetail.DeskPhoneExtension != '' && this.state.EmployeeDetail.DeskPhoneExtension != null ? 'block' : 'none' }}>
                    <div className="lumx-list-item__wrapper">
                        <div className="lumx-list-item__before">
                            <i className="lumx-icon lumx-icon--color-dark lumx-icon--has-shape lumx-icon--size-m lumx-icon--path">
                                <svg aria-hidden="true" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" width="1em"><path d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" fill="currentColor"></path></svg>
                            </i>
                        </div>
                        <div className="lumx-list-item__content">
                            <div className="user-details-list-item__content">
                                <span className="user-details-list-item__title">Extension</span>
                                <span className="user-details-list-item__value">{this.state.EmployeeDetail.DeskPhoneExtension}</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="user-details-list-item lumx-list-item lumx-list-item--size-regular" style={{ display: this.state.EmployeeDetail.PreferredPronouns != '' && this.state.EmployeeDetail.PreferredPronouns != null ? 'block' : 'none' }}>
                    <div className="lumx-list-item__wrapper">
                        <div className="lumx-list-item__before">
                            <i className="lumx-icon lumx-icon--color-dark lumx-icon--has-shape lumx-icon--size-m lumx-icon--path">
                                <svg aria-hidden="true" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" width="1em"><path d="M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M15.5,8A1.5,1.5 0 0,1 17,9.5A1.5,1.5 0 0,1 15.5,11A1.5,1.5 0 0,1 14,9.5A1.5,1.5 0 0,1 15.5,8M8.5,8A1.5,1.5 0 0,1 10,9.5A1.5,1.5 0 0,1 8.5,11A1.5,1.5 0 0,1 7,9.5A1.5,1.5 0 0,1 8.5,8M12,17.5C9.67,17.5 7.69,16.04 6.89,14H17.11C16.3,16.04 14.33,17.5 12,17.5Z" fill="currentColor"></path></svg>
                            </i>
                        </div>
                        <div className="lumx-list-item__content">
                            <div className="user-details-list-item__content">
                                <span className="user-details-list-item__title">Preferred Pronouns</span>
                                <span className="user-details-list-item__value">{this.state.EmployeeDetail.PreferredPronouns}</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="user-details-list-item lumx-list-item lumx-list-item--size-regular" style={{ display: this.state.EmployeeDetail.DepartmentName != '' && this.state.EmployeeDetail.DepartmentName != null ? 'block' : 'none' }}>
                    <div className="lumx-list-item__wrapper">
                        <div className="lumx-list-item__before">
                            <i className="lumx-icon lumx-icon--color-dark lumx-icon--has-shape lumx-icon--size-m lumx-icon--path">
                                <svg aria-hidden="true" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" width="1em"><path d="M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z" fill="currentColor"></path></svg>
                            </i>
                        </div>
                        <div className="lumx-list-item__content">
                            <div className="user-details-list-item__content">
                                <span className="user-details-list-item__title">Department Name</span>
                                <span className="user-details-list-item__value">{this.state.EmployeeDetail.DepartmentName}</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="user-details-list-item lumx-list-item lumx-list-item--size-regular" style={{ display: this.state.EmployeeDetail.DepartmentID != '' && this.state.EmployeeDetail.DepartmentID != null ? 'block' : 'none' }}>
                    <div className="lumx-list-item__wrapper">
                        <div className="lumx-list-item__before">
                            <i className="lumx-icon lumx-icon--color-dark lumx-icon--has-shape lumx-icon--size-m lumx-icon--path">
                                <svg aria-hidden="true" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" width="1em"><path d="M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z" fill="currentColor"></path></svg>
                            </i>
                        </div>
                        <div className="lumx-list-item__content">
                            <div className="user-details-list-item__content">
                                <span className="user-details-list-item__title">Department ID</span>
                                <span className="user-details-list-item__value">{this.state.EmployeeDetail.DepartmentID}</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="user-details-list-item lumx-list-item lumx-list-item--size-regular" style={{ display: this.state.EmployeeDetail.SeatingLocation != '' && this.state.EmployeeDetail.SeatingLocation != null ? 'block' : 'none' }}>
                    <div className="lumx-list-item__wrapper">
                        <div className="lumx-list-item__before">
                            <i className="lumx-icon lumx-icon--color-dark lumx-icon--has-shape lumx-icon--size-m lumx-icon--path">
                                <svg aria-hidden="true" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" width="1em"><path d="M14.19,14.19L6,18L9.81,9.81L18,6M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,10.9A1.1,1.1 0 0,0 10.9,12A1.1,1.1 0 0,0 12,13.1A1.1,1.1 0 0,0 13.1,12A1.1,1.1 0 0,0 12,10.9Z" fill="currentColor"></path></svg>
                            </i>
                        </div>
                        <div className="lumx-list-item__content">
                            <div className="user-details-list-item__content">
                                <span className="user-details-list-item__title">Seating Location</span>
                                <span className="user-details-list-item__value">{this.state.EmployeeDetail.SeatingLocation}</span>
                            </div>
                        </div>
                    </div>
                </li>

                <li className="user-details-list-item lumx-list-item lumx-list-item--size-regular" style={{ display: this.state.EmployeeDetail.LocationName != '' && this.state.EmployeeDetail.LocationName != null ? 'block' : 'none' }}>
                    <div className="lumx-list-item__wrapper">
                        <div className="lumx-list-item__before">
                            <i className="lumx-icon lumx-icon--color-dark lumx-icon--has-shape lumx-icon--size-m lumx-icon--path">
                                <svg aria-hidden="true" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" width="1em"><path d="M19,15H17V13H19M19,19H17V17H19M13,7H11V5H13M13,11H11V9H13M13,15H11V13H13M13,19H11V17H13M7,11H5V9H7M7,15H5V13H7M7,19H5V17H7M15,11V5L12,2L9,5V7H3V21H21V11H15Z" fill="currentColor"></path></svg>
                            </i>
                        </div>
                        <div className="lumx-list-item__content">
                            <div className="user-details-list-item__content">
                                <span className="user-details-list-item__title">Location Name</span>
                                <span className="user-details-list-item__value">{this.state.EmployeeDetail.LocationName}</span>
                            </div>
                        </div>
                    </div>
                </li>

                <li className="user-details-list-item lumx-list-item lumx-list-item--size-regular" style={{ display: this.state.EmployeeDetail.LocationID != '' && this.state.EmployeeDetail.LocationID != null ? 'block' : 'none' }}>
                    <div className="lumx-list-item__wrapper">
                        <div className="lumx-list-item__before">
                            <i className="lumx-icon lumx-icon--color-dark lumx-icon--has-shape lumx-icon--size-m lumx-icon--path">
                                <svg aria-hidden="true" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" width="1em"><path d="M19,15H17V13H19M19,19H17V17H19M13,7H11V5H13M13,11H11V9H13M13,15H11V13H13M13,19H11V17H13M7,11H5V9H7M7,15H5V13H7M7,19H5V17H7M15,11V5L12,2L9,5V7H3V21H21V11H15Z" fill="currentColor"></path></svg>
                            </i>
                        </div>
                        <div className="lumx-list-item__content">
                            <div className="user-details-list-item__content">
                                <span className="user-details-list-item__title">Location ID</span>
                                <span className="user-details-list-item__value">{this.state.EmployeeDetail.LocationID}</span>
                            </div>
                        </div>
                    </div>
                </li>

                <li className="user-details-list-item lumx-list-item lumx-list-item--size-regular" style={{ display: this.state.EmployeeDetail.AnniversaryDate != '' && this.state.EmployeeDetail.AnniversaryDate != null ? 'block' : 'none' }}>
                    <div className="lumx-list-item__wrapper">
                        <div className="lumx-list-item__before">
                            <i className="lumx-icon lumx-icon--color-dark lumx-icon--has-shape lumx-icon--size-m lumx-icon--path">
                                <svg aria-hidden="true" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" width="1em"><path d="M19,15H17V13H19M19,19H17V17H19M13,7H11V5H13M13,11H11V9H13M13,15H11V13H13M13,19H11V17H13M7,11H5V9H7M7,15H5V13H7M7,19H5V17H7M15,11V5L12,2L9,5V7H3V21H21V11H15Z" fill="currentColor"></path></svg>
                            </i>
                        </div>
                        <div className="lumx-list-item__content">
                            <div className="user-details-list-item__content">
                                <span className="user-details-list-item__title">Anniversary date</span>
                                <span className="user-details-list-item__value">{this.DateCal(this.state.EmployeeDetail.AnniversaryDate)}</span>
                            </div>
                        </div>
                    </div>
                </li>

                <li className="user-details-list-item lumx-list-item lumx-list-item--size-regular" style={{ display: this.state.EmployeeDetail.HireDate != '' && this.state.EmployeeDetail.HireDate != null ? 'block' : 'none' }}>
                    <div className="lumx-list-item__wrapper">
                        <div className="lumx-list-item__before">
                            <i className="lumx-icon lumx-icon--color-dark lumx-icon--has-shape lumx-icon--size-m lumx-icon--path">
                                <svg aria-hidden="true" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" width="1em"><path d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z" fill="currentColor"></path></svg>
                            </i>
                        </div>
                        <div className="lumx-list-item__content">
                            <div className="user-details-list-item__content">
                                <span className="user-details-list-item__title">Latest Hire Date</span>
                                <span className="user-details-list-item__value">{this.DateCal(this.state.EmployeeDetail.HireDate)}</span>
                            </div>
                        </div>
                    </div>
                </li>

                <li className="user-details-list-item lumx-list-item lumx-list-item--size-regular" style={{ display: this.state.EmployeeDetail.EmailAddress != '' && this.state.EmployeeDetail.EmailAddress != null ? 'block' : 'none' }}>
                    <div className="lumx-list-item__wrapper">
                        <div className="lumx-list-item__before">
                            <i className="lumx-icon lumx-icon--color-dark lumx-icon--has-shape lumx-icon--size-m lumx-icon--path">
                                <svg aria-hidden="true" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" width="1em"><path d="M12,6C13.11,6 14,5.1 14,4C14,3.62 13.9,3.27 13.71,2.97L12,0L10.29,2.97C10.1,3.27 10,3.62 10,4A2,2 0 0,0 12,6M16.6,16L15.53,14.92L14.45,16C13.15,17.29 10.87,17.3 9.56,16L8.5,14.92L7.4,16C6.75,16.64 5.88,17 4.96,17C4.23,17 3.56,16.77 3,16.39V21A1,1 0 0,0 4,22H20A1,1 0 0,0 21,21V16.39C20.44,16.77 19.77,17 19.04,17C18.12,17 17.25,16.64 16.6,16M18,9H13V7H11V9H6A3,3 0 0,0 3,12V13.54C3,14.62 3.88,15.5 4.96,15.5C5.5,15.5 6,15.3 6.34,14.93L8.5,12.8L10.61,14.93C11.35,15.67 12.64,15.67 13.38,14.93L15.5,12.8L17.65,14.93C18,15.3 18.5,15.5 19.03,15.5C20.11,15.5 21,14.62 21,13.54V12A3,3 0 0,0 18,9Z" fill="currentColor"></path></svg>
                            </i>
                        </div>
                        <div className="lumx-list-item__content">
                            <div className="user-details-list-item__content">
                                <span className="user-details-list-item__title">Email</span>
                                <span className="user-details-list-item__value"><a href={'https://mail.google.com/mail/?view=cm&fs=1&to=' + this.state.EmployeeDetail.EmailAddress} target="_blank">{this.state.EmployeeDetail.EmailAddress}</a></span>
                            </div>
                        </div>
                    </div>
                </li>

                <li className="user-details-list-item lumx-list-item lumx-list-item--size-regular" style={{ display: this.state.EmployeeDetail.TeamName != '' && this.state.EmployeeDetail.TeamName != null ? 'block' : 'none' }}>
                    <div className="lumx-list-item__wrapper">
                        <div className="lumx-list-item__before">
                            <i className="lumx-icon lumx-icon--color-dark lumx-icon--has-shape lumx-icon--size-m lumx-icon--path">
                                <svg aria-hidden="true" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" width="1em"><path d="M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z" fill="currentColor"></path></svg>
                            </i>
                        </div>
                        <div className="lumx-list-item__content">
                            <div className="user-details-list-item__content">
                                <span className="user-details-list-item__title">Team Name</span>
                                <span className="user-details-list-item__value">{this.state.EmployeeDetail.TeamName}</span>
                            </div>
                        </div>
                    </div>
                </li>

                <li className="user-details-list-item lumx-list-item lumx-list-item--size-regular" style={{ display: this.state.EmployeeDetail.ProfileQuestion1 != '' && this.state.EmployeeDetail.ProfileQuestion1 != null ? 'block' : 'none' }}>
                    <div className="lumx-list-item__wrapper">
                        <div className="lumx-list-item__before">
                            <i className="lumx-icon lumx-icon--color-dark lumx-icon--has-shape lumx-icon--size-m lumx-icon--path">
                                <svg aria-hidden="true" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" width="1em"><path d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M10,16V19.08L13.08,16H20V4H4V16H10M6,7H18V9H6V7M6,11H15V13H6V11Z" fill="currentColor"></path></svg>
                            </i>
                        </div>
                        <div className="lumx-list-item__content">
                            <div className="user-details-list-item__content">
                                <span className="user-details-list-item__title">{this.state.EmployeeDetail.ProfileQuestion1}</span>
                                <span className="user-details-list-item__value">{this.state.EmployeeDetail.ProfileAnswer1}</span>
                            </div>
                        </div>
                    </div>
                </li>

                <li className="user-details-list-item lumx-list-item lumx-list-item--size-regular" style={{ display: this.state.EmployeeDetail.ProfileQuestion2 != '' && this.state.EmployeeDetail.ProfileQuestion2 != null ? 'block' : 'none' }}>
                    <div className="lumx-list-item__wrapper">
                        <div className="lumx-list-item__before">
                            <i className="lumx-icon lumx-icon--color-dark lumx-icon--has-shape lumx-icon--size-m lumx-icon--path">
                                <svg aria-hidden="true" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" width="1em"><path d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M10,16V19.08L13.08,16H20V4H4V16H10M6,7H18V9H6V7M6,11H15V13H6V11Z" fill="currentColor"></path></svg>
                            </i>
                        </div>
                        <div className="lumx-list-item__content">
                            <div className="user-details-list-item__content">
                                <span className="user-details-list-item__title">{this.state.EmployeeDetail.ProfileQuestion2}</span>
                                <span className="user-details-list-item__value">{this.state.EmployeeDetail.ProfileAnswer2}</span>
                            </div>
                        </div>
                    </div>
                </li>

                <li className="user-details-list-item lumx-list-item lumx-list-item--size-regular" style={{ display: this.state.EmployeeDetail.ProfileQuestion3 != '' && this.state.EmployeeDetail.ProfileQuestion3 != null ? 'block' : 'none' }}>
                    <div className="lumx-list-item__wrapper">
                        <div className="lumx-list-item__before">
                            <i className="lumx-icon lumx-icon--color-dark lumx-icon--has-shape lumx-icon--size-m lumx-icon--path">
                                <svg aria-hidden="true" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" width="1em"><path d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M10,16V19.08L13.08,16H20V4H4V16H10M6,7H18V9H6V7M6,11H15V13H6V11Z" fill="currentColor"></path></svg>
                            </i>
                        </div>
                        <div className="lumx-list-item__content">
                            <div className="user-details-list-item__content">
                                <span className="user-details-list-item__title">{this.state.EmployeeDetail.ProfileQuestion3}</span>
                                <span className="user-details-list-item__value">{this.state.EmployeeDetail.ProfileAnswer3}</span>
                            </div>
                        </div>
                    </div>
                </li>

                <li className="user-details-list-item lumx-list-item lumx-list-item--size-regular" style={{ display: this.state.EmployeeDetail.ProfileQuestion4 != '' && this.state.EmployeeDetail.ProfileQuestion4 != null ? 'block' : 'none' }}>
                    <div className="lumx-list-item__wrapper">
                        <div className="lumx-list-item__before">
                            <i className="lumx-icon lumx-icon--color-dark lumx-icon--has-shape lumx-icon--size-m lumx-icon--path">
                                <svg aria-hidden="true" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" width="1em"><path d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M10,16V19.08L13.08,16H20V4H4V16H10M6,7H18V9H6V7M6,11H15V13H6V11Z" fill="currentColor"></path></svg>
                            </i>
                        </div>
                        <div className="lumx-list-item__content">
                            <div className="user-details-list-item__content">
                                <span className="user-details-list-item__title">{this.state.EmployeeDetail.ProfileQuestion4}</span>
                                <span className="user-details-list-item__value">{this.state.EmployeeDetail.ProfileAnswer4}</span>
                            </div>
                        </div>
                    </div>
                </li>

                <li className="user-details-list-item lumx-list-item lumx-list-item--size-regular" style={{ display: this.state.EmployeeDetail.ProfileQuestion5 != '' && this.state.EmployeeDetail.ProfileQuestion5 != null ? 'block' : 'none' }}>
                    <div className="lumx-list-item__wrapper">
                        <div className="lumx-list-item__before">
                            <i className="lumx-icon lumx-icon--color-dark lumx-icon--has-shape lumx-icon--size-m lumx-icon--path">
                                <svg aria-hidden="true" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" width="1em"><path d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M10,16V19.08L13.08,16H20V4H4V16H10M6,7H18V9H6V7M6,11H15V13H6V11Z" fill="currentColor"></path></svg>
                            </i>
                        </div>
                        <div className="lumx-list-item__content">
                            <div className="user-details-list-item__content">
                                <span className="user-details-list-item__title">{this.state.EmployeeDetail.ProfileQuestion5}</span>
                                <span className="user-details-list-item__value">{this.state.EmployeeDetail.ProfileAnswer5}</span>
                            </div>
                        </div>
                    </div>


                </li>
                <li className="user-details-list-item lumx-list-item lumx-list-item--size-regular" style={{ display: this.state.EmployeeDetail.ERGMembership != '' && this.state.EmployeeDetail.ERGMembership != null ? 'block' : 'none' }}>
                    <div className="lumx-list-item__wrapper">
                        <div className="lumx-list-item__before">
                            <i className="lumx-icon lumx-icon--color-dark lumx-icon--has-shape lumx-icon--size-m lumx-icon--path">
                                <svg aria-hidden="true" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" width="1em"><path d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" fill="currentColor"></path></svg>
                            </i>
                        </div>
                        <div className="lumx-list-item__content">
                            <div className="user-details-list-item__content">
                                <span className="user-details-list-item__title">CRG Membership(s)</span>
                                <span className="user-details-list-item__value">{this.state.EmployeeDetail.ERGMembership}</span>
                            </div>
                        </div>
                    </div>


                </li>
                <li className="user-details-list-item lumx-list-item lumx-list-item--size-regular" style={{ display: this.state.EmployeeDetail.ProfileDescription != '' && this.state.EmployeeDetail.ProfileDescription != null ? 'block' : 'none' }}>
                    <div className="lumx-list-item__wrapper">
                        <div className="lumx-list-item__before">
                            <i className="lumx-icon lumx-icon--color-dark lumx-icon--has-shape lumx-icon--size-m lumx-icon--path">
                                <svg viewBox="0 0 24 24" width="1em">
                                    <path fill="currentColor" d="M12 3C14.21 3 16 4.79 16 7S14.21 11 12 11 8 9.21 8 7 9.79 3 12 3M16 13.54C16 14.6 15.72 17.07 13.81 19.83L13 15L13.94 13.12C13.32 13.05 12.67 13 12 13S10.68 13.05 10.06 13.12L11 15L10.19 19.83C8.28 17.07 8 14.6 8 13.54C5.61 14.24 4 15.5 4 17V21H20V17C20 15.5 18.4 14.24 16 13.54Z" />
                                </svg>
                            </i>
                        </div>
                        <div className="lumx-list-item__content">
                            <div className="user-details-list-item__content">
                                <span className="user-details-list-item__title">Job Description</span>
                                <span className="user-details-list-item__value">{this.state.EmployeeDetail.ProfileDescription}</span>
                            </div>
                        </div>
                    </div>
                </li>
                {this.state.EmployeeDetail.JobName != 'Contractor' ? <>
                    <li className="user-details-list-item lumx-list-item lumx-list-item--size-regular" style={{ display: this.state.EmployeeDetail.HR1PersonID != '' && this.state.EmployeeDetail.HR1PersonID != null ? 'block' : 'none' }}>
                        <div className="lumx-list-item__wrapper">
                            <div className="lumx-list-item__before">
                                <i className="lumx-icon lumx-icon--color-dark lumx-icon--has-shape lumx-icon--size-m lumx-icon--path">
                                    <svg viewBox="0 0 24 24" width="1em">
                                        <path fill="currentColor" d="M12 3C14.21 3 16 4.79 16 7S14.21 11 12 11 8 9.21 8 7 9.79 3 12 3M16 13.54C16 14.6 15.72 17.07 13.81 19.83L13 15L13.94 13.12C13.32 13.05 12.67 13 12 13S10.68 13.05 10.06 13.12L11 15L10.19 19.83C8.28 17.07 8 14.6 8 13.54C5.61 14.24 4 15.5 4 17V21H20V17C20 15.5 18.4 14.24 16 13.54Z" />
                                    </svg>
                                </i>
                            </div>
                            <div className="lumx-list-item__content">
                                <div className="user-details-list-item__content">
                                    <span className="user-details-list-item__title">Primary HR Business Partner</span>
                                    <span className="user-details-list-item__value"><Link to={/ProfilePageLumapps/ + this.state.EmployeeDetail.HR1PersonID}>{this.state.EmployeeDetail.HR1FullName}</Link></span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="user-details-list-item lumx-list-item lumx-list-item--size-regular" style={{ display: this.state.EmployeeDetail.HR2PersonID != '' && this.state.EmployeeDetail.HR2PersonID != null ? 'block' : 'none' }}>
                        <div className="lumx-list-item__wrapper">
                            <div className="lumx-list-item__before">
                                <i className="lumx-icon lumx-icon--color-dark lumx-icon--has-shape lumx-icon--size-m lumx-icon--path">
                                    <svg viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M12 3C14.21 3 16 4.79 16 7S14.21 11 12 11 8 9.21 8 7 9.79 3 12 3M16 13.54C16 14.6 15.72 17.07 13.81 19.83L13 15L13.94 13.12C13.32 13.05 12.67 13 12 13S10.68 13.05 10.06 13.12L11 15L10.19 19.83C8.28 17.07 8 14.6 8 13.54C5.61 14.24 4 15.5 4 17V21H20V17C20 15.5 18.4 14.24 16 13.54Z" />
                                    </svg>
                                </i>
                            </div>
                            <div className="lumx-list-item__content">
                                <div className="user-details-list-item__content">
                                    <span className="user-details-list-item__title">Secondary HR Business Partner</span>
                                    <span className="user-details-list-item__value"><Link to={/ProfilePageLumapps/ + this.state.EmployeeDetail.HR2PersonID}>{this.state.EmployeeDetail.HR2FullName}</Link></span>
                                </div>
                            </div>
                        </div>
                    </li>
                </> : ''
                }


            </>
            )
    }

    render() {
        return (
            <>
                <div className="container" style={{ display: this.state.hideContent == true? 'none':'block' }}>
                    <div className="row">
                        <div className="col l12 s12 m12">
                            <div className="card horizontal employeeProfileReview">

                                <div className="card-stacked">
                                    <div className="card-content">
                                        <div className="row">
                                            <div className="col l12 s12 m12">
                                                <Link to="/SelfClose" target="_blank" style={{ display: 'none' }}>Link</Link>
                                                <div className="profileImgContainer">
                                                    {this.ProfileTopSection()}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col l12 s12 m12">
                                                <div className="articleBox">
                                                <ul className="user-details-list lumx-list hopcustomlist">
                                                    {this.ListDataDisplay()}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col s12 l12 m12">
                                                <div className="articleBox">
                                                 <ul className="profileImgSection">
                                                    {this.EmployeePhotoRenderSection()}
                                                 </ul>
                                                </div>
                                            </div>
                                           
                                        </div>

                                        <div className="row">
                                            <div className="col l12 s12 m12">
                                                <div className="articleBox">
                                                    {this.employeeHir()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        
                    </div>
                </div>
            </>
        );
    }
}

import React, { Component } from 'react';
//import "react-datepicker/dist/react-datepicker.css";

import '../assets/customOkta.css'
export class SessionTimeout extends Component {
    static displayName = SessionTimeout.name;

    constructor(props) {
        super(props);
        this.state = {};
        this.oktaURL = '';
        
    }
  
   async componentDidMount() {
       var response = await fetch('Auth/CheckSession');
       var data = await response.json();
       this.oktaURL = data.url;
       window.location.href = this.oktaURL;
   }

    render() {
        return (<>
            <p className="textCenterSession">
                <span><img src="images/oktaNew.gif" /></span>
                <span>Signing in to Internal App.</span>
                    </p>
        </>)
    }

}

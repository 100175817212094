import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { MessageBox } from '../../common/modalBox/MessageBox'
import { HttpRequest } from '../CommonOperations';
import { validateEmail } from '../../common/CommonOperations';

export class DISHscriber extends Component {
    static displayName = DISHscriber.name;

    constructor(props) {
        super(props);
        this.state = {
            showBecomeACustomerModal: false, 
            showExistingCustomerModal: false,
            customerDetail: [],
            dishScriberContentRequest: [],
            dishScriberNewFormContent: [],
            dishScriberExistFormContent: [],
            showMessageBox: false, message: '', messageType: '1'
        };
        this.UserDetails = this.props.UserDetails;
        this.path = "https://intranet.global.dish.com";
        this.hideBecomeACustomerModal = this.hideBecomeACustomerModal.bind(this); 
    }
    componentDidMount() {
        this.getvalue();
        this.GetDishscriberContent();
       // this.GetDishscriberContent1();
        this.GetDishscriberNewFormContent();
        this.GetDishScriberExistFormContent();
    }
    ShowMessageBox = (message, messageType) => this.setState({ showMessageBox: true, messageType: messageType, message: message });
    HideMessageBox = () => this.setState({ showMessageBox: false });

    getvalue =  async () => {
        let param = { 'UserId': this.UserDetails.NTLogin, 'CompanyName': 'Dish' };
        let response = await HttpRequest('Content/GetDishscriberUserInfo', 'Post', param);
        const data = await response.json();
        this.setState({ customerDetail: data.DishScriberUserInfo, })
    }

    GetDishscriberContent = async () => {
        let response = await HttpRequest('Content/GetContentByRouteText/dishScriberContent','Get');
        const res = await response.json();
        this.setState({ dishScriberContentRequest: res.data })        
    }
    //GetDishscriberContent1 = async () => {
    //    let response = await HttpRequest('Content/GetBusinessSessionDetailsById/volunteeratoktoberfest', 'Get');
    //    const res = await response.json();
    //   // debugger;
    //    console.log('res.data')
    //    console.log(res.Data)
    //   // this.setState({ dishScriberContentRequest: res.data })
    //}
    GetDishscriberNewFormContent = async () => {
        let response = await HttpRequest('Content/GetContentByRouteText/dishScriberFormNew','Get');
        const res = await response.json();
        this.setState({ dishScriberNewFormContent: res.data })        
    }
    GetDishScriberExistFormContent = async () => {
        let response = await HttpRequest('Content/GetContentByRouteText/dishScriberFormExist','Get');
        const res = await response.json();
        this.setState({ dishScriberExistFormContent: res.data })        
    }

    becomeACustomer = () => { this.setState({ showBecomeACustomerModal: true })}
    existingCustomer = () => {this.setState({ showExistingCustomerModal: true})}
    hideExistingCustomerModal = () => {this.setState({showExistingCustomerModal: false})}
    hideBecomeACustomerModal = async (item) => {await this.setState({showBecomeACustomerModal: false})}

    InsertNewCustomer = async (e) => {
        //if (document.getElementById('secondaryEmailText').value.length == 0) {
        //    this.ShowMessageBox("Secondary email cannot be blank", 3); return false;

        //}
        if (document.getElementById('secondaryEmailText').value.length > 0) {
            if (!validateEmail(document.getElementById('secondaryEmailText').value)) {
                this.ShowMessageBox("Please enter a valid email address", 3); e.stopPropagation(); return false;
            }
        }
        try {
            let param = {'EmployeeNumber': this.state.customerDetail.EmployeeNumber, 'SecondaryEmailAddress': document.getElementById('secondaryEmailText').value, DishScriberType: "new", UserId: this.UserDetails.NTLogin, CompanyName: "DISH" }
            var response = await HttpRequest('Content/SendDishscriberUserEmail', 'Post', param);
            const data = await response.json();
            if (data.IsSuccessful) {
                this.setState({ showBecomeACustomerModal: false });
                this.ShowMessageBox('Thank you for your interest in the Dish Scriber program!<br />Your information has been sent');
            } else {
                this.setState({ showBecomeACustomerModal: false });
                this.ShowMessageBox(data.FriendlyMessage, 3);

            }
            
        } catch (e) {
        }
    }

    InsertExistingCustomer = async (e) => {
        
        let accountNumber = document.getElementById('accountNumber').value;
        var reg = new RegExp('^[0-9]*$');
        if (accountNumber.length == 0) {
            this.ShowMessageBox("Account number cannot be blank", 3); e.stopPropagation(); return false;

        }
        accountNumber = accountNumber.replace(/-/gi, '');

        //if (reg.test(accountNumber) == false) {
        //    this.ShowMessageBox("Only Numeric Account Number is allowed. ", 3); return false;
        //}
        if (accountNumber.length < 16) {
            this.ShowMessageBox("Account Number too short, should be 16 characters long!", 3); e.stopPropagation(); return false;

        }
        if (accountNumber.length > 16) {
            this.ShowMessageBox("Account Number too long, should be 16 characters long!", 3); e.stopPropagation(); return false;

        }     
        try {
            let param = {'EmployeeNumber': this.state.customerDetail.EmployeeNumber, 'DishScriberAccountNumber': document.getElementById('accountNumber').value, DishScriberType: "exist", UserId: this.UserDetails.NTLogin, CompanyName: "DISH" }
            var response = await HttpRequest('Content/SendDishscriberUserEmail', 'Post', param);
            const data = await response.json();

            this.setState({ showExistingCustomerModal: false });
            this.ShowMessageBox('Thank you for your interest in the Dish Scriber program!<br />Your information has been sent');
            accountNumber = '';
        } catch (e) {
        }
    }

    renderSwitch(param) {
        switch (param) {
            case 'Certificate':
                return(
                <div>
                        <h6>Your certificate number is {this.state.customerDetail.DishScriberCertificateID} call 1-866-974-0493 to sign up for your DISH service.</h6>
                        <span style={{ fontStyle: 'italic' }}> Your certificate number is valid for 30 days. After 30 days, please contact <a style={{ fontStyle: 'italic', color: 'blue', marginRight: '6px', textTransform: 'lowercase' }} href='mailto:scriberoffers@dish.com'>scriberoffers@dish.com</a>for a new certificate number. </span><br />
                        {this.UserDetails.GoogleData.organizations[0].title != 'Contractor'?<Button variant="primary" onClick={this.existingCustomer}>Existing Customer?</Button>:''}
                </div>);
            case 'Eligible':
                return(
                    <div> {this.UserDetails.GoogleData.organizations[0].title != 'Contractor' ?<><Button variant="secondary" onClick={this.becomeACustomer}>Become a Customer!</Button> <Button variant="primary" onClick={this.existingCustomer}>Existing Customer?</Button></>: ''}
                    
                </div>);
            case 'Ineligible':
                return(
                <div>
                        <h6>You will be eligible for the DISHscriber benefit on { new Date(this.state.customerDetail.DishScriberEligibilityDate).toLocaleDateString()}. Please check back to get your certificate.</h6>
                </div>);
            case 'Account':
                return(
                <div>
                    <h6>You are currently enrolled in the DISHscriber program.</h6>
                    </div>);
            default: return <></>
           
        }
        
    }
    RenderImage = () => {
        if (this.state.dishScriberContentRequest.length == 0)
            return <></>;
        else {
            return (
                this.state.dishScriberContentRequest.imageGroup.imageGroupDetails.map((item, index) => (
                    <img key="testKey" src="images/dish-scriber-sm.png" className="img-responsive" alt="" />
                ))
            );
        }
    }
    render() {
        if (this.UserDetails.GoogleData == null)
            return <></>
    return (
        <>
            <div className="container">
                <div className="col s12 m7">
                   
                    <div className="card horizontal dishScribre">
                        <div className="card-image">
                            {this.RenderImage()}
                        </div>
                        <div className="card-stacked">
                            <div className="card-content">
                                    <h4 dangerouslySetInnerHTML={{ __html: this.state.dishScriberContentRequest.title }} ></h4>
                                    <span dangerouslySetInnerHTML={{ __html: this.state.dishScriberContentRequest.body }} ></span>
                                </div>
                            <div className="card-action">
                                {this.renderSwitch(this.state.customerDetail.DishScriberStatus)}
                                </div>
                            </div>
                        </div>
                    </div>
                <div dangerouslySetInnerHTML={{ __html: this.state.dishScriberContentRequest.imageFooterText }} ></div> 
                <div className="row"><img src="images/footerlogos-dishscriber.jpg" className="img-responsive" alt="" /></div>
            </div>



            <Modal show={this.state.showBecomeACustomerModal} onHide={() => this.hideBecomeACustomerModal()}  className="modalMySaveCall">
                <Modal.Header closeButton>
                    <Modal.Title dangerouslySetInnerHTML={{ __html: this.state.dishScriberNewFormContent.title }}></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div  className="col s12 l2 m2">
                            {this.RenderImage()}
                        </div>
                        <div className="col s12 l10 m10">
                            <span dangerouslySetInnerHTML={{ __html: this.state.dishScriberNewFormContent.body }} ></span> 
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col s12">
                            <div className="input-field">
                                <input id="FirstName" type="text" className="validate" disabled defaultValue={this.state.customerDetail.FirstName} />
                                <label htmlFor="FirstName" className="active">First Name</label>
                            </div>
                            <div className="input-field">
                                <input id="LastName" type="text" className="validate" disabled defaultValue={this.state.customerDetail.LastName} />
                                <label htmlFor="LastName" className="active">Last Name</label>
                            </div>
                            <div className="input-field">
                                <input id="Address" type="text" className="validate" disabled defaultValue='Please ensure that the service address matches an employee address in Oracle Self Service' />
                                <label htmlFor="Address" className="active">Address</label>
                            </div>
                            <div className="input-field">
                                <input id="employeeId" type="text" className="validate" disabled defaultValue={this.state.customerDetail.EmployeeNumber} />
                                <label htmlFor="employeeId" className="active">Employee ID Number</label>
                            </div>
                            <div className="input-field">
                                <input id="HireDate" type="text" className="validate" disabled defaultValue={new Date(this.state.customerDetail.HireDate).toLocaleDateString()} />
                                <label htmlFor="HireDate" className="active">Hire Date</label>
                            </div>
                            <div className="input-field">
                                <input className="validate" id="secondaryEmailText" />
                                <label htmlFor="secondaryEmailText" className="active">Secondary Email</label>
                                <span className="helper-text">Secondary Email Contact for Receipt of Certificate Number:(Must be a dish.com email address)</span>

                            </div>
                        </div>
                    </div>
                    {/*<ul>
                        <li>
                            <label htmlFor="FirstName">First Name:</label>
                            <span name='FirstName'>{this.state.customerDetail.FirstName }</span>
                        </li>
                        <li>
                            <label htmlFor="LastName">Last Name:</label>
                            <span name='LastName'>{ this.state.customerDetail.LastName }</span>
                        </li>
                        <li>
                            <label htmlFor="address">Address:</label>
                            <span name='address'>Please ensure that the service address matches an employee address in Oracle Self Service. </span>                           
                        </li>
                        <li>
                            <label htmlFor="projectName">Employee ID Number:</label>
                            <span name='FirstName'>{ this.state.customerDetail.EmployeeNumber }</span>
                        </li>
                        <li>
                            <label htmlFor="address">Hire Date:</label>
                            <span name='address'>{this.state.customerDetail.HireDate}</span>
                        </li>
                        <li>
                            <label htmlFor="address">Secondary Email Contact for Receipt of Certificate Number:(Must be a dish.com email address)</label>
                            <input className="form-control" placeholder="SecondaryEmail" type="text" id="secondaryEmailText"  />

                        </li>
                    </ul>*/}
                    <div className="row">
                    <div className="col s12 l12">
                        By clicking "Submit", you acknowledge that if your DISH account is disconnected before your balance is paid in full you may forfeit future DISH employee credits.
                        Please review the Employee Programming business rules here: <a  id="lnkBusinessRulesDoc" runat="server" target="_blank">DISHscriber</a>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={this.hideBecomeACustomerModal}>Cancel</Button>
                    <Button variant="primary" onClick={this.InsertNewCustomer}>Submit</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={this.state.showExistingCustomerModal} onHide={this.hideExistingCustomerModal} className="modalMySaveCall">
                <Modal.Header closeButton>
                    <Modal.Title dangerouslySetInnerHTML={{ __html: this.state.dishScriberExistFormContent.title }} ></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col m2 l2 s12">
                            {this.RenderImage()}
                        </div>
                        <div className="col m10 l10 s12">
                            <span dangerouslySetInnerHTML={{ __html: this.state.dishScriberExistFormContent.body}} ></span> 
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col s12">
                            <div className="input-field">
                                <input id="FirstName" type="text" className="validate" disabled defaultValue={this.state.customerDetail.FirstName} />
                                <label htmlFor="FirstName" className="active">First Name</label>
                            </div>
                            <div className="input-field">
                                <input id="LastName" type="text" className="validate" disabled defaultValue={this.state.customerDetail.LastName} />
                                <label htmlFor="LastName" className="active">Last Name</label>
                            </div>
                            <div className="input-field">
                                <input id="Address" type="text" className="validate" disabled defaultValue='Please ensure that the service address matches an employee address in Oracle Self Service' />
                                <label htmlFor="Address" className="active">Address</label>
                            </div>
                            <div className="input-field">
                                <input id="employeeId" type="text" className="validate" disabled defaultValue={this.state.customerDetail.EmployeeNumber} />
                                <label htmlFor="employeeId" className="active">Employee ID Number</label>
                            </div>
                            <div className="input-field">
                                {/* <input className="validate" id="accountNumber" ng-minlength="16" ng-maxlength="16" ng-pattern="/^[a-zA-Z0-9]*$/" />*/}
                                <input className="validate" id="accountNumber" type="text" />
                                <label htmlFor="accountNumber" className="active">Account Number:(found on your Dish Network bill)</label>
                                

                            </div>
                        </div>
                    </div>
                    {/*
                      <ul>
                        <li>
                            <label htmlFor="FirstName">First Name:</label>
                            <span name='FirstName'>{this.state.customerDetail.FirstName}</span>
                        </li>
                        <li>
                            <label htmlFor="LastName">Last Name:</label>
                            <span name='LastName'>{this.state.customerDetail.LastName}</span>
                        </li>
                        <li>
                            <label htmlFor="address">Address:</label>
                            <span name='address'>Please ensure that the service address matches an employee address in Oracle Self Service. </span>
                        </li>
                        <li>
                            <label htmlFor="projectName">Employee ID Number:</label>
                            <span name='FirstName'>{this.state.customerDetail.EmployeeNumber}</span>
                        </li>
                        <li>
                            <label htmlFor="address">Account Number:(found on your Dish Network bill)</label>
                            <input className="form-control" placeholder="AccountNumber" type="text"  />
                        </li>

                    </ul>
                     */}
                    <div className="row">
                    <div className="col s12 m12 l12">
                        By clicking "Submit", you acknowledge that if your DISH account is disconnected before your balance is paid in full you may forfeit future DISH employee credits.
                        Please review the Employee Programming business rules here: <a id="lnkBusinessRulesDoc" runat="server" target="_blank">DISHscriber</a>
                    </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.hideExistingCustomerModal}>Cancel</Button>
                    <Button variant="primary" onClick={this.InsertExistingCustomer}>Submit</Button>
                </Modal.Footer>
            </Modal>
            <MessageBox show={this.state.showMessageBox} onHide={this.HideMessageBox} type={this.state.messageType} message={this.state.message} />
            
            </>
    );
  }
}
